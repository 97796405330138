import React from "react";
import animationData from "../Images/animationData.json";
import Lottie from "react-lottie";
import { Link } from "react-router-dom";

export default function Page404() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div className="">
      <div className="flex flex-col py-20 items-center">
        <div className="h-[500px] ">
          <Lottie options={defaultOptions} isClickToPauseDisabled={true} />
          <div className="flex items-center justify-center">
            <Link
              to="/"
              className="text-white border py-3 w-full text-center rounded-xl hover:bg-green-500 hover:text-black font-bold"
            >
              Go To Home
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
