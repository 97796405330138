import React from "react";
import logo from "../../Images/mowgli-sanctuary.png";
import { Link, useNavigate } from "react-router-dom";
import a1 from "../../Images/twitter-header-icon.png";
import a2 from "../../Images/facebook.png";
import a3 from "../../Images/m.png";
import a4 from "../../Images/telegram-512.png";
import footer from "../../Images/footerbg.jpg";

export default function Footer() {
  const navigate = useNavigate();
  return (
    <div
      className="relative overflow-hidden lg:flex flex-col items-center  bg-opacity-5 py-10 "
      style={{ backgroundImage: `url(${footer})`, backgroundSize: "cover" }}
    >
      <div className="lg:flex w-full lg: justify-between p-10 ">
        <div className="flex flex-col justify-center items-center">
          <img
            data-aos="fade-top"
            data-aos-delay="50"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out-cubic"
            onClick={() => navigate("/")}
            src={logo}
            alt=""
            className="h-28 w-[52] cursor-pointer"
          />
          <div className="flex my-10 gap-10">
            <Link to="https://twitter.com/MowgliSanctuary">
              <img src={a1} alt="" className="h-10 w-10" />
            </Link>
            <Link to="https://www.facebook.com/people/Mowgli-Sanctuary/100090329212988/">
              {" "}
              <img src={a2} alt="" className="h-10 w-10" />
            </Link>
            <Link to="https://medium.com/@mowglisanctuary">
              {" "}
              <img src={a3} alt="" className="h-10 w-10" />
            </Link>
            <Link to="https://t.me/Mowglisanctuaryofficial">
              {" "}
              <img src={a4} alt="" className="h-10 w-10" />
            </Link>
          </div>
        </div>
        <div className="flex flex-col lg:items-start items-center lg:my-0 my-3 ">
          <p className="text-xl font-bold text-white font-arco">THE MOWLI</p>
          <p
            onClick={() => navigate("/monglicoin")}
            className="text-white font-bold text-sm my-1 cursor-pointer"
          >
            BUY MOWGLI
          </p>
          <p
            onClick={() => navigate("/monglicoin-claim")}
            className="text-white font-bold text-sm my-1 cursor-pointer"
          >
            CLAIM MOWGLI
          </p>
          <p
            onClick={() => navigate("/mowglicoin-bridge")}
            className="text-white font-bold text-sm my-1 cursor-pointer"
          >
            BRIDGE GMOWGLICOIN
          </p>
          <p
            onClick={() => navigate("/mowgli-gallery")}
            className="text-white font-bold text-sm my-1 cursor-pointer"
          >
            MOWGLI GALLERY
          </p>
        </div>
        <div className="flex flex-col lg:items-start items-center lg:my-0 my-3 ">
          <p className="text-xl font-bold text-white font-arco">
            TERMS & POLICIES
          </p>
          <p className="text-white font-bold text-sm my-1 cursor-pointer">
            PRIVACY POLICY
          </p>
          <p className="text-white font-bold text-sm my-1 cursor-pointer">
            TERMS & CONDITIONS
          </p>
          <p className="text-white font-bold text-sm my-1 cursor-pointer">
            FAQs
          </p>
        </div>
      </div>
      <div className="flex justify-center items-center ">
        <p className="my-3 text-white">© 2024 Mowgli</p>
      </div>
    </div>
  );
}
