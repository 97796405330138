import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../Images/mowgli-sanctuary.png";
import stak from "../Images/stake.png";
import stak1 from "../Images/instagram-stories.png";
import stak2 from "../Images/compass.png";
import stak3 from "../Images/jigsaw.png";
import stak4 from "../Images/dashboard.png";
import Staking from "./Staking";
import DashboardHome from "./DashboardHome";
import Storay from "./Storay";
import Puzzle from "./Puzzle";
import Explore from "./Explore";
import { StakingApp } from "../Page/Context/StakingApp";
import Exclusive from "./Exclusive";
import Sanctuary from "./Sanctuary";
import MenuIcon from "@mui/icons-material/Menu";
import CreateButoon from "../Alldilog/ConnectButton";
import { useAccount } from "wagmi";
import { Drawer, Dialog } from "@mui/material";
import axiosInstance1 from "../Config1/axios";
import { API_URLS1 } from "../Config1/apiUrls";
import Menu from "../Components/Menu/Menu";
import Notification from "../Components/Notification/Notification";
import Progress from "../Alldilog/Progress";
import Shakehand from "./Shakehand";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { toast } from "react-toastify";
import d from "../Images/bg1.jpg";

export default function Dashboard() {
  const navigate = useNavigate();
  const { address, isDisconnected } = useAccount();
  const { page, setPage } = useContext(StakingApp);
  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [data, setData] = useState();

  const handleDrawerOpen = () => {
    setOpen1(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };
  const handelopen2 = (i) => {
    setOpen2(true);
  };

  const Activ = () => {
    axiosInstance1
      .post(API_URLS1.loginactivity, {})
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    Activ();
  }, []);

  const reUpStory = () => {
    axiosInstance1
      .post(API_URLS1.handshakeRequestList, {})
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
        err?.response?.data?.Response_code === 401 && back();
      });
  };

  const RequestReject = (id) => {
    axiosInstance1
      .post(API_URLS1.RejectByStorymake, { requestid: id })
      .then((res) => {
        toast.success(res.data.msg);
        handleClose2();
      })
      .catch((err) => {
        console.log(err);
        err?.response?.data?.Response_code === 401 && back();
      });
  };
  const ConfirmByStorymake = (id) => {
    axiosInstance1
      .post(API_URLS1.RequestConfirm, { requestid: id })
      .then((res) => {
        toast.success(res.data.msg);
        handleClose2();
      })
      .catch((err) => {
        console.log(err);
        err?.response?.data?.Response_code === 401 && back();
      });
  };

  useEffect(() => {
    reUpStory();
  }, [open2]);

  const handleDrawerClose = () => {
    setOpen1(false);
  };
  const address1 = localStorage.getItem("address");
  const back = () => {
    navigate("/");
  };
  useEffect(() => {
    address !== address1 && back();
  }, [address]);

  // console.log(data.data.length, "data");

  return (
    <>
      <>
        {/* <Joyride1 steps={steps} /> */}
        <div
          // style={{
          //   backgroundImage: `url(${d})`,
          //   backgroundSize: "cover",
          // }}
          className="lg:flex lg:px-10 gap-10 justify-center lg:h-screen items-center bg-gradient-to-r from-[#060508] from-8% via-[#10122e] via-20% to-[#14003d] to-60% "
        >
          <Menu />

          <div className="lg:w-[80%] lg:h-[90vh] flex flex-col  py-3 !overflow-y-auto example">
            <Notification />

            <div className="flex flex-col lg:gap-10 w-full p-5 lg:px-20  overflow-auto lg:h-[90vh] example">
              {/* <p className="text-white font-bold text-3xl">Dashboard</p> */}
              <div className="lg:flex  lg:gap-10 w-full   ">
                <div
                  // style={{
                  //   backgroundImage: `url(${d})`,
                  //   backgroundSize: "cover",
                  // }}
                  className="relative overflow-hidden justify-between lg:my-0 my-2  w-full lg:flex items-center rounded-2xl text-white p-3 border border-[#3d3fc2] gap-10  py-5"
                >
                  <Progress />
                  <div className="flex flex-col justify-start items-start">
                    <p className="text-xl font-bold text-center my-5">
                      Mowgli Sanctuary Staked
                    </p>
                    <p className="text-sm font-bold">
                      Stake your IOs to win NFTs, ETH, whitelist spots, and
                      other rewards!
                    </p>
                    <p
                      onClick={() => navigate("/staking")}
                      id="step-1"
                      className="  px-8 py-1 rounded-3xl bg-[#5c2f9e]  my-5 cursor-pointer hover:bg-[#866dac] hover:text-black hover:font-bold"
                    >
                      Get Stake
                    </p>
                  </div>
                </div>
                <div
                  // style={{
                  //   backgroundImage: `url(${d2})`,
                  //   backgroundSize: "cover",
                  // }}
                  className="relative lg:my-0 my-2 overflow-hidden justify-between  w-full lg:flex border border-[#3d3fc2] items-center rounded-2xl text-white p-3 gap-10 "
                >
                  {/* <Progress /> */}
                  <div className="flex flex-col justify-start items-start">
                    <p className="text-xl font-bold text-center my-5">
                      Mowgli Sanctuary Story
                    </p>
                    <p className="text-sm font-bold">
                      A journey into the heart of the wilderness with the Mowgli
                      Sanctuary NFT Collection. Inspired by the timeless story
                      of Mowgli and his adventures in the jungle, this
                      collection brings the magic of wildlife to the digital
                      world.
                    </p>
                    <div className="flex w-full justify-between">
                      <p></p>
                      <p
                        onClick={() => navigate("/storay")}
                        id="step-2"
                        className="px-8 py-1 rounded-3xl bg-[#5c2f9e]  my-5 cursor-pointer hover:bg-[#866dac] hover:text-black hover:font-bold"
                      >
                        Make Story
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="lg:flex  lg:gap-10 w-full  ">
                <div
                  // style={{
                  //   backgroundImage: `url(${d3})`,
                  //   backgroundSize: "cover",
                  // }}
                  className="relative overflow-hidden lg:my-0 my-2 border border-[#3d3fc2] justify-between w-full lg:flex items-center rounded-2xl text-white p-3 gap-10  "
                >
                  {/* <Progress /> */}
                  <div className="flex flex-col justify-start items-start">
                    <p className="text-xl font-bold text-center my-5">
                      Mowgli Sanctuary Puzzel's
                    </p>
                    <p className="text-sm font-bold">
                      Instructions: Piece together the fragmented image to
                      reveal a scene from Mowgli's adventure in the jungle.
                    </p>
                    <p className="text-sm font-bold">
                      Clues: Look for distinctive features like Mowgli's red
                      lion cloth or the lush greenery of the jungle to help
                      assemble the pieces.
                    </p>
                    <p
                      onClick={() => setPage("puzzle")}
                      className="  px-8 py-1 rounded-3xl bg-[#5c2f9e] my-5 cursor-pointer hover:bg-[#866dac] hover:text-black hover:font-bold"
                    >
                      Get Puzzel's
                    </p>
                  </div>
                </div>
                <div
                  // style={{
                  //   backgroundImage: `url(${d4})`,
                  //   backgroundSize: "cover",
                  // }}
                  className="relative overflow-hidden border border-[#3d3fc2] justify-between w-full flex items-center rounded-2xl text-white p-3 gap-10 "
                >
                  {/* <Progress /> */}
                  <div className="flex flex-col justify-start items-start">
                    <p className="text-xl font-bold text-center my-5">
                      Mowgli Sanctuary Explore
                    </p>
                    <p className="text-sm font-bold">
                      Exploration is the act of traveling into unfamiliar or
                      unknown territories in order to discover, learn about, or
                      experience new things. It is a fundamental aspect of human
                      curiosity and has been a driving force behind many
                      significant discoveries throughout history.
                    </p>
                    <div className="flex w-full justify-between">
                      <p></p>
                      <p
                        onClick={() => setPage("explore")}
                        className="  px-8 py-1 rounded-3xl bg-[#5c2f9e]  my-5 cursor-pointer hover:bg-[#866dac] hover:text-black hover:font-bold"
                      >
                        Explore
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="lg:flex lg:px-10 gap-10 justify-center items-center ">
        <div className=" lg:w-[15%] relativ justify-between  flex bg-opacity-25 bg-[#078043] flex-col  py-5 text-white rounded-xl lg:my-5 lg:h-[90vh] px-2 ">
          <div className=" lg:block hidden ">
            <div className="flex items-center justify-center border-b border-gray-500 py-2 ">
              <img
                onClick={() => navigate("/")}
                src={logo}
                alt=""
                className=" h-20 cursor-pointer lg:block hidden"
              />
            </div>
            <div className="flex justify-between lg:flex-col lg:my-0 my-5  ">
              <span
                onClick={() => setPage("dashboard")}
                className="px-5 gap-5 flex cursor-pointer  lg:my-4"
              >
                <img src={stak4} className="h-7 w-7" alt="stak" />
                <p className="font-bold  ">Dashbord</p>
              </span>
              <span
                onClick={() => setPage("staking")}
                className="px-5 gap-5 flex cursor-pointer  lg:my-4"
              >
                <img src={stak} className="h-7 w-7" alt="stak" />
                <p className="font-bold ">Staking</p>
              </span>
            </div>
            <div className="flex justify-between lg:flex-col lg:my-0 my-5 ">
              <span
                onClick={() => setPage("storay")}
                className="px-5 gap-5 flex cursor-pointer  lg:my-4"
              >
                <img src={stak1} className="h-7 w-7" alt="stak" />
                <p className="font-bold ">Story</p>
              </span>
              <span
                onClick={() => setPage("puzzle")}
                className="px-5 gap-5 flex cursor-pointer  lg:my-4"
              >
                <img src={stak2} className="h-7 w-7" alt="stak" />
                <p className="font-bold  ">Puzzle's</p>
              </span>
            </div>
            <div className="flex justify-between lg:flex-col lg:my-0 my-5">
              <span
                onClick={() => setPage("explore")}
                className="px-5 gap-5 flex cursor-pointer  lg:my-4"
              >
                <img src={stak3} className="h-7 w-7" alt="stak" />
                <p className="font-bold  ">Explore</p>
              </span>
              <span

                className="px-5 gap-5 flex cursor-pointer lg:hidden border-[#078043] py-1 rounded-md  lg:my-4"
              >
                <CreateButoon />
        
              </span>
            </div>
          </div>
          <div className="flex justify-between items-center w-full lg:hidden ">
       

            <img
              onClick={() => navigate("/")}
              src={logo}
              alt=""
              className=" h-20 cursor-pointer "
            />
            <div className="w-10 relative h-10 bg-white rounded-full flex justify-center items-center border border-gray-500 mr-5">
              <MenuIcon
                onClick={handleDrawerOpen}
                className="!text-3xl font-bold text-black animate-pulse"
              />
            </div>
          </div>

          <div
            onClick={() => navigate("/")}
            className="px-5  lg:block hidden lg:bg-opacity-20 justify-between lg:bg-black  hover:text-black cursor-pointer hover:border-b py-2 lg:border lg:border-[#078043] rounded-xl lg:hover:bg-blue-200 "
          >
    
            <CreateButoon />
          </div>
        </div>
        <div className="lg:w-[80%] lg:h-[90vh] flex flex-col  py-3 !overflow-y-auto example">
          <div className="flex justify-between items-center pb-5 px-10">
            <p className="text-white text-3xl font-bold capitalize">
              {page === "storay" ? "Unleash Your Imagination" : page}
            </p>

            <span
              onClick={handelopen2}
              className="flex relative bg-black h-16 w-16 gap-2 rounded-full cursor-pointer items-center justify-center"
            >
              {data?.data?.length !== 0 && (
                <span className="bg-blue-700 h-5 w-5 rounded-full justify-center items-center shadow-lg">
                  <p
                    style={{ boxShadow: "0 0 60px white" }}
                    className="font-bold text-center text-sm  text-black "
                  >
                    {data?.data?.length}
                  </p>
                </span>
              )}

              <span class="relative flex h-7 w-7">
                <NotificationsActiveIcon class="animate-ping absolute inline-flex h-full w-full rounded-full bg-blue-400 opacity-75"></NotificationsActiveIcon>
                <NotificationsActiveIcon class="relative inline-flex rounded-full h-7 w-7 bg-blue-500"></NotificationsActiveIcon>
              </span>
            </span>
          </div>
          {page === "dashboard" && <DashboardHome setPage={setPage} />}
          {page === "staking" && <Staking />}
          {page === "storay" && <Storay />}
          {page === "puzzle" && <Puzzle />}
          {page === "explore" && <Explore />}
          {page === "exclusive" && <Exclusive />}
          {page === "sanctuary" && <Sanctuary />}
          {page === "shakehand" && <Shakehand />}
        </div>
      </div> */}
        {/* <Drawer
        PaperProps={{
          className: "!w-[70%]  !bg-[#078043] !p-2 ",
        }}
        anchor="right"
        open={open1}
        onClose={handleDrawerClose}
      >
        <div className="flex flex-col bg-opacity-20 bg-black h-full text-white rounded-md p-2   ">
          <div className="relative">
            <div className="flex items-center justify-center border-b border-gray-500 py-2 ">
              <img
                onClick={() => {
                  navigate("/");
                  handleDrawerClose();
                }}
                src={logo}
                alt=""
                className=" h-20 cursor-pointer "
              />
            </div>
            <span
              onClick={() => {
                setPage("dashboard");
                handleDrawerClose();
              }}
              className="px-5 gap-5 flex cursor-pointer  my-4"
            >
              <img src={stak4} className="h-7 w-7" alt="stak" />
              <p className="font-bold  ">Dashbord</p>
            </span>
            <span
              onClick={() => {
                setPage("staking");
                handleDrawerClose();
              }}
              className="px-5 gap-5 flex cursor-pointer  my-4"
            >
              <img src={stak} className="h-7 w-7" alt="stak" />
              <p className="font-bold ">Staking</p>
            </span>

            <span
              onClick={() => {
                setPage("storay");
                handleDrawerClose();
              }}
              className="px-5 gap-5 flex cursor-pointer  my-4"
            >
              <img src={stak1} className="h-7 w-7" alt="stak" />
              <p className="font-bold ">Story</p>
            </span>
            <span
              onClick={() => {
                setPage("puzzle");
                handleDrawerClose();
              }}
              className="px-5 gap-5 flex cursor-pointer  my-4"
            >
              <img src={stak2} className="h-7 w-7" alt="stak" />
              <p className="font-bold  ">Puzzle's</p>
            </span>

            <span
              onClick={() => {
                setPage("explore");
                handleDrawerClose();
              }}
              className="px-5 gap-5 flex cursor-pointer  my-4"
            >
              <img src={stak3} className="h-7 w-7" alt="stak" />
              <p className="font-bold  ">Explore</p>
            </span>
          </div>
          <div
            onClick={() => navigate("/")}
            className=" absolute inset-x-5 bottom-5  bg-opacity-20 justify-between bg-black  hover:text-black cursor-pointer hover:border-b py-2 lg:border border-[#078043] rounded-xl hover:bg-blue-200 "
          >
            <CreateButoon />
          </div>
        </div>
      </Drawer> */}
        {/* <Dialog
        open={open2}
        onClose={handleClose2}
        PaperProps={{
          className:
            "w-[40%] !lg:h-[50%] lg:p-1 p-1 flex gap-3 flex-col !rounded-2xl !text-shadow-none !bg-[#041125]  border border-[#078043] ",
        }}
      >
        <div className="flex w-full flex-col my-5 gap-10 font-bold px-5">
          <p className="text-white text-xl">Notifications</p>
          {data?.data?.length !== 0 ? (
            data?.data?.map((i) => {
              return (
                <>
                  <div className="flex  border p-2 rounded-lg bg-gray-950 px-5 justify-between w-[100%]">
                    <p className="text-white ">#{i.toStoryorderid}</p>
                    <p className="text-white ">{i.storyTitle}</p>
                    <span className="flex gap-3">
                      <p
                        onClick={() => ConfirmByStorymake(i.id)}
                        className="px-3 py-1 rounded-lg bg-green-400 cursor-pointer text-sm"
                      >
                        Accept
                      </p>
                      <p
                        onClick={() => RequestReject(i.id)}
                        className="px-3 py-1 rounded-lg bg-red-400 cursor-pointer text-sm"
                      >
                        Reject
                      </p>{" "}
                    </span>
                  </div>
                </>
              );
            })
          ) : (
            <p className="text-white text-center">
              Currently you have no any notifications...!
            </p>
          )}
        </div>
      </Dialog> */}
      </>
    </>
  );
}
