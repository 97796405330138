import React, { useContext, useEffect, useState } from "react";

import { StakingApp } from "../../Page/Context/StakingApp";
import axiosInstance1 from "../../Config1/axios";
import { API_URLS1 } from "../../Config1/apiUrls";
import { toast } from "react-toastify";
import { useAccount, useContractWrite } from "wagmi";
import logo from "../../Images/loaderimg.png";
import CryptoJS from "crypto-js";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  ExclusiveAddress,
  StakingAbi,
  stakingAddress,
} from "../../Page/Context/constant";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Dialog,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import Slider from "react-slick";
import { Loder } from "../../Alldilog/Loder";
import Menu from "../../Components/Menu/Menu";
import Notification from "../../Components/Notification/Notification";

export default function Exclusive() {
  const { stakId, aprove1, name, ByAprove1 } = useContext(StakingApp);
  const { address } = useAccount();
  const navigate = useNavigate();
  const [isLoding, setIsLoding] = useState(true);
  const [isLoding1, setIsLoding1] = useState(false);
  const [nftaccount, setNftaccount] = useState();
  const [stakedList, setStakedList] = useState();
  const [staked, setStaked] = useState();
  const [isChecked, setisChecked] = useState([]);
  const [isChecked1, setisChecked1] = useState([]);
  const [decrptedData, setDecrptedData] = useState("");
  const [text, setText] = useState("");
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [sin, setSin] = useState();
  const [sin1, setSin1] = useState();
  const [data, setdata] = useState();
  const [timess, SetTimes] = useState();
  const [listData, setListData] = useState();
  const [listData2, setListDat2] = useState();
  const [decrptedData1, setDecrptedData1] = useState("");
  const [checked, setChecked] = React.useState(false);
  const { setPage } = useContext(StakingApp);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const back = () => {
    toast.error("Your Session have expired");
    localStorage.clear();
    navigate("/");
  };
  const handleClose1 = () => {
    setOpen1(false);
  };
  const handelopen1 = () => {
    setOpen1(true);
  };

  const stak = () => {
    handelopen1();
    setListData(isChecked);
  };
  const handleClose2 = () => {
    setOpen2(false);
  };
  const handelopen2 = () => {
    setOpen2(true);
  };

  const unstak = () => {
    handelopen2();
    setListDat2(isChecked1);
  };

  const [data1, setdata1] = useState();
  const [timess1, SetTimes1] = useState();
  const secretPass = process.env.REACT_APP_Child_Key;
  const getwalletnft = () => {
    axiosInstance1
      .post(API_URLS1.getwalletnft, {
        nftType: "Exclusive",
      })
      .then((res) => {
        if (res.data.nftdata !== nftaccount) {
          setIsLoding(true);
        }
        setNftaccount(() => {
          if (res.data.nftdata !== nftaccount) {
            return res.data.nftdata;
          }
        });
        setIsLoding(false);
      })
      .catch((err) => {
        setIsLoding(false);
        err?.response?.data?.Response_code === 401 && back();
      });
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      getwalletnft();
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  const getStakedlist = () => {
    axiosInstance1
      .post(API_URLS1.getStakedlist, {
        nft_category: 1,
      })
      .then((res) => {
        setStakedList(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        err?.response?.data?.Response_code === 401 && back();
      });
  };
  useEffect(() => {
    getStakedlist();
  }, []);

  const Stakedlist = () => {
    axiosInstance1
      .post(API_URLS1.Stakedlist, {
        nft_category: 1,
        stake_status: "success",
      })
      .then((res) => {
        setStaked(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        err?.response?.data?.Response_code === 401 && back();
      });
  };
  useEffect(() => {
    Stakedlist();
  }, []);

  ///staking

  //sing     ...------------
  const CheckApi = () => {
    const timestamps = Date.now() / 1000;
    const postData = {
      timestamp: parseInt(timestamps),
      addressTo: address,
      status: true,
    };
    SetTimes(postData.timestamp);

    const formData = new URLSearchParams();
    for (const [key, value] of Object.entries(postData)) {
      formData.append(key, value);
    }
    axiosInstance1
      .post(API_URLS1.VerifySignvalue, postData)
      .then((res) => {
        setdata(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        err?.response?.data?.Response_code === 401 && back();
      });
  };

  useEffect(() => {
    data && check();
  }, [data]);

  const check = () => {
    const jsonData = data;
    const one = jsonData?.addr_1?.slice(2);
    const two = jsonData?.addr_2?.slice(2);
    const three = jsonData?.addr_3?.slice(2);
    const four = jsonData?.addr_4?.slice(2);
    const five = jsonData?.addr_5?.slice(2);

    const signature = `${one}${two}${three}${four}${five}`;

    const bytes = CryptoJS.AES.decrypt(signature, secretPass);
    const data1 = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    setDecrptedData(data1);
  };

  //sign    .....------

  const postData = () => {
    axiosInstance1
      .post(API_URLS1.StbyU, { data: isChecked })
      .then((res) => {
        // toast.success("");
        setSin(encryptData(res.data.order_id));
        handleClose1();
        setIsLoding1(true);

        getStakedlist();
      })
      .catch((err) => {
        console.log(err);
        err?.response?.data?.Response_code === 401 && back();
      });
  };

  const encryptData = (text) => {
    const data = CryptoJS.AES.encrypt(
      JSON.stringify(text),
      secretPass
    ).toString();
    return data;
  };
  const identifiers = isChecked?.map((item) => parseInt(item?.identifier));
  useEffect(() => {
    decrptedData?.length > 0 && stake();
  }, [decrptedData?.length > 0 ? true : false]);

  const {
    write: stake,
    isLoading: val2,
    error,
  } = useContractWrite({
    address: stakingAddress,
    abi: StakingAbi,
    functionName: "stake",
    args: [
      ExclusiveAddress,
      identifiers,
      true,
      decrptedData.toString(),
      timess,
    ],
    onError(data) {
      console.log(data, "hello");
      setDecrptedData("");
      setIsLoding1(false);
    },
    onSuccess(data) {
      StakedSuccess(data.hash.toString());
      setDecrptedData("");
      setIsLoding1(false);
      handleClose1();
    },
  });

  useEffect(() => {
    error && StakdFailed();
    error && setDecrptedData("");
    error && handleClose1();
  }, [error]);

  const StakedSuccess = (e) => {
    axiosInstance1
      .post(API_URLS1.StakedSuccess, { conn: sin, taxId: e })
      .then((res) => {
        toast.success("Staking Successful");
        getStakedlist();
        Stakedlist();
        setisChecked([]);
      })
      .catch((err) => {
        console.log(err);
        err?.response?.data?.Response_code === 401 && back();
      });
  };
  const StakdFailed = (e) => {
    axiosInstance1
      .post(API_URLS1.StakdFailed, { conn: sin, taxId: e })
      .then((res) => {
        toast.error("Staking Failed");
        getStakedlist();
        setisChecked([]);
      })
      .catch((err) => {
        console.log(err);
        err?.response?.data?.Response_code === 401 && back();
      });
  };

  useEffect(() => {
    sin && CheckApi();
  }, [sin]);
  /////staking End
  const UnstakeNft = () => {
    axiosInstance1
      .post(API_URLS1.UnstakeNft, { data: isChecked1 })
      .then((res) => {
        // toast.success("Unstaking Successful");
        setSin1(encryptData1(res.data.order_id));
        handleClose2();
        setIsLoding1(true);
        setisChecked1([]);
      })
      .catch((err) => {
        console.log(err);
        err?.response?.data?.Response_code === 401 && back();
      });
  };

  const identifiers1 = isChecked1?.map((item) => parseInt(item?.identity_id));
  const encryptData1 = (text) => {
    const data = CryptoJS.AES.encrypt(
      JSON.stringify(text),
      secretPass
    ).toString();
    return data;
  };

  const CheckApi1 = () => {
    const timestamps = Date.now() / 1000;
    const postData = {
      timestamp: parseInt(timestamps),
      addressTo: address,
      status: true,
    };
    SetTimes1(postData.timestamp);

    const formData = new URLSearchParams();
    for (const [key, value] of Object.entries(postData)) {
      formData.append(key, value);
    }
    axiosInstance1
      .post(API_URLS1.VerifySignvalue, postData)
      .then((res) => {
        setdata1(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        err?.response?.data?.Response_code === 401 && back();
      });
  };

  useEffect(() => {
    data1 && check1();
  }, [data1]);

  const check1 = () => {
    const jsonData = data1;
    const one = jsonData?.addr_1?.slice(2);
    const two = jsonData?.addr_2?.slice(2);
    const three = jsonData?.addr_3?.slice(2);
    const four = jsonData?.addr_4?.slice(2);
    const five = jsonData?.addr_5?.slice(2);
    const signature = `${one}${two}${three}${four}${five}`;
    const bytes = CryptoJS.AES.decrypt(signature, secretPass);
    const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    setDecrptedData1(data);
  };

  useEffect(() => {
    decrptedData1?.length > 0 && stake1();
  }, [decrptedData1?.length > 0 ? true : false]);

  const {
    write: stake1,
    isLoading: val1,
    error1,
  } = useContractWrite({
    address: stakingAddress,
    abi: StakingAbi,
    functionName: "unstake",
    args: [
      ExclusiveAddress,
      identifiers1,
      true,
      decrptedData1.toString(),
      timess1,
    ],
    onError(data) {
      UnstakeFailed();
      setDecrptedData1("");
      setIsLoding1(false);
    },
    onSuccess(data) {
      UnstakeSuccess(data.hash.toString());
      setDecrptedData1("");
      setIsLoding1(false);
    },
  });

  // ;

  useEffect(() => {
    error1 && UnstakeFailed();
    error1 && setDecrptedData1("");
  }, [error1]);

  const UnstakeSuccess = (e) => {
    axiosInstance1
      .post(API_URLS1.updateUnstakeSuccess, {
        conn: sin1,
        taxId: e,
      })
      .then((res) => {
        toast.success("Unstaking Successful");
        getwalletnft();
        getStakedlist();
        setisChecked([]);
        Stakedlist();
      })
      .catch((err) => {
        console.log(err);
        err?.response?.data?.Response_code === 401 && back();
      });
  };
  const UnstakeFailed = (e) => {
    axiosInstance1
      .post(API_URLS1.updateUnstakeFailed, { conn: sin1, taxId: "hfbwrgerj" })
      .then((res) => {
        toast.error("Unstaking Failed");
        getwalletnft();
        getStakedlist();
        setisChecked([]);
      })
      .catch((err) => {
        console.log(err);
        err?.response?.data?.Response_code === 401 && back();
      });
  };

  useEffect(() => {
    sin1 && CheckApi1();
  }, [sin1]);

  const handlecheckbox = (e) => {
    const { value, checked } = e.target;
    const [identifier, image_url, contract, name] = value.split(",");
    const name1 = name.split("#");

    if (checked) {
      setisChecked([
        ...isChecked,
        {
          identity_id: identifier,
          image: image_url,
          nft_type: "rare",
          staketype: 1,
          token: contract,
          title: name1?.[0],
          rarity: "null",
          identifier,
        },
      ]);
    } else {
      setisChecked(isChecked.filter((item) => item.identifier !== identifier));
    }
  };
  useEffect(() => {
    sin && CheckApi();
  }, [sin]);

  const handlecheckbox1 = (e) => {
    const { value, checked } = e.target;
    const [id, identity_id, image] = value.split(",");
    if (checked) {
      setisChecked1([
        ...isChecked1,
        {
          id,
          unstakein: id,
          identity_id,
          taxId: "user Reg",
          image,
        },
      ]);
    } else {
      setisChecked1(isChecked1.filter((item) => item.id !== id));
    }
  };

  console.log(nftaccount?.length, "nftaccount.length");

  return (
    <>
      {isLoding1 && <Loder />}
      {isLoding ? (
        <Loder />
      ) : (
        <div className="lg:flex lg:px-10 gap-10 justify-center items-center ">
          <Menu />

          <div className="lg:w-[80%] lg:h-[90vh] flex flex-col  py-3 !overflow-y-auto example">
            <Notification />
            <div className="flex-col flex lg:py-5 lg:px-10 px-5">
              <p className="text-white font-bold my-5 lg:text-2xl text-xl">
                Mowgli{" "}
                <b className="text-[#E1A93C] italic text-3xl">
                  Exclusive Staking
                </b>
              </p>
              {nftaccount?.length === 0 ? (
                <div className="w-full flex  h-[100%] justify-center items-center  ">
                  <div>
                    <img src={logo} alt="" className="h-52 w-52" />
                    <a
                      href="https://opensea.io/collection/the-mowgli-sanctuary/overview"
                      className="flex py-3 px-5 bg-blue-400 w-64 cursor-pointer my-10 text-xl items-center justify-center text-white font-bold"
                      target="_blank"
                    >
                      Open Sea
                    </a>
                  </div>
                </div>
              ) : (
                <>
                  <div className="lg:flex text-white gap-5">
                    <div className="flex lg:w-[50%]">
                      <div className="w-full">
                        <div className="flex justify-between">
                          <p
                            onClick={CheckApi}
                            className="text-white font-bold text-xl underline"
                          >
                            Available for staking
                          </p>

                          {isChecked.length >= 1 && (
                            <>
                              {aprove1 ? (
                                <button
                                  className="border px-10 py-1 text-white rounded-md  bg-green-500  border-gray-500"
                                  onClick={stak}
                                >
                                  Stake
                                </button>
                              ) : (
                                <button
                                  className="border px-10 py-1 text-white rounded-md  bg-green-500  border-gray-500"
                                  onClick={() => ByAprove1()}
                                >
                                  Aprove
                                </button>
                              )}
                            </>
                          )}
                        </div>
                        {nftaccount ? (
                          <div className="flex overflow-auto scbox  text-white gap-5 px-5 my-5">
                            {nftaccount?.map((item) => {
                              return (
                                <Card
                                  sx={{
                                    minWidth: 200,
                                    backgroundColor: "#E1A93C",
                                    borderRadius: 2,
                                  }}
                                >
                                  <div className=" relative flex flex-col">
                                    <input
                                      type="checkbox"
                                      value={`${
                                        item.identifier
                                      },${`https://bafybeib2ocbry6wrodf6t6ubz2gm2s7eeeag5n6epdi3rpjqafjzh5o724.ipfs.nftstorage.link/${item.identifier}.png`},${
                                        item.contract
                                      },${item.name}`}
                                      checked={item.isChecked}
                                      onChange={(e) => handlecheckbox(e)}
                                      className="flex absolute right-2 top-2 h-5 w-5 bg-green-500"
                                    />
                                    <img
                                      src={`https://bafybeib2ocbry6wrodf6t6ubz2gm2s7eeeag5n6epdi3rpjqafjzh5o724.ipfs.nftstorage.link/${item.identifier}.png`}
                                      className="rounded-md  h-52 "
                                      alt={`https://bafybeib2ocbry6wrodf6t6ubz2gm2s7eeeag5n6epdi3rpjqafjzh5o724.ipfs.nftstorage.link/${item.identifier}.png`}
                                    />
                                    <p className="text-white text-center">
                                      {item.name}
                                    </p>
                                  </div>
                                </Card>
                              );
                            })}
                          </div>
                        ) : (
                          <div className="flex flex-col justify-center items-center h-96">
                            <p className="text-2xl font-bold text-blue-500">
                              You have no NFT's
                            </p>
                            <Link
                              to="https://opensea.io/collection/the-mowgli-sanctuary/overview"
                              className="  px-8 py-1 rounded-3xl bg-green-500 my-5 cursor-pointer hover:bg-green-300 hover:text-black hover:font-bold"
                            >
                              Purchase
                            </Link>
                          </div>
                        )}
                      </div>
                    </div>
                    {stakedList && (
                      <div className="flex lg:w-[50%]">
                        <div className="w-full">
                          <div className="flex justify-between">
                            <p className="text-white font-bold text-xl underline">
                              Staked NFT's
                            </p>

                            {isChecked1.length >= 1 && (
                              <button
                                className="border px-10 py-1 text-white rounded-md  bg-red-500  border-gray-500"
                                onClick={unstak}
                              >
                                Unstake
                              </button>
                            )}
                          </div>

                          <div className="flex overflow-auto scbox text-white gap-5 px-5 my-5">
                            {stakedList?.map((item) => {
                              return (
                                <Card
                                  sx={{
                                    minWidth: 200,
                                    backgroundColor: "#E1A93C",
                                    borderRadius: 2,
                                  }}
                                >
                                  {" "}
                                  <div className=" relative flex flex-col">
                                    {item.isStory === 0 ? (
                                      <input
                                        type="checkbox"
                                        value={`${item.id},${item.identity_id},${item.image}`}
                                        checked={item.isChecked}
                                        onChange={(e) => handlecheckbox1(e)}
                                        className="flex absolute right-2 top-2 h-5 w-5 bg-green-500"
                                      />
                                    ) : (
                                      <Chip
                                        label={`allot to ${item.storyTitle}`}
                                        className="flex absolute right-0 !rounded-none bottom-8 !w-full !font-bold !text-white !bg-[#0547CB]"
                                      />
                                    )}

                                    <img
                                      src={item?.image}
                                      alt={item?.image}
                                      className="rounded-md h-52 "
                                    />
                                    <div className="flex flex-col justify-center items-center text-white text-sm my-1">
                                      <p>
                                        {moment(item.stake_at).format("ll")}
                                      </p>
                                    </div>
                                  </div>
                                </Card>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  {staked && (
                    <div className="flex justify-center items-center shadow-md shadow-[#E1A93C] rounded-2xl my-5 p-5">
                      <TableContainer className="!border example !text-blue-500 h-[40vh] ">
                        <Table stickyHeader>
                          <TableHead>
                            <TableRow className="">
                              <TableCell className="!text-black !font-bold">
                                Image
                              </TableCell>
                              <TableCell className="!text-black !font-bold">
                                Name
                              </TableCell>
                              <TableCell className="!text-black !font-bold">
                                Stake at
                              </TableCell>
                              <TableCell className="!text-black !font-bold">
                                Unstake at
                              </TableCell>
                              <TableCell className="!text-black !font-bold">
                                Point
                              </TableCell>
                              <TableCell className="!text-black !font-bold !text-center">
                                Stake type
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {staked?.map((row) => (
                              <TableRow
                                key={row.name}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell className="!text-black !font-bold">
                                  <img
                                    src={row.image}
                                    alt=""
                                    className="h-14 "
                                  />
                                </TableCell>
                                <TableCell className="!text-white">
                                  #{row.identity_id}
                                </TableCell>
                                <TableCell className="!text-white">
                                  {row.stake_at === null
                                    ? ""
                                    : moment(row.stake_at).format("L")}
                                </TableCell>
                                <TableCell className="!text-white">
                                  {row.unstake_at === null
                                    ? ""
                                    : moment(row.unstake_at).format("L")}
                                </TableCell>

                                <TableCell className="!text-white">
                                  30
                                </TableCell>
                                <TableCell className="text-white">
                                  <p
                                    className={
                                      row.stake_type === "stake"
                                        ? " text-green-500 text-center py-1"
                                        : " text-red-500 text-center py-1"
                                    }
                                  >
                                    {row.stake_type}
                                  </p>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  )}
                </>
              )}

              <Dialog
                open={open1}
                onClose={handleClose1}
                PaperProps={{
                  className:
                    "!lg:w-[40%] !lg:min:h-[50%] lg:p-1 p-1 flex gap-3 flex-col !rounded-2xl !text-shadow-none !bg-[#041125] !p-5  border border-[#078043] ",
                }}
              >
                <div className="flex flex-col justify-center items-center">
                  <div className="grid grid-cols-3 gap-3 text-white">
                    {listData?.map((i) => {
                      return <img src={i.image} alt={i.token} />;
                    })}
                  </div>
                  <div className="flex justify-center gap-5 items-center">
                    <button
                      onClick={postData}
                      className="px-8 py-1 rounded-3xl bg-green-500 my-5 cursor-pointer hover:bg-green-300 hover:text-black font-bold  text-white"
                    >
                      Confirm
                    </button>
                    <button
                      onClick={handleClose1}
                      className="px-8 py-1 rounded-3xl bg-red-500 my-5 cursor-pointer hover:bg-red-300 hover:text-black font-bold  text-white"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </Dialog>
              <Dialog
                open={open2}
                onClose={handleClose2}
                PaperProps={{
                  className:
                    "!lg:w-[40%] !lg:min:h-[50%] lg:p-1 p-1 flex gap-3 flex-col !rounded-2xl !text-shadow-none !bg-[#041125] !p-5  border border-[#078043] ",
                }}
              >
                <div className="flex flex-col ">
                  <p className="text-white font-bold text-xl my-2">
                    UnStake Your Nft's
                  </p>
                  <div className="grid lg:grid-cols-3 grid-cols-2 gap-3">
                    {listData2?.map((i) => {
                      return (
                        <img src={i.image} alt="" className="rounded-lg" />
                      );
                    })}
                  </div>
                  <span className="flex  gap-3 my-2">
                    <input
                      type="checkbox"
                      checked={checked}
                      onChange={handleChange}
                      className="flex h-5 w-5 mt-2 bg-green-500"
                    />

                    <p className="text-white my-2 text-sm ">
                      Are you sure you want to unstake your NFT after 30 days of
                      staking? If you unstake before 30 days, you will receive
                      no rewards and also you come at start point.
                    </p>
                  </span>
                  {!checked && (
                    <p className="text-sm text-red-500 italic">{text}</p>
                  )}

                  <div className="flex justify-center gap-5 items-center">
                    {checked ? (
                      <button
                        onClick={UnstakeNft}
                        className="px-8 py-1 rounded-3xl bg-green-500 my-5 cursor-pointer hover:bg-green-300 hover:text-black font-bold  text-white"
                      >
                        Confirm
                      </button>
                    ) : (
                      <button
                        onClick={() => setText("Please check confirmation box")}
                        className="px-8 py-1 rounded-3xl bg-green-500 my-5 cursor-pointer hover:bg-green-300 hover:text-black font-bold  text-white"
                      >
                        Confirm
                      </button>
                    )}

                    <button
                      onClick={handleClose2}
                      className="px-8 py-1 rounded-3xl bg-red-500 my-5 cursor-pointer hover:bg-red-300 hover:text-black font-bold  text-white"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </Dialog>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
