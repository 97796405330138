"use Server";
import React, { useState, createContext } from "react";
import {
  ExclusiveAbi,
  ExclusiveAddress,
  SanctuaryAbi,
  SanctuaryAddress,
  aproveAddress,
  aprovegAbi,
  stakingAddress,
} from "./constant";
import { useAccount, useContractRead, useContractWrite } from "wagmi";
import { toast } from "react-toastify";

export const StakingApp = createContext();

export const Staking = ({ children }) => {
  const [name, setname] = useState();
  const [stiryId, setStiryId] = useState();
  const { address } = useAccount();
  const [stakId, setStakId] = useState("");
  const [page, setPage] = useState("dashboard");
  const [aprove, setAprove] = useState();
  const [aprove1, setAprove1] = useState();
  const [balanceOf, setBalanceOf] = useState();
  const [balanceOf1, setBalanceOf1] = useState();

  useContractRead({
    address: SanctuaryAddress,
    abi: SanctuaryAbi,
    functionName: "isApprovedForAll",
    args: [address, stakingAddress],
    onSuccess(data) {
      setAprove(data);
    },
    watch: true,
  });
  useContractRead({
    address: ExclusiveAddress,
    abi: ExclusiveAbi,
    functionName: "isApprovedForAll",
    args: [address, stakingAddress],
    onSuccess(data) {
      setAprove1(data);
    },
    watch: true,
  });
  useContractRead({
    address: ExclusiveAddress,
    abi: ExclusiveAbi,
    functionName: "balanceOf",
    args: [address],
    onSuccess(data) {
      setBalanceOf(Number(data));
    },
    watch: true,
  });
  useContractRead({
    address: SanctuaryAddress,
    abi: SanctuaryAbi,
    functionName: "balanceOf",
    args: [address],
    onSuccess(data) {
      setBalanceOf1(Number(data));
    },
    watch: true,
  });

  const { write: ByAprove1, isLoading: va1 } = useContractWrite({
    address: ExclusiveAddress,
    abi: ExclusiveAbi,
    functionName: "setApprovalForAll",
    args: [stakingAddress, true],
    onError(data) {
      const dat = data?.toString()?.split("Contract Call");
      toast.warn(`ClaimRoi is Warning ${dat?.[0]}`);
    },
    onSuccess(data) {
      toast.success(`ClaimRoi is Successful ${data.hash.toString()}`);
    },
  });
  const {
    write: ByAprove,
    isLoading: val,
    error,
    isError,
  } = useContractWrite({
    address: SanctuaryAddress,
    abi: SanctuaryAbi,
    functionName: "setApprovalForAll",
    args: [stakingAddress, true],
    onError(data) {
      const dat = data?.toString()?.split("Contract Call");
      toast.warn(`ClaimRoi is Warning ${dat?.[0]}`);
    },
    onSuccess(data) {
      toast.success(`ClaimRoi is Successful ${data.hash.toString()}`);
    },
  });

  return (
    <StakingApp.Provider
      value={{
        stiryId,
        setStiryId,
        page,
        setPage,
        stakId,
        setStakId,
        aprove,
        aprove1,
        name,
        balanceOf,
        balanceOf1,
        setname,
        ByAprove,
        ByAprove1,
      }}
    >
      {children}
    </StakingApp.Provider>
  );
};
