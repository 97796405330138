import React, { useState, useEffect } from "react";
import { Box, CircularProgress, Dialog } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useAccount, useSignMessage } from "wagmi";
// import "dotenv/config";

import axios from "axios";
import { SiweMessage } from "siwe";
import axiosInstance1, { baseURL1 } from "../../Config1/axios";
import { API_URLS1 } from "../../Config1/apiUrls";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import CreateButoon from "../ConnectButton";
import ButtonLoder from "../ButtonLoder";

// import ErrorMessage from "./ErrorMessage";

export default function Sign() {
  const [open, setOpen] = useState(false);
  const { address, isDisconnected } = useAccount();
  const [masg, setMasg] = useState();
  const [status, setStatus] = useState(false);
  const [add, setAdd] = useState();

  const handleClose = () => {
    setOpen(false);
  };
  const handelopen = () => {
    setOpen(true);
  };

  const navigate = useNavigate();

  useEffect(() => {
    address && !status && handelopen();
  }, [status]);

  console.log(!status, "!status");

  const { data: signMessageData, isLoading, signMessage } = useSignMessage();
  const domain = window.location.host;
  const origin = window.location.origin;

  const statement = "User authentications ";

  async function createSiweMessage() {
    const res = await fetch(`${baseURL1}get-nonce`);
    const message = new SiweMessage({
      domain,
      address,
      statement,
      uri: origin,
      version: "1",
      chainId: "1",
      nonce: await res.nonce,
      expirationTime: "2024-03-27T12:00:00Z",
    });
    signMessage({
      message: message.prepareMessage(),
    });
    setMasg(message.prepareMessage());
  }
  const Activ = () => {
    axiosInstance1
      .post(API_URLS1.loginactivity, {})
      .then((res) => {
        handleClose();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    Activ();
  }, []);
  const CheckApi = () => {
    axiosInstance1
      .post(API_URLS1.login, {
        signedMessage: signMessageData,
        message: masg,
        address: address,
      })
      .then((res) => {
        Cookies.set("token", res.data.token);
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("address", res.data.address);
        Activ();
        Verify();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const cook = Cookies.get("myCookie");
  // console.log(cook, "cook");

  const Verify = () => {
    axiosInstance1
      .post(API_URLS1.checkToken, {})
      .then((res) => {
        setStatus(res.data.Tokenstatus);
        setOpen(res.data.Tokenstatus ? false : true);
        Activ();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    Verify();
  }, [address]);

  useEffect(() => {
    signMessageData && CheckApi();
  }, [signMessageData]);

  useEffect(() => {
    isDisconnected && Verify();
  }, [isDisconnected]);

  return (
    <div>
      <div>
        {address ? (
          status ? (
            <p
              onClick={() => navigate("/dashboard")}
              className="text-xl font-bold my-2 underline cursor-pointer"
            >
              Dashboard
            </p>
          ) : (
            <p
              onClick={handelopen}
              className="text-xl font-bold my-2 underline cursor-pointer "
            >
              Dashboard
            </p>
          )
        ) : (
          <p
            onClick={handelopen}
            className="text-xl font-bold my-2 underline cursor-pointer "
          >
            Dashboard
          </p>
        )}

        {/* <p
            onClick={() => Verify()}
            className="text-2x font-bold text-white cursor-pointer lg:block hidden"
          >
            very
          </p> */}
      </div>

      {/* <button className="bg-blue-500 text-white px-5 text-sm py-1 rounded-lg font-bold my-3"></button> */}
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          PaperProps={{
            className:
              "!min-w-[20%] !min-h-[25%] p-5 flex gap-3 flex-col !rounded-2xl !text-shadow-none  !bg-gray-500",
          }}
        >
          <div className=" flex flex-col justify-center items-center">
            <div
              onClick={handleClose}
              className=" absolute top-3 right-3 border p-1 hover:bg-gray-200 cursor-pointer  rounded-full "
            >
              <CloseIcon />
            </div>
            <div className="flex flex-col justify-center items-center mt-10">
              <p className="text-xl font-bold text-black">
                Verify your account
              </p>
              <p className="w-64 text-sm text-center text-white my-3">
                To finish connecting, you must sign a message in your wallet to
                verify that you are the owner of this account.
              </p>
            </div>
            {address ? (
              isLoading ? (
                <button
                  className="bg-white text-white  text-sm py-1 rounded-lg font-bold my-3 h-10 w-52"
                  // onClick={createSiweMessage}
                >
                  <ButtonLoder />
                </button>
              ) : (
                <button
                  className="bg-blue-500 text-white px-5 text-sm py-1 rounded-lg font-bold my-3 "
                  onClick={createSiweMessage}
                >
                  Sign Message
                </button>
              )
            ) : (
              <div className="border py-1 px-5 rounded-xl hover:bg-black my-2">
                <CreateButoon />
              </div>
            )}

            <p
              onClick={handleClose}
              className="text-black font-bold cursor-pointer"
            >
              Cancel
            </p>
          </div>
        </Dialog>
      </div>
    </div>
  );
}
