import React, { useContext, useEffect, useState } from "react";
import favcon from "../../Images/favicon.png";
import AddIcon from "@mui/icons-material/Add";
import { Chip, Dialog, Divider, Tooltip } from "@mui/material";
import axiosInstance1 from "../../Config1/axios";
import { API_URLS1 } from "../../Config1/apiUrls";
import { toast } from "react-toastify";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import { Link, useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import { StakingApp } from "../../Page/Context/StakingApp";
import hand from "../../Images/Hand.png";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Loder } from "../../Alldilog/Loder";
import moment from "moment";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useAccount } from "wagmi";
import Menu from "../../Components/Menu/Menu";
import Notification from "../../Components/Notification/Notification";
import ButtonLoder from "../../Alldilog/ButtonLoder";

export default function Storay() {
  const [id2, setId2] = useState();
  const [open, setOpen] = useState(false);
  const [tatile, setTatile] = useState("My Story");
  const [title1, setTatile1] = useState("My Story");
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [page, setPage1] = useState(false);
  const [mowgli, setmowgli] = useState();
  const [kaa, setkaa] = useState();
  const [bhageera, setbhageera] = useState();
  const [kinglouis, setkinglouis] = useState();
  const [sherekhan, setsherekhan] = useState();
  const [baloo, setbaloo] = useState();
  const [data, setData] = useState();
  const [nft, setNft] = useState();
  const [nft1, setNft1] = useState();
  const [nft2, setNft2] = useState();
  const [less, setLess] = useState("");
  const [getStory, setGetStory] = useState();
  const [dsc, setDsc] = useState("");
  const [dsc1, setDsc1] = useState("");
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);
  const [storyId, setStoryId] = useState();
  const [msg, setmsg] = useState();
  const [loading, setLoading] = useState(true);
  const [loading1, setLoading1] = useState(true);
  const [loading2, setLoading2] = useState(false);
  const { address } = useAccount();
  const [run, setRun] = useState(true);

  const { balanceOf, balanceOf1, setStiryId, setPage } = useContext(StakingApp);
  const handleClose5 = () => {
    setOpen5(false);
  };
  const handelopen5 = (i) => {
    setOpen5(true);
  };

  const handleClose3 = () => {
    setOpen3(false);
  };
  const handelopen3 = (i) => {
    setOpen3(true);
    setId2(i);
  };
  const handleClose4 = () => {
    setOpen4(false);
  };
  const handelopen4 = (i) => {
    setOpen4(true);
  };
  const navigate = useNavigate();
  const back = () => {
    toast.error("Your Session have expired");
    localStorage.clear();
    navigate("/");
  };

  const Date = moment().format();
  const arr = [
    { stakedId: mowgli?.id },
    { stakedId: kaa?.id },
    { stakedId: bhageera?.id },
    { stakedId: kinglouis?.id },
    { stakedId: sherekhan?.id },
    { stakedId: baloo?.id },
  ];

  const AddStory = () => {
    setLoading2(true);
    axiosInstance1
      .post(API_URLS1.addCreateStory, {
        stakedDescription: dsc,
        storyTitle: tatile,
        data: arr,
      })
      .then((res) => {
        res.data.Response_code === 200
          ? toast.success(res.data.msg)
          : toast.error(res.data.msg);

        getStory1();
        res.data.Response_code === 200 && handleClose();
        setLoading2(false);
      })
      .catch((err) => {
        setmsg(err?.response?.data?.msg);
        setLoading2(false);
        err?.response?.data?.Response_code === 401 && back();
      });
  };
  const reUpStory = (id) => {
    axiosInstance1
      .post(API_URLS1.nftupdateinstory, {
        stakedId: id,
        StoryId: storyId,
      })
      .then((res) => {
        // toast.success(res.data.msg);
        handleClose2();
        getStory1();
      })
      .catch((err) => {
        console.log(err);
        err?.response?.data?.Response_code === 401 && back();
      });
  };
  const EditStory = () => {
    axiosInstance1
      .post(API_URLS1.editStory, {
        StoryId: id2,
        stakedDescription: dsc1,
        storyTitle: title1,
      })
      .then((res) => {
        toast.success("Edit story Successful");
        getStory1();
        handleClose3();
      })
      .catch((err) => {
        console.log(err);
        err?.response?.data?.Response_code === 401 && back();
      });
  };

  const TerStory = () => {
    axiosInstance1
      .post(API_URLS1.ExitStory, {
        StoryId: id2,
      })
      .then((res) => {
        toast.success("Disband your story");
        getStory1();
        handleClose3();
        handleClose4();
      })
      .catch((err) => {
        console.log(err);
        err?.response?.data?.Response_code === 401 && back();
      });
  };

  const getStory1 = () => {
    axiosInstance1
      .post(API_URLS1.createdStorylist, {})
      .then((res) => {
        setGetStory(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        err?.response?.data?.Response_code === 401 && back();
      });
  };

  useEffect(() => {
    getStory1();
  }, []);

  const allClear = () => {
    setmowgli("");
    setkaa("");
    setbhageera("");
    setkinglouis("");
    setsherekhan("");
    setbaloo("");
  };

  const getStakedlist = () => {
    axiosInstance1
      .post(API_URLS1.Storylist, {})
      .then((res) => {
        setPage1(res.data.data[0]);
      })
      .catch((err) => {
        console.log(err);
        err?.response?.data?.Response_code === 401 && back();
      });
  };
  const FliterNft = () => {
    setLoading1(true);
    axiosInstance1
      .post(API_URLS1.AvailableMakestory, {
        tokentitle: nft,
        tokenAddress: nft1,
        nft_category: nft2,
      })
      .then((res) => {
        setData(res.data.data);
        setLoading1(false);
        // setPage(res.data.data[0]);
      })
      .catch((err) => {
        console.log(err);
        err?.response?.data?.Response_code === 401 && back();
      });
  };

  useEffect(() => {
    FliterNft();
  }, [nft, nft1, nft2]);

  const dat1 = page?.exclusive?.split(",");
  const dat2 = page?.san_1?.split(",");
  const dat3 = page?.san_2?.split(",");
  const dat4 = page?.san_3?.split(",");
  const dat5 = page?.san_4?.split(",");
  const dat6 = page?.san_5?.split(",");

  useEffect(() => {
    getStakedlist();
  }, []);

  const handleCancel = () => {
    setDsc("");
    setTatile("");
  };

  const handleClose = () => {
    setOpen(false);
    setDsc("");
    setTatile("My Story");
    allClear();
  };
  const handelopen = () => {
    setOpen(true);
    setRun(false);
  };
  const handleClose1 = () => {
    setOpen1(false);
    setNft1("");
    setNft("");
    setData([]);
  };
  const handelopen1 = () => {
    setOpen1(true);
  };
  const handleClose2 = () => {
    setOpen2(false);
    setNft1("");
    setNft("");
    setData([]);
  };
  const handelopen2 = () => {
    setOpen2(true);
  };
  const onCopyLink = (id) => {
    navigator?.clipboard?.writeText(id);
  };

  const TotalPoints = getStory?.reduce((total, row) => row?.totalpoint, 0);

  var sum = 0;
  var sum1 = 0;

  getStory
    ?.filter((data) => data.story_status !== "progress")
    .map((item, index) => {
      var str = item?.totalpoint;
      const pairs = str?.split(",");
      const filteredArray = pairs.filter((item) => item.trim() !== "");
      filteredArray?.forEach((pair) => {
        const [key, valueStr] = pair?.split("=>");
        const value = parseInt(valueStr?.trim()); // Convert the value to integer
        sum += value;
      });
      sum1 += sum * item?.datediffrence;
      sum = 0;
    });

  const editdata = getStory?.filter((data) => data.id === id2);
  let arrayImage = {};

  if (editdata?.[0]?.statustype === null) {
    var imgString = editdata?.[0]?.imgsrc_json;
    let keyValuePairs = imgString?.split(",");
    let dataArray = keyValuePairs?.map((pair, index) => {
      const [key, value] = pair.split("=>");
      arrayImage[key] = value;
      return key;
    });
  } else {
    const column1Values = editdata?.[0]?.merged_images?.split(",") ?? [];
    const column2Values = editdata?.[0]?.imgsrc_json?.split(",") ?? [];
    const uniqueValuesSet = new Set();
    column1Values.forEach((value) => {
      if (value.trim() !== "") {
        uniqueValuesSet.add(value.trim());
      }
    });
    column2Values.forEach((value) => {
      if (value.trim() !== "") {
        uniqueValuesSet.add(value.trim());
      }
    });
    const mergedValues = Array.from(uniqueValuesSet);

    let dataArray = mergedValues?.map((pair, index) => {
      const [key, value] = pair.split("=>");
      arrayImage[key] = value;
      return key;
    });
  }

  const mowgliIMG = arrayImage?.Mowgli ? arrayImage?.Mowgli.split("<>") : "";
  const Kaa = arrayImage?.Kaa ? arrayImage?.Kaa.split("<>") : "";
  const Bhageera = arrayImage?.Bhageera ? arrayImage?.Bhageera.split("<>") : "";
  const Kinglouis = arrayImage?.Kinglouis
    ? arrayImage?.Kinglouis.split("<>")
    : "";
  const ShereKhan = arrayImage?.ShereKhan
    ? arrayImage?.ShereKhan.split("<>")
    : "";
  const Baloo = arrayImage?.Baloo ? arrayImage?.Baloo.split("<>") : "";

  return (
    <>
      {loading ? (
        <Loder />
      ) : (
        <>
          <div className="lg:flex lg:px-10 gap-10 justify-center lg:h-screen items-center bg-gradient-to-r from-[#1b1b2e] from-8% via-[#140606] via-20% to-[#011825] to-60% ">
            <Menu />

            <div className="lg:w-[80%] lg:h-[90vh] flex flex-col  py-3 !overflow-y-auto example">
              <Notification />
              {/* <Joyride1 steps={steps} run={run} />{" "} */}
              <div className="flex-col flex lg:p-10 p-3 text-white">
                <p id="step-1" className="text-white font-bold text-2xl my-5">
                  Craft Your Mowgli Sanctuary Tale
                </p>
                <div className="flex justify-between my-5">
                  <span className="flex gap-3 border lg:justify-between border-indigo-200 border-y-indigo-500 lg:my-0 my-2 px-2 py-2 rounded-xl">
                    <p>Total</p>
                    <p className="font-bold">{sum1.toString()}</p>
                    <img
                      src={favcon}
                      alt=""
                      className="h-6 w-6 object-contain"
                    />
                  </span>
                  <div className="lg:flex justify-between gap-10">
                    <span
                      onClick={() => handelopen()}
                      id="step-2"
                      className="flex gap-3  justify-between px-2 py-2 rounded-xl lg:my-0 my-2 bg-[#5c2f9e] hover:bg-[#866dac] cursor-pointer text-white font-bold "
                    >
                      <p>Add Story</p>
                      <AddIcon className="!text-white" />
                    </span>
                  </div>
                </div>

                <div className="lg:flex justify-between text-white gap-5 mt-10">
                  <div className="relative overflow-hidden lg:flex flex-col lg:my-0 my-3 justify-center text-gray-100  w-full border border-[#866dac] rounded-lg p-5">
                    <p className="text-xl font-bold ">
                      Introducing Mowgli Story.
                    </p>
                    <p className="my-3 ">
                      Welcome to our captivating Jungle Realms adventure, where
                      staking your NFTs unlocks a gateway to unparalleled
                      storytelling and rewards. As you immerse yourself in our
                      vibrant ecosystem, your staked NFTs become tokens of
                      creativity, granting you access to co-create our thrilling
                      narrative featuring Mowgli and his animal allies. Join us
                      as we blend art, technology, and imagination into an
                      unforgettable journey filled with exclusive benefits and
                      collaborative storytelling like never before!
                    </p>
                    <p className="  px-8 py-1 rounded-3xl bg-[#5c2f9e] my-5 cursor-pointerh hover:bg-[#866dac] hover:text-black hover:font-bold w-48">
                      Learn More
                    </p>
                  </div>
                  <div className=" relative overflow-hidden lg:my-0 my-3  border border-[#866dac] gap-5 lg:flex w-full  rounded-lg p-5">
                    {balanceOf !== 0 || balanceOf1 !== 0 ? (
                      <div className="flex flex-col  h-[400px] overflow-auto py-5 example  px-2">
                        <p className="text-xl font-bold ">
                          Welcome to Mowgli Sanctuary, where your NFTs are the
                          key to unlocking a world of imagination and adventure.
                          Your staked NFTs have opened the door to a unique
                          storytelling experience, where you can shape the
                          narrative of the sanctuary and earn exclusive rewards.
                        </p>
                        <p className="lg:pr-24 my-3 ">
                          In Mowgli Sanctuary, you will join Mowgli, Bagheera,
                          Kaa, Baloo, Shere Khan, and King Louie on a journey
                          through the lush jungle, encountering thrilling
                          challenges and forging deep bonds of friendship.
                        </p>
                        <p className="lg:pr-24 my-3 ">
                          Your decisions will influence the course of events,
                          leading to different outcomes and unlocking new
                          chapters in the story. Immerse yourself in the rich
                          lore of Mowgli Sanctuary, and let your creativity
                          guide you as you craft your own tale within this
                          enchanting world.
                        </p>
                        <p className="lg:pr-24 my-3 ">
                          As a token holder, you are not just a spectator but an
                          active participant in the unfolding story of Mowgli
                          Sanctuary. Your contributions will be rewarded with
                          exclusive in-game items, access to special events, and
                          the opportunity to leave a lasting impact on the
                          sanctuary and its inhabitants.
                        </p>
                        <p className="lg:pr-24 my-3 ">
                          Join us on this epic adventure and become a part of
                          the ever-evolving story of Mowgli Sanctuary. Your
                          journey begins now..
                        </p>
                        <p className="lg:pr-24 my-3 ">
                          Sincerely, The Mowgli Sanctuary Team
                        </p>
                        <p className="  px-8 py-1 rounded-3xl bg-[#5c2f9e] cursor-pointer hover:bg-[#866dac] hover:text-black hover:font-bold w-48">
                          Learn More
                        </p>
                      </div>
                    ) : (
                      <div className="flex flex-col  h-[400px] overflow-auto example  py-5  px-2">
                        <p className="text-xl font-bold ">
                          Welcome to Mowgli Sanctuary, a place where NFTs unlock
                          a world of wonders and adventures. It appears you
                          haven't yet acquired the key to this magical realm.
                        </p>
                        <p className="lg:pr-24 my-3 ">
                          By owning the NFTs representing the beloved characters
                          Mowgli, Bagheera, Kaa, Baloo, Shere Khan, and King
                          Louie, you can unlock exclusive content, join
                          thrilling events, and immerse yourself in the
                          sanctuary's enchanting lore.
                        </p>
                        <p className="lg:pr-24 my-3 ">
                          Don't miss out on the chance to be part of our
                          community of explorers. Visit OpenSea now to acquire
                          your NFTs and unlock the greatest benefits of Mowgli
                          Sanctuary.
                        </p>
                        <p className="  px-8 py-1 rounded-3xl bg-green-500 my-5 cursor-pointer hover:bg-green-300 hover:text-black hover:font-bold w-48">
                          Learn More
                        </p>
                      </div>
                    )}
                  </div>
                </div>
                <div className="grid lg:grid-cols-2 md:grid-cols-1 grid-cols-1 lg:gap-5">
                  {getStory
                    ?.filter((data) => data.story_status !== "end")
                    .map((i) => {
                      let arrayImage = {};

                      if (i.statustype === null) {
                        var imgString = i.imgsrc_json;
                        let keyValuePairs = imgString?.split(",");
                        let dataArray = keyValuePairs?.map((pair, index) => {
                          const [key, value] = pair.split("=>");
                          arrayImage[key] = value;
                          return key;
                        });
                      } else {
                        const column1Values = i.merged_images?.split(",") ?? [];
                        const column2Values = i.imgsrc_json?.split(",") ?? [];
                        const uniqueValuesSet = new Set();
                        column1Values.forEach((value) => {
                          if (value.trim() !== "") {
                            uniqueValuesSet.add(value.trim());
                          }
                        });
                        column2Values.forEach((value) => {
                          if (value.trim() !== "") {
                            uniqueValuesSet.add(value.trim());
                          }
                        });
                        const mergedValues = Array.from(uniqueValuesSet);

                        let dataArray = mergedValues?.map((pair, index) => {
                          const [key, value] = pair.split("=>");
                          arrayImage[key] = value;
                          return key;
                        });
                      }

                      var str = i?.totalpoint;
                      const pairs = str?.split(",");
                      const filteredArray = pairs.filter(
                        (item) => item.trim() !== ""
                      );
                      let storySum = 0;
                      filteredArray?.forEach((pair) => {
                        const [key, valueStr] = pair?.split("=>");
                        const value = parseInt(valueStr?.trim());
                        storySum += value;
                      });

                      const mowgliIMG = arrayImage?.Mowgli
                        ? arrayImage?.Mowgli.split("<>")
                        : "";
                      const Kaa = arrayImage?.Kaa
                        ? arrayImage?.Kaa.split("<>")
                        : "";
                      const Bhageera = arrayImage?.Bhageera
                        ? arrayImage?.Bhageera.split("<>")
                        : "";
                      const Kinglouis = arrayImage?.Kinglouis
                        ? arrayImage?.Kinglouis.split("<>")
                        : "";
                      const ShereKhan = arrayImage?.ShereKhan
                        ? arrayImage?.ShereKhan.split("<>")
                        : "";
                      const Baloo = arrayImage?.Baloo
                        ? arrayImage?.Baloo.split("<>")
                        : "";
                      return (
                        <div
                          className={
                            i.story_status === "progress"
                              ? "flex flex-col border-[2px] border-red-500 rounded-lg mt-5 px-3 py-1"
                              : "flex flex-col border-[2px] border-[#3e36a8] rounded-lg mt-5 px-3 py-1"
                          }
                        >
                          <div className="flex justify-between my">
                            <div className="flex justify-center gap-2">
                              <p className="text-gray-500 text-sm">Id:</p>
                              <p className="text-gray-500 text-sm">
                                {i.storyId_1}
                              </p>
                              <ContentCopyIcon
                                onClick={() => {
                                  onCopyLink(i.storyId_1);
                                  toast.success(
                                    <p>
                                      Copied <b>#{i.storyId_1}</b>
                                    </p>
                                  );
                                }}
                                className="!text-sm mt-1 cursor-pointer"
                              />
                            </div>
                            <div>
                              <p className="text-gray-500 text-end text-sm">
                                {i.datediffrence}
                                days ago
                              </p>
                              <span className="flex gap-3 px-2 py-2 justify-between rounded-xl border lg:my-0 my-2 border-indigo-200 border-y-indigo-500">
                                <img src={favcon} alt="" className="h-6 w-6" />
                                <p>{storySum ? storySum.toString() : "0"}</p>
                                <p>/ Day</p>
                              </span>
                            </div>
                          </div>
                          {less === i.id ? (
                            <div className="h-full bg-opacity-20 bg-[#078043] p-5 rounded-xl my-10">
                              <span className="flex justify-between">
                                <p className=" font-bold text-start text-white ">
                                  {i.storyTitle}
                                </p>
                                <CloseIcon onClick={() => setLess("")} />
                              </span>

                              <p className="flex text-white items-start justify-start text-ellipsis overflow-hidden  my-3">
                                {i.description}
                              </p>
                            </div>
                          ) : (
                            <div className="lg:flex w-full gap-3 my-5">
                              <div className="lg:w-[48%] justify-center items-center py-2   px-5 lg:flex sm:flex  flex-col gap-5 ">
                                <div className="relative mowgliImage md:justify-center md:items-center md:w-[50%] sm:w-[50%]">
                                  <img
                                    src={mowgliIMG?.[0]}
                                    alt={mowgliIMG?.[0]}
                                    tiltle={mowgliIMG?.[1]}
                                    className="object-contain rounded-full border border-green-500"
                                  />
                                </div>

                                <div className="flex flex-col md:px-0 px-3 rounded-lg  ">
                                  <div className="flex gap-3 justify-center items-center  my-1">
                                    <div className="relative">
                                      {arrayImage?.ShereKhan ? (
                                        <img
                                          src={ShereKhan?.[0]}
                                          alt=""
                                          // title={ShereKhan?.[1]}
                                          className={
                                            ShereKhan?.[1] === address
                                              ? "w-20 rounded-full border-2 border-green-500"
                                              : "w-20 rounded-full border-2 border-red-500"
                                          }
                                        />
                                      ) : (
                                        <>
                                          <img
                                            src={dat5?.[0]}
                                            alt=""
                                            className="w-20 object-contain blur"
                                          />
                                          <AddIcon
                                            onClick={() => {
                                              setNft(dat5?.[1]);
                                              setNft1(dat5?.[2]);
                                              setNft2(dat5?.[3]);
                                              handelopen2();
                                              setStoryId(i.id);
                                            }}
                                            className="text-black  absolute bottom-1 right-1 bg-white rounded-full"
                                          />
                                        </>
                                      )}
                                    </div>
                                    <div className="relative">
                                      {arrayImage?.Baloo ? (
                                        <img
                                          src={Baloo?.[0]}
                                          alt=""
                                          className={
                                            Baloo?.[1] === address
                                              ? "w-20 rounded-full border-2 border-green-500"
                                              : "w-20 rounded-full border-2 border-red-500"
                                          }
                                        />
                                      ) : (
                                        <>
                                          <img
                                            src={dat6?.[0]}
                                            alt=""
                                            className="w-20 object-contain blur"
                                          />
                                          <AddIcon
                                            onClick={() => {
                                              setNft(dat6?.[1]);
                                              setNft1(dat6?.[2]);
                                              setNft2(dat6?.[3]);
                                              handelopen2();
                                              setStoryId(i.id);
                                            }}
                                            className="text-black  absolute bottom-1 right-1 bg-white rounded-full"
                                          />
                                        </>
                                      )}
                                    </div>
                                  </div>
                                  <div className="flex gap-3 my-1">
                                    {/* <p className="text-xl text-white">{Kaa?.[1]}</p> */}
                                    <div className="relative">
                                      {arrayImage?.Kaa ? (
                                        <img
                                          src={Kaa?.[0]}
                                          alt=""
                                          className={
                                            Kaa?.[1] === address
                                              ? "lg:w-20 w-32  rounded-full border-2 border-green-500"
                                              : "lg:w-20 w-32 rounded-full border-2 border-red-500"
                                          }
                                        />
                                      ) : (
                                        <>
                                          <img
                                            src={dat2?.[0]}
                                            alt=""
                                            className="lg:w-44 w-32 object-contain blur"
                                          />
                                          <AddIcon
                                            onClick={() => {
                                              setNft(dat2?.[1]);
                                              setNft1(dat2?.[2]);
                                              setNft2(dat2?.[3]);
                                              handelopen2();
                                              setStoryId(i.id);
                                            }}
                                            className="text-black  absolute bottom-1 right-1 bg-white rounded-full"
                                          />
                                        </>
                                      )}
                                    </div>
                                    <div className="relative">
                                      {arrayImage?.Bhageera ? (
                                        <img
                                          src={Bhageera?.[0]}
                                          alt=""
                                          className={
                                            Bhageera?.[1] === address
                                              ? "lg:w-20 w-32 rounded-full border-2 border-green-500"
                                              : "lg:w-20 w-32 rounded-full border-2 border-red-500"
                                          }
                                        />
                                      ) : (
                                        <>
                                          <img
                                            src={dat3?.[0]}
                                            alt=""
                                            className="lg:w-44 w-32 object-contain blur"
                                          />
                                          <AddIcon
                                            onClick={() => {
                                              setNft(dat3?.[1]);
                                              setNft1(dat3?.[2]);
                                              setNft2(dat3?.[3]);
                                              handelopen2();
                                              setStoryId(i.id);
                                            }}
                                            className="text-black  absolute bottom-1 right-1 bg-white rounded-full"
                                          />
                                        </>
                                      )}
                                    </div>
                                    <div className="relative">
                                      {arrayImage?.Kinglouis ? (
                                        <img
                                          src={Kinglouis?.[0]}
                                          alt=""
                                          className={
                                            Kinglouis?.[1] === address
                                              ? "lg:w-20 w-32 rounded-full border-2 border-green-500"
                                              : "lg:w-20 w-32 rounded-full border-2 border-red-500"
                                          }
                                        />
                                      ) : (
                                        <>
                                          <img
                                            src={dat4?.[0]}
                                            alt=""
                                            className="lg:w-44 w-32 object-contain blur"
                                          />
                                          <AddIcon
                                            onClick={() => {
                                              setNft(dat4?.[1]);
                                              setNft1(dat4?.[2]);
                                              setNft2(dat4?.[3]);
                                              handelopen2();
                                              setStoryId(i.id);
                                            }}
                                            className="text-black  absolute bottom-1 right-1 bg-white rounded-full"
                                          />
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className=" ">
                                <span className="flex">
                                  <input
                                    // value={title1}
                                    defaultValue={i.storytitle}
                                    disabled={true}
                                    type="text"
                                    className="border-none outline-none py-2 italic font-bold text-white bg-transparent text-xl"
                                  />
                                  <EditIcon
                                    onClick={() => handelopen3(i.id)}
                                    className="!text-xl text-green-500"
                                  />
                                </span>

                                <textarea
                                  // value={dsc1}
                                  disabled={true}
                                  defaultValue={i?.description}
                                  className="text-white text-sm  w-full example bg-transparent my-3 italic  outline-none border-none h-[250px]"
                                  onChange={(e) => setDsc1(e.target.value)}
                                ></textarea>
                                {i.story_status === "progress" && (
                                  <div className="lg:flex justify-between items-end p-2 rounded-md ">
                                    <span className="lg:my-0 my-2 flex text-center px-8 text-sm border border-[#4b57c5] py-1 justify-center cursor-pointer hover:bg-[#4b57c5] hover:text-white hover:font-bold">
                                      <p
                                        onClick={() => {
                                          setStiryId(i.id);
                                          setPage("shakehand");
                                        }}
                                      >
                                        Go Handshaking with friends
                                      </p>

                                      <img
                                        src={hand}
                                        alt=""
                                        className="h-5 mt-1"
                                      />
                                    </span>
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      );
                    })}
                </div>

                <Dialog
                  open={open}
                  // onClose={handleClose}
                  PaperProps={{
                    className:
                      "!min-w-[50%] !lg:min-h-[70%] lg:p-1 p-1 flex gap-3 flex-col !rounded-2xl !text-shadow-none !bg-[#041125]  border border-[#5c2f9e] ",
                  }}
                >
                  <div>
                    <div className="flex items-end justify-end  p-5">
                      <CloseIcon
                        onClick={handleClose}
                        className="text-white text-4xl font-bold cursor-pointer"
                      />
                    </div>

                    <div className="lg:flex lg:p-10 p-2 gap-20">
                      {/* <Joyridecopy steps={steps1} /> */}
                      <div className="lg:w-[30%] flex flex-col">
                        <div className="flex flex-col justify-center items-center">
                          <div className="relative mowgliImage">
                            {mowgli ? (
                              <img
                                src={mowgli.img}
                                alt=""
                                className="object-contain rounded-full"
                              />
                            ) : (
                              <img
                                src={dat1?.[0]}
                                alt=""
                                className="object-contain blur"
                              />
                            )}

                            <AddIcon
                              onClick={() => {
                                setNft(dat1?.[1]);
                                setNft1(dat1?.[2]);
                                setNft2(dat1?.[3]);
                                handelopen1();
                              }}
                              id="mowgli"
                              className="text-black !text-5xl absolute bottom-5 right-5 bg-white rounded-full"
                            />
                          </div>

                          <p className="text-white capitalize my-3">
                            {dat1?.[1]}
                          </p>
                        </div>

                        <div className="flex flex-col justify-center items-center gap-5 mt-5 my-5">
                          <div className="flex justify-between gap-3">
                            <div className="flex flex-col justify-center items-center">
                              <Tooltip placement="left-start" title={dat2?.[1]}>
                                <div className="relative ">
                                  {kaa ? (
                                    <img
                                      src={kaa.img}
                                      alt=""
                                      className="object-contain  w-20 rounded-full"
                                    />
                                  ) : (
                                    <img
                                      src={dat2?.[0]}
                                      alt=""
                                      className="object-contain  w-20 blur"
                                    />
                                  )}

                                  <AddIcon
                                    onClick={() => {
                                      setNft(dat2?.[1]);
                                      setNft1(dat2?.[2]);
                                      setNft2(dat2?.[3]);
                                      handelopen1();
                                    }}
                                    className="text-black absolute bottom-0 right-0 bg-white rounded-full"
                                  />
                                </div>
                              </Tooltip>
                            </div>
                            <div className="flex flex-col justify-center items-center">
                              <Tooltip placement="top" title={dat3?.[1]}>
                                <div className="relative">
                                  {bhageera ? (
                                    <img
                                      src={bhageera.img}
                                      alt=""
                                      className="object-contain rounded-full w-20"
                                    />
                                  ) : (
                                    <img
                                      src={dat3?.[0]}
                                      alt=""
                                      className="object-contain blur w-20"
                                    />
                                  )}

                                  <AddIcon
                                    onClick={() => {
                                      setNft(dat3?.[1]);
                                      setNft1(dat3?.[2]);
                                      setNft2(dat3?.[3]);
                                      handelopen1();
                                    }}
                                    className="text-black absolute bottom-0 right-0 bg-white rounded-full"
                                  />
                                </div>
                              </Tooltip>
                            </div>
                          </div>
                          <div className="flex justify-between gap-3">
                            <div className="flex flex-col justify-center items-center">
                              <Tooltip
                                placement="right-start"
                                title={dat4?.[1]}
                              >
                                <div className="relative">
                                  {kinglouis ? (
                                    <img
                                      src={kinglouis.img}
                                      alt=""
                                      className="object-contain rounded-full w-20"
                                    />
                                  ) : (
                                    <img
                                      src={dat4?.[0]}
                                      alt=""
                                      className="object-contain blur w-20"
                                    />
                                  )}

                                  <AddIcon
                                    onClick={() => {
                                      setNft(dat4?.[1]);
                                      setNft1(dat4?.[2]);
                                      setNft2(dat4?.[3]);
                                      handelopen1();
                                    }}
                                    className="text-black absolute bottom-0 right-0 bg-white rounded-full"
                                  />
                                </div>
                              </Tooltip>
                            </div>
                            <div className="flex flex-col justify-center items-center">
                              <Tooltip placement="left" title={dat5?.[1]}>
                                <div className="relative">
                                  {sherekhan ? (
                                    <img
                                      src={sherekhan.img}
                                      alt=""
                                      className="object-contain rounded-full w-20"
                                    />
                                  ) : (
                                    <img
                                      src={dat5?.[0]}
                                      alt=""
                                      className="object-contain blur w-20"
                                    />
                                  )}

                                  <AddIcon
                                    onClick={() => {
                                      setNft(dat5?.[1]);
                                      setNft1(dat5?.[2]);
                                      setNft2(dat5?.[3]);
                                      handelopen1();
                                    }}
                                    className="text-black absolute bottom-0 right-0 bg-white rounded-full"
                                  />
                                </div>
                              </Tooltip>

                              <p className="text-white capitalize"></p>
                            </div>
                            <div className="flex flex-col justify-center items-center">
                              <Tooltip placement="right" title={dat6?.[1]}>
                                <div className="relative">
                                  {baloo ? (
                                    <img
                                      src={baloo.img}
                                      alt=""
                                      className="object-contain rounded-full w-20"
                                    />
                                  ) : (
                                    <img
                                      src={dat6?.[0]}
                                      alt=""
                                      className="object-contain blur w-20"
                                    />
                                  )}

                                  <AddIcon
                                    onClick={() => {
                                      setNft(dat6?.[1]);
                                      setNft1(dat6?.[2]);
                                      setNft2(dat6?.[3]);
                                      handelopen1();
                                    }}
                                    className="text-black absolute bottom-0 right-0 bg-white rounded-full"
                                  />
                                </div>
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="lg:w-[70%] ">
                        <input
                          value={tatile}
                          onChange={(e) => setTatile(e.target.value)}
                          type="text"
                          className="border-none outline-none py-2 text-xl font-bold text-white bg-transparent"
                        />
                        {/* <p className="text-white font-bold text-xl">My Story</p> */}
                        <textarea
                          value={dsc}
                          className="text-white text-sm  w-full  outline-none border-none bg-inherit h-[500px]"
                          onChange={(e) => setDsc(e.target.value)}
                          placeholder=" Once upon a time, in the heart of the lush and vibrant Indian
              jungle, there lived a young boy named Mowgli. Abandoned as a baby
              and taken in by a pack of wolves, Mowgli grew up under the care of
              Mother Wolf and her cubs. From them, he learned the ways of the
              jungle – how to run with the wind, climb trees with agility, and
              communicate with the animals through a language of whispers and
              gestures. As Mowgli grew older, he became friends with many of the
              jungle's inhabitants. There was Bagheera, the sleek and cunning
              black panther who watched over him like a guardian; Baloo, the
              wise and jovial bear who taught him the Laws of the Jungle; and
              Kaa, the ancient python who shared his wisdom in the serpentine
              coils of his hypnotic embrace. But not all creatures in the jungle
              were friendly. Shere Khan, the fierce and vengeful tiger, harbored
              a deep hatred for humans and sought to rid the jungle of Mowgli's
              presence. With his blazing eyes and razor-sharp claws, Shere Khan
              was a constant threat, lurking in the shadows and plotting his
              next move. Despite the dangers, Mowgli remained determined to
              prove himself worthy of his place in the jungle. With the help of
              his animal friends, he faced many challenges – from escaping the
              clutches of the cunning monkeys of the Bandar-log to outwitting
              the treacherous schemes of Shere Khan. Along the way, Mowgli
              learned valuable lessons about courage, loyalty, and the true
              meaning of family."
                        ></textarea>
                        {/* <p className="text-red-500 text-sm my-1">
                          {msg === "stakedDescription is required" &&
                            "Please write story"}
                        </p> */}
                        <div className="flex gap-5">
                          {loading2 ? (
                            <button
                              className="bg-white text-white  text-sm py-1 rounded-lg font-bold my-3 h-10 w-32"
                              // onClick={createSiweMessage}
                            >
                              <ButtonLoder />
                            </button>
                          ) : (
                            <button
                              onClick={AddStory}
                              className="  px-8 py-1 rounded-3xl bg-green-500 my-5 cursor-pointer hover:bg-green-300 hover:text-black font-bold  text-white"
                            >
                              SAVE
                            </button>
                          )}

                          <button
                            onClick={handleClose}
                            className="  px-8 py-1 rounded-3xl bg-green-500 my-5 cursor-pointer hover:bg-green-300 hover:text-black font-bold  text-white"
                          >
                            RESET
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog>
                <Dialog
                  open={open1}
                  onClose={handleClose1}
                  PaperProps={{
                    className:
                      "!lg:w-[40%] !lg:min:h-[50%] lg:p-1 p-1 flex gap-3 flex-col !rounded-2xl !text-shadow-none !bg-[#041125]   border border-[#5c2f9e] ",
                  }}
                >
                  <div className="flex flex-col w-full justify-between ">
                    <div className="flex w-full justify-between overflow-auto example">
                      <div className="p-5  ">
                        <div className="w-full flex justify-between border-b">
                          <p className="text-xl font-bold text-white ">
                            Nft's You Hold
                          </p>
                          {nft === "Mowgli" ? (
                            <Link
                              target="_blank"
                              to="https://opensea.io/collection/mowgliexclusive/overview"
                              className=" text-sm  text-white cursor-pointer  "
                            >
                              <sv className="text-blue-500 text-xl">Get</sv>{" "}
                              {nft} <ArrowForwardIcon />
                            </Link>
                          ) : (
                            <Link
                              target="_blank"
                              to="https://opensea.io/collection/the-mowgli-sanctuary/overview"
                              className=" text-sm  text-white cursor-pointer  "
                            >
                              <sv className="text-blue-500 text-xl">Get</sv>{" "}
                              {nft} <ArrowForwardIcon />
                            </Link>
                          )}
                        </div>
                        {loading1 ? (
                          <div></div>
                        ) : (
                          <div
                            onClick={handleClose1}
                            className="grid grid-cols-2 gap-5 my-5 "
                          >
                            {data?.length > 0 ? (
                              data?.map((i) => {
                                return (
                                  <div
                                    className={
                                      i.isStory === 1
                                        ? "relative  imageop !bg-black"
                                        : "relative"
                                    }
                                  >
                                    {i.isStory === 1 && (
                                      <Chip
                                        label={`Allot to #${i.storyTitle}`}
                                        className="flex absolute right-0 !rounded-none bottom-14 !w-full !font-bold !text-white !bg-[#0547CB]"
                                      />
                                    )}

                                    <img
                                      src={i.imgsrc}
                                      alt={i.title}
                                      className={
                                        i.isStory === 1
                                          ? "w-44  object-contain !bg-opacity-20 !bg-black"
                                          : "w-44 object-contain cursor-pointer hover:border hover:border-blue-500"
                                      }
                                      onClick={() =>
                                        i.isStory === 1
                                          ? ""
                                          : nft === "Mowgli"
                                          ? setmowgli({
                                              img: i.imgsrc,
                                              id: i.id,
                                            })
                                          : nft === "Bhageera"
                                          ? setbhageera({
                                              img: i.imgsrc,
                                              id: i.id,
                                            })
                                          : nft === "Kaa"
                                          ? setkaa({ img: i.imgsrc, id: i.id })
                                          : nft === "Kinglouis"
                                          ? setkinglouis({
                                              img: i.imgsrc,
                                              id: i.id,
                                            })
                                          : nft === "ShereKhan"
                                          ? setsherekhan({
                                              img: i.imgsrc,
                                              id: i.id,
                                            })
                                          : nft === "Baloo"
                                          ? setbaloo({
                                              img: i.imgsrc,
                                              id: i.id,
                                            })
                                          : ""
                                      }
                                    />
                                    <p className="text-white font-bold capitalize text-center">
                                      {i.title} #{i.identity_id}
                                    </p>
                                  </div>
                                );
                              })
                            ) : (
                              <div className="flex flex-col justify-center items-center">
                                <p className="text-white justify-center text-center">
                                  You have no Nfts........
                                </p>
                                <span className="flex gap-3 my-5">
                                  <VolunteerActivismIcon className="text-[#FF4EA1]" />
                                  <Link
                                    to="https://opensea.io/collection/the-mowgli-sanctuary/overview"
                                    className="flex items-center gap-1.5 text-lg font-bold text-white duration-200 hover:text-[#FF4EA1] xl:uppercase cursor-pointer "
                                  >
                                    OPENSEA
                                  </Link>
                                </span>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Dialog>
                <Dialog
                  open={open2}
                  onClose={handleClose2}
                  PaperProps={{
                    className:
                      "!lg:w-[40%] !lg:min:h-[50%] lg:p-1 p-1 flex gap-3 flex-col !rounded-2xl !text-shadow-none !bg-[#041125]   border border-[#5c2f9e] ",
                  }}
                >
                  <div className="flex flex-col w-full justify-between ">
                    <div className="flex w-full justify-between overflow-auto example">
                      <div className="p-5  ">
                        <p className="text-xl font-bold text-white my-5 border-b">
                          Nft's You Hold
                        </p>
                        <div className="grid grid-cols-2 gap-5 ">
                          {data?.length !== 0 ? (
                            data?.map((i) => {
                              return (
                                <div
                                  className={
                                    i.isStory === 1
                                      ? "relative  imageop !bg-black"
                                      : "relative"
                                  }
                                >
                                  {i.isStory === 1 && (
                                    <Chip
                                      label={`Allot to #${i.storyTitle}`}
                                      className="flex absolute right-0 !rounded-none bottom-14 !w-full !font-bold !text-white !bg-[#0547CB]"
                                    />
                                  )}

                                  <img
                                    src={i.imgsrc}
                                    alt={i.title}
                                    className={
                                      i.isStory === 1
                                        ? "w-44  object-contain !bg-opacity-20 !bg-black cursor-pointer"
                                        : "w-44 object-contain cursor-pointer"
                                    }
                                    onClick={() => reUpStory(i.id)}
                                  />
                                  <p className="text-white font-bold capitalize text-center">
                                    {i.title} #{i.identity_id}
                                  </p>
                                </div>
                              );
                            })
                          ) : (
                            <div className="flex flex-col justify-center items-center">
                              <p className="text-white justify-center text-center">
                                You have no Nfts........
                              </p>
                              <span className="flex gap-3 my-5">
                                <VolunteerActivismIcon className="text-[#FF4EA1]" />
                                <Link
                                  to="https://opensea.io/collection/the-mowgli-sanctuary/overview"
                                  className="flex items-center gap-1.5 text-lg font-bold text-white duration-200 hover:text-[#FF4EA1] xl:uppercase cursor-pointer "
                                >
                                  OPENSEA
                                </Link>
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog>
                <Dialog
                  open={open3}
                  onClose={handleClose3}
                  PaperProps={{
                    className:
                      "! !lg:min-h-[70%] lg:p-1 p-1 flex gap-3 flex-col !rounded-2xl !text-shadow-none !bg-[#041125]  border border-[#5c2f9e] ",
                  }}
                >
                  <div>
                    <div className="flex items-end justify-end  p-5">
                      <CloseIcon
                        onClick={handleClose3}
                        className="text-white text-4xl font-bold cursor-pointer"
                      />
                    </div>

                    <div className="lg:flex lg:p-10 p-2 gap-20">
                      <div className="lg:w-[30%] flex flex-col">
                        <div className="flex flex-col justify-center items-center">
                          <div className="relative mowgliImage">
                            <img
                              src={mowgliIMG?.[0]}
                              alt=""
                              className="object-contain rounded-full"
                            />
                          </div>
                        </div>

                        <div className="flex flex-col justify-center items-center gap-5 mt-5 my-5">
                          <div className="flex justify-between gap-3">
                            <div className="flex flex-col justify-center items-center">
                              <Tooltip placement="right">
                                <div className="relative">
                                  <img
                                    src={ShereKhan?.[0]}
                                    alt=""
                                    className="object-contain rounded-full w-20"
                                  />
                                </div>
                              </Tooltip>

                              <p className="text-white capitalize"></p>
                            </div>
                            <div className="flex flex-col justify-center items-center">
                              <Tooltip placement="right">
                                <div className="relative">
                                  <img
                                    src={Baloo?.[0]}
                                    alt=""
                                    className="object-contain rounded-full w-20"
                                  />
                                </div>
                              </Tooltip>
                            </div>
                          </div>
                          <div className="flex justify-between gap-3">
                            <div className="flex flex-col justify-center items-center">
                              <Tooltip placement="right">
                                <div className="relative">
                                  <img
                                    src={Kaa?.[0]}
                                    alt=""
                                    className="object-contain rounded-full w-20"
                                  />
                                </div>
                              </Tooltip>
                            </div>
                            <div className="flex flex-col justify-center items-center">
                              <Tooltip placement="right">
                                <div className="relative">
                                  <img
                                    src={Bhageera?.[0]}
                                    alt=""
                                    className="object-contain rounded-full w-20"
                                  />
                                </div>
                              </Tooltip>
                            </div>
                            <div className="flex flex-col justify-center items-center">
                              <Tooltip placement="right">
                                <div className="relative">
                                  <img
                                    src={Kinglouis?.[0]}
                                    alt=""
                                    className="object-contain rounded-full w-20"
                                  />
                                </div>
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="lg:w-[70%] ">
                        <input
                          // value={title1}
                          defaultValue={editdata?.[0]?.storytitle}
                          onChange={(e) => setTatile1(e.target.value)}
                          type="text"
                          className="border-none outline-none py-2 text-xl font-bold text-white bg-transparent"
                        />
                        {/* <p className="text-white font-bold text-xl">My Story</p> */}
                        <textarea
                          // value={dsc1}
                          defaultValue={editdata?.[0]?.description}
                          className="text-white text-sm  w-full  outline-none border-none bg-inherit "
                          onChange={(e) => setDsc1(e.target.value)}
                          placeholder=" Once upon a time, in the heart of the lush and vibrant Indian
              jungle, there lived a young boy named Mowgli. Abandoned as a baby
              and taken in by a pack of wolves, Mowgli grew up under the care of
              Mother Wolf and her cubs. From them, he learned the ways of the
              jungle – how to run with the wind, climb trees with agility, and
              communicate with the animals through a language of whispers and
              gestures. As Mowgli grew older, he became friends with many of the
              jungle's inhabitants. There was Bagheera, the sleek and cunning
              black panther who watched over him like a guardian; Baloo, the
              wise and jovial bear who taught him the Laws of the Jungle; and
              Kaa, the ancient python who shared his wisdom in the serpentine
              coils of his hypnotic embrace. But not all creatures in the jungle
              were friendly. Shere Khan, the fierce and vengeful tiger, harbored
              a deep hatred for humans and sought to rid the jungle of Mowgli's
              presence. With his blazing eyes and razor-sharp claws, Shere Khan
              was a constant threat, lurking in the shadows and plotting his
              next move. Despite the dangers, Mowgli remained determined to
              prove himself worthy of his place in the jungle. With the help of
              his animal friends, he faced many challenges – from escaping the
              clutches of the cunning monkeys of the Bandar-log to outwitting
              the treacherous schemes of Shere Khan. Along the way, Mowgli
              learned valuable lessons about courage, loyalty, and the true
              meaning of family."
                        ></textarea>
                        {/* <p className="text-gray-500 text-sm my-1"></p> */}
                        <div className="flex gap-5 items-baseline">
                          <button
                            onClick={EditStory}
                            className="  px-8 py-1 rounded-3xl bg-green-500 my-5 cursor-pointer hover:bg-green-300 hover:text-black font-bold  text-white"
                          >
                            SAVE
                          </button>
                          <button
                            onClick={handelopen4}
                            className="  px-8 py-1 rounded-3xl bg-green-500 my-5 cursor-pointer hover:bg-green-300 hover:text-black font-bold  text-white"
                          >
                            Disband
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog>

                <Dialog
                  open={open4}
                  onClose={handleClose4}
                  PaperProps={{
                    className:
                      "lg:w-[30%] !lg:h-[50%] lg:p-1 p-1 flex gap-3 flex-col !rounded-2xl !text-shadow-none !bg-[#041125]  border border-[#5c2f9e] ",
                  }}
                >
                  <div className="flex flex-col justify-center items-center py-5">
                    <p className="text-white">
                      Are you sure disband your story #
                      {editdata?.[0]?.storyTitle}
                    </p>
                    <p></p>
                    <div className="flex my-20 gap-10 font-bold">
                      <button
                        onClick={TerStory}
                        className="py-1 px-10 bg-green-500 hover:bg-green-300 text-white rounded-xl"
                      >
                        Yes
                      </button>
                      <button
                        onClick={handleClose4}
                        className="py-1 px-10 bg-red-500 hover:bg-red-300 text-white rounded-xl"
                      >
                        No
                      </button>
                    </div>
                  </div>
                </Dialog>
                <Dialog
                  open={open5}
                  onClose={handleClose5}
                  PaperProps={{
                    className:
                      "w-[30%] !lg:h-[50%] lg:p-1 p-1 flex gap-3 flex-col !rounded-2xl !text-shadow-none !bg-[#041125]  border border-[#5c2f9e] ",
                  }}
                >
                  <div className="flex w-full flex-col my-20 gap-10 font-bold px-20">
                    <button
                      onClick={TerStory}
                      className="bg-green-500 hover:bg-green-300 text-white rounded-xl w-ful py-1"
                    >
                      Personal hand shaking
                    </button>
                    <button
                      onClick={TerStory}
                      className="bg-green-500 hover:bg-green-300 text-white rounded-xl w-full py-1"
                    >
                      Public hand shaking
                    </button>
                  </div>
                </Dialog>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
