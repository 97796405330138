import { Drawer } from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import CreateButoon from "../../Alldilog/ConnectButton";
import logo from "../../Images/mowgli-sanctuary.png";
import stak from "../../Images/stake.png";
import stak1 from "../../Images/instagram-stories.png";
import stak2 from "../../Images/compass.png";
import stak3 from "../../Images/jigsaw.png";
import stak4 from "../../Images/dashboard.png";
import MenuIcon from "@mui/icons-material/Menu";

export default function PhoneMenu() {
  const [open1, setOpen1] = useState(false);

  const handleDrawerOpen = () => {
    setOpen1(true);
  };
  const handleDrawerClose = () => {
    setOpen1(false);
  };
  return (
    <div>
      <div className="flex justify-between items-center w-full lg:hidden ">
        <img
          onClick={() => ""}
          src={logo}
          alt=""
          className=" h-20 cursor-pointer "
        />
        <div className="w-10 relative h-10 bg-white rounded-full flex justify-center items-center border border-gray-500 mr-5">
          <MenuIcon
            onClick={handleDrawerOpen}
            className="!text-3xl font-bold text-black animate-pulse"
          />
        </div>
      </div>
      <Drawer
        PaperProps={{
          className:
            "!w-[70%]  !bg-gradient-to-t from-[#7b50d6] from-8% via-[#8263c5] via-20% to-[#7b50d6] to-60%  !p-2 ",
        }}
        anchor="right"
        open={open1}
        onClose={handleDrawerClose}
      >
        <div className="flex flex-col bg-opacity-20 bg-black h-full text-white rounded-md p-2   ">
          <div className="relative">
            <div className="flex items-center justify-center border-b border-gray-500 py-2 ">
              <img
                // onClick={() => {
                //   navigate("/");
                //   handleDrawerClose();
                // }}
                src={logo}
                alt=""
                className=" h-20 cursor-pointer "
              />
            </div>
            <Link
              to="/dashboard"
              className="px-5 gap-5 flex cursor-pointer  my-4"
            >
              <img src={stak4} className="h-7 w-7" alt="stak" />
              <p className="font-bold  ">Dashbord</p>
            </Link>
            <Link
              to="/staking"
              className="px-5 gap-5 flex cursor-pointer  my-4"
            >
              <img src={stak} className="h-7 w-7" alt="stak" />
              <p className="font-bold ">Staking</p>
            </Link>

            <Link to="/storay" className="px-5 gap-5 flex cursor-pointer  my-4">
              <img src={stak1} className="h-7 w-7" alt="stak" />
              <p className="font-bold ">Story</p>
            </Link>
            <Link
              onClick={() => ""}
              className="px-5 gap-5 flex cursor-pointer  my-4"
            >
              <img src={stak2} className="h-7 w-7" alt="stak" />
              <p className="font-bold  ">Event's</p>
            </Link>

            <Link
              onClick={() => ""}
              className="px-5 gap-5 flex cursor-pointer  my-4"
            >
              <img src={stak3} className="h-7 w-7" alt="stak" />
              <p className="font-bold  ">Explore</p>
            </Link>
          </div>
          <div
            // onClick={() => navigate("/")}
            className=" absolute inset-x-5 bottom-5  bg-opacity-20 justify-between bg-black  hover:text-black cursor-pointer hover:border-b py-2 lg:border border-[#078043] rounded-xl hover:bg-blue-200 "
          >
            <CreateButoon />
          </div>
        </div>
      </Drawer>
    </div>
  );
}
