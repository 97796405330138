import * as React from "react";

import "./loder.css";

export const Loder = () => (
  <div class="loader-mask">
    <div class="load05"></div>
    <div class="loader">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>
);
