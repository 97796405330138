import { Dialog, Divider } from "@mui/material";
import React from "react";
import mt from "../../Images/download.svg";
import m from "../../Images/download2.svg";
import CloseIcon from "@mui/icons-material/Close";

export default function Login({ setOpen, open }) {
  return (
    <div>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{
          className:
            "!min-w-[40%] !min-h-[50%] p-5 flex gap-3 flex-col !rounded-2xl !text-shadow-none !bg-white",
        }}
      >
        <div className="flex">
          <div className="w-[40%] border-r-2 px-5 ">
            <p className="text-xl font-bold my-2">Connect a Wallet</p>
            <p className="font-bold text-sm text-gray-400">Popular</p>
            <div className="flex gap-3 my-3 hover:bg-gray-300 px-2 py-1 rounded-xl cursor-pointer">
              <img src={mt} alt="" className="rounded-full" />
              <p className="font-bold ">MataMask</p>
            </div>
            <div className="flex gap-3 my-3 hover:bg-gray-300 px-2 py-1 rounded-xl cursor-pointer ">
              <img src={mt} alt="" className="rounded-full" />
              <p className="font-bold ">MataMask</p>
            </div>

            <div className="flex gap-3 my-3 hover:bg-gray-300 px-2 py-1 rounded-xl cursor-pointer ">
              <img src={mt} alt="" className="rounded-full" />
              <p className="font-bold ">MataMask</p>
            </div>
            <div className="flex gap-3 my-3 hover:bg-gray-300 px-2 py-1 rounded-xl cursor-pointer ">
              <img src={mt} alt="" className="rounded-full" />
              <p className="font-bold ">MataMask</p>
            </div>
            <div className="flex gap-3 my-3 hover:bg-gray-300 px-2 py-1 rounded-xl cursor-pointer ">
              <img src={mt} alt="" className="rounded-full" />
              <p className="font-bold ">MataMask</p>
            </div>

            <div className="flex gap-3 my-3 hover:bg-gray-300 px-2 py-1 rounded-xl cursor-pointer ">
              <img src={mt} alt="" className="rounded-full" />
              <p className="font-bold ">MataMask</p>
            </div>
            <div className="flex gap-3 my-3 hover:bg-gray-300 px-2 py-1 rounded-xl cursor-pointer ">
              <img src={mt} alt="" className="rounded-full" />
              <p className="font-bold ">MataMask</p>
            </div>
            <div className="flex gap-3 my-3 hover:bg-gray-300 px-2 py-1 rounded-xl cursor-pointer ">
              <img src={mt} alt="" className="rounded-full" />
              <p className="font-bold ">MataMask</p>
            </div>
          </div>

          <div className="w-[60%] flex flex-col justify-center items-center">
            <div
              onClick={() => setOpen(false)}
              className=" absolute top-3 right-3 border p-1 hover:bg-gray-200 cursor-pointer rounded-full "
            >
              <CloseIcon />
            </div>

            <p className="text-xl font-bold ">What is a Wallet?</p>
            <div className="flex w-96 items-center gap-3 mt-32">
              <img src={m} alt="" className="h-14 w-14" />
              <span className="flex flex-col">
                <p className="font-bold">A Home for your Digital Assets</p>
                <p className="font-bold text-sm text-gray-400">
                  Wallets are used to send, receive, store, and display digital
                  assets like Ethereum and NFTs.
                </p>
              </span>
            </div>
            <div className="flex w-96 items-center gap-3 mt-5">
              <img src={m} alt="" className="h-14 w-14" />
              <span className="flex flex-col">
                <p className="font-bold">A Home for your Digital Assets</p>
                <p className="font-bold text-sm text-gray-400">
                  Wallets are used to send, receive, store, and display digital
                  assets like Ethereum and NFTs.
                </p>
              </span>
            </div>
            <div className="flex flex-col justify-center items-center mt-20">
              <p className="bg-blue-500 rounded-2xl text-white font-bold px-3">
                Get a Wallet
              </p>
              <p className="text-blue-700 font-bold my-2">Learn More</p>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
