import React, { useEffect, useState } from "react";
import axiosInstance1 from "../../Config1/axios";
import { API_URLS1 } from "../../Config1/apiUrls";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { Dialog } from "@mui/material";
import { toast } from "react-toastify";
import { Navigate } from "react-router-dom";
import { useAccount } from "wagmi";

export default function Notification() {
  const [data, setData] = useState();
  const { address, isDisconnected } = useAccount();
  const [open2, setOpen2] = React.useState(false);

  const handleClose2 = () => {
    setOpen2(false);
  };
  const handelopen2 = (i) => {
    setOpen2(true);
  };

  const reUpStory = () => {
    axiosInstance1
      .post(API_URLS1.handshakeRequestList, {})
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
        err?.response?.data?.Response_code === 401 && back();
      });
  };

  const RequestReject = (id) => {
    axiosInstance1
      .post(API_URLS1.RejectByStorymake, { requestid: id })
      .then((res) => {
        toast.success(res.data.msg);
        handleClose2();
      })
      .catch((err) => {
        console.log(err);
        err?.response?.data?.Response_code === 401 && back();
      });
  };
  const ConfirmByStorymake = (id) => {
    axiosInstance1
      .post(API_URLS1.RequestConfirm, { requestid: id })
      .then((res) => {
        toast.success(res.data.msg);
        handleClose2();
      })
      .catch((err) => {
        console.log(err);
        err?.response?.data?.Response_code === 401 && back();
      });
  };
  const address1 = localStorage.getItem("address");
  const back = () => {
    Navigate("/");
  };
  useEffect(() => {
    address !== address1 && back();
  }, [address]);

  useEffect(() => {
    reUpStory();
  }, [open2]);
  return (
    <>
      <div className=" items-center pb-5 px-10">
        <span
          onClick={handelopen2}
          className="flex relative bg-black h-16 w-16 gap-2 rounded-full cursor-pointer items-center float-right justify-center"
        >
          {data?.data?.length !== 0 && (
            <span className="bg-blue-700 h-5 w-5 rounded-full justify-center items-center shadow-lg">
              <p
                style={{ boxShadow: "0 0 60px white" }}
                className="font-bold text-center text-sm  text-black "
              >
                {data?.data?.length}
              </p>
            </span>
          )}

          <span class="relative flex h-7 w-7">
            <NotificationsActiveIcon class="animate-ping absolute inline-flex h-full w-full rounded-full bg-blue-400 opacity-75"></NotificationsActiveIcon>
            <NotificationsActiveIcon class="relative inline-flex rounded-full h-7 w-7 bg-blue-500"></NotificationsActiveIcon>
          </span>
        </span>
      </div>
      <Dialog
        open={open2}
        onClose={handleClose2}
        PaperProps={{
          className:
            "lg:w-[40%] !lg:h-[50%] lg:p-1 p-1 flex gap-3 flex-col !rounded-2xl !text-shadow-none !bg-[#041125]  border border-[#5c2f9e] ",
        }}
      >
        <div className="flex w-full flex-col my-5 gap-10 font-bold px-5">
          <p className="text-white text-xl">Notifications</p>
          {data?.data?.length !== 0 ? (
            data?.data?.map((i) => {
              return (
                <>
                  <div className="flex  border p-2 rounded-lg bg-gray-950 px-5 justify-between w-[100%]">
                    <p className="text-white ">#{i.toStoryorderid}</p>
                    <p className="text-white ">{i.storyTitle}</p>
                    <span className="flex gap-3">
                      <p
                        onClick={() => ConfirmByStorymake(i.id)}
                        className="px-3 py-1 rounded-lg bg-green-400 cursor-pointer text-sm"
                      >
                        Accept
                      </p>
                      <p
                        onClick={() => RequestReject(i.id)}
                        className="px-3 py-1 rounded-lg bg-red-400 cursor-pointer text-sm"
                      >
                        Reject
                      </p>{" "}
                    </span>
                  </div>
                </>
              );
            })
          ) : (
            <p className="text-white text-center">
              Currently you have no any notifications...!
            </p>
          )}
        </div>
      </Dialog>
    </>
  );
}
