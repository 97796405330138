import { Avatar, Chip, Dialog, Slide } from "@mui/material";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import React, { useEffect, useState } from "react";
import trophy from "../../Images/trophy.png";
import axios from "axios";
import { useAccount } from "wagmi";
import axiosInstance1 from "../../Config1/axios";
import { API_URLS1 } from "../../Config1/apiUrls";
import { toast } from "react-toastify";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function Trophy() {
  const [open, setOpen] = React.useState(false);
  const [activity, setActivity] = useState();
  const [amout, setAmout] = useState("");
  console.log(process.env.REACT_APP_key, "hello");
  const { address } = useAccount();
  const back = () => {
    toast.warn("Your Session have expired");
    localStorage.clear();
  };
  const getActivity = () => {
    axiosInstance1
      .post(API_URLS1.getdailycheckin)
      .then((res) => {
        setActivity(res.data.data);
      })
      .catch((err) => {
        err?.response?.data?.Response_code === 401 && back();
      });
  };

  const stakActivity = (id) => {
    axiosInstance1
      .post(API_URLS1.updateclaim, { claimId: id })
      .then((res) => {
        toast.success(res.data.msg);
        getActivity();
        handleClose();
      })
      .catch((err) => {
        err?.response?.data?.Response_code === 401 && back();
      });
  };

  useEffect(() => {
    open && getActivity();
  }, [open]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {address && !open && (
        <div className="!fixed lg:!block !hidden py-1 !z-50 top-[20%] right-0  ">
          <Chip
            avatar={<Avatar alt="Natacha" src={trophy} />}
            label="Check"
            variant="outlined"
            className="!cursor-pointer !bg-white !w-28 !py-1 !font-bold !animate-bounce"
            onClick={handleClickOpen}
          />
        </div>
      )}

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{
          className:
            "!min-w-[50%] !min-h-[35%] p-10 flex gap-3 flex-col !rounded-2xl !text-shadow-none !bg-black",
        }}
      >
        <div className="flex flex-col">
          <div className="flex justify-between ">
            <p className="text-4xl font-bold text-white">Daily check in</p>
            <span className="flex justify-between w-[20%] text-white underline">
              <p className="font-bold text-xl">Total point</p>
              <p className="font-bold text-xl text-green-500">
                {activity?.[0]?.TotalClaimPoint}
              </p>
            </span>
          </div>
          <p className="text-xl text-white">
            Continue check-in for 7 days will earn surprise!
          </p>
          <div className="grid gap-3 w-full grid-cols-7  text-white my-5  py-3 ">
            {activity?.map((i) => {
              return (
                <div
                  className={
                    i.status === 0
                      ? "relative h-32 flex flex-col justify-between items-center border  rounded-xl"
                      : "relative h-32 flex flex-col bg-yellow-500 justify-between items-center border  rounded-xl"
                  }
                >
                  <span className="absolute left-7 -top-4  rounded-full bg-yellow-500 text-white !shadow-2xl p-1 px-5">
                    {i.point}
                  </span>
                  <div className="flex justify-center items-center my-10">
                    <p>{i.day}</p>
                  </div>
                  {i.status === 0 ? (
                    i.claimopen === "true" ? (
                      <p
                        className={
                          i.claimopen === "true"
                            ? "absolute left-5  font-bold bg-yellow-500 text-yellow-500-500 bottom-2 border px-2 rounded-md hover:bg-gray-300 cursor-pointer hover:text-black"
                            : "absolute left-5  font-bold text-gray-500 bottom-2 border px-2 rounded-md hover:bg-gray-300 cursor-pointer hover:text-black"
                        }
                        onClick={() => stakActivity(i.id)}
                      >
                        Claim
                      </p>
                    ) : (
                      <p
                        className={
                          i.claimopen === "true"
                            ? "absolute left-0  font-bold bg-yellow-500 text-yellow-500-500 bottom-2 border px-2 rounded-md hover:bg-gray-300 cursor-pointer hover:text-black"
                            : "absolute left-0  font-bold text-gray-500 bottom-2  px-1 rounded-md  cursor-pointer "
                        }
                      >
                        Upcomming
                      </p>
                    )
                  ) : (
                    <p
                      className={
                        i.claimopen === "true"
                          ? "absolute left-2  font-bold bg-yellow-500 text-yellow-500-500 bottom-2 border px-2 rounded-md hover:bg-gray-300 cursor-pointer hover:text-black"
                          : "absolute left-2  font-bold text-gray-500 bottom-2 border px-2 rounded-md hover:bg-gray-300 cursor-pointer hover:text-black"
                      }
                      // onClick={() => stakActivity(i.id)}
                    >
                      Claimed
                    </p>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </Dialog>
    </div>
  );
}
