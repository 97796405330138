import React from "react";
import "./Progress.css";

export default function Progress() {
  return (
    <>
      <div className="skill flex justify-center items-center">
        <div className="outer">
          <div className="inner flex justify-center items-center">
            <div id="number">
              <p className="font-bold">65%</p>
            </div>
          </div>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          width="160px"
          height="160px"
          className="svg"
        >
          <defs>
            <linearGradient id="GradientColor">
              <stop offset="0%" stop-color="#e91e63" />
              <stop offset="100%" stop-color="#673ab7" />
            </linearGradient>
          </defs>
          <circle
            className="circle"
            cx="80"
            cy="80"
            r="70"
            stroke-linecap="round"
          />
        </svg>
      </div>
    </>
  );
}

// import * as React from "react";
// import { styled } from "@mui/material/styles";
// import Box from "@mui/material/Box";
// import LinearProgress, {
//   linearProgressClasses,
// } from "@mui/material/LinearProgress";

// const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
//   height: 40,
//   borderRadius: "0 10 0 10",
//   [`&.${linearProgressClasses.colorPrimary}`]: {
//     backgroundColor:
//       theme.palette.grey[theme.palette.mode === "light" ? 200 : 500],
//   },
//   [`& .${linearProgressClasses.bar}`]: {
//     borderRadius: 5,
//     backgroundColor: theme.palette.mode === "light" ? "#9AD100" : "#9AD100",
//   },
// }));

// export default function Progress() {
//   return (
//     <Box sx={{ width: "50%", transform: "rotate(-45deg)" }}>
//       <BorderLinearProgress variant="determinate" value={50} />

//       <div className="flex justify-between">
//         <span className="flex gap-2">
//           <p>Min</p>
//           <p className="text-black font-bold">55%</p>
//         </span>
//         <span className="flex gap-2">
//           <p>Max</p>
//           <p className="text-black font-bold">100%</p>
//         </span>
//       </div>
//     </Box>
//   );
// }
