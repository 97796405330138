import React, { useEffect, useState } from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { Loder } from "../../Alldilog/Loder";

export default function Faqs() {
  const [isLoding, setIsLoding] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoding(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);
  return (
    <>
      {isLoding ? (
        <div className="flex flex-col justify-center items-center lg:py-56 py-20 bg-gradient-to-t from-[#599f00] to-[#a9dc01] h-screen ">
          <p className="lg:text-6xl font-bold text-xl text-pink-500">
            <Loder />
          </p>
        </div>
      ) : (
        <>
          <Header />
          <div className="flex flex-col items-center justify-center py-28 bg-gradient-to-t from-[#599f00] to-[#a9dc01] ">
            <p className="text-5xl font-bold text-white underline">FAQ'S</p>

            <div className="flex flex-col w-[900px] my-20">
              <span className="flex flex-col my-5">
                <p className="text-3xl font-bold text-black">
                  What is the Mowgli Sanctuary?
                </p>
                <p className="text-white font-bold my-3">
                  The Mowgli Sanctuary is a virtual world and conservation
                  initiative inspired by the spirit of Mowgli from "The Jungle
                  Book." It is a digital realm where art, nature, and technology
                  converge to raise awareness about wildlife conservation and
                  habitat preservation.
                </p>
              </span>
              <span className="flex flex-col my-5">
                <p className="text-3xl font-bold text-black">
                  Why should I buy a Mowgli Sanctuary NFT?
                </p>
                <p className="text-white font-bold my-3">
                  Buying a Mowgli Sanctuary NFT not only grants you ownership of
                  a unique digital collectible but also makes you a part of a
                  community dedicated to protecting wildlife and their habitats.
                  Additionally, holding a Mowgli Sanctuary NFT can have tangible
                  benefits, such as receiving exclusive rewards and
                  participating in presale token offerings.
                </p>
              </span>
              <span className="flex flex-col my-5">
                <p className="text-3xl font-bold text-black">
                  What benefits do I get from holding a Mowgli Sanctuary NFT?
                </p>
                <p className="text-white font-bold my-3">
                  By holding a Mowgli Sanctuary NFT, you become eligible to
                  participate in presale token offerings based on the rarity and
                  type of NFT you hold. This means you could have the
                  opportunity to access new tokens before they are available to
                  the general public, based on the rarity of your NFT.
                </p>
              </span>
              <span className="flex flex-col my-5">
                <p className="text-3xl font-bold text-black">
                  How do I participate in presale token offerings?
                </p>
                <p className="text-white font-bold my-3">
                  Holders of Mowgli Sanctuary NFTs will receive information
                  about upcoming presale token offerings based on the rarity and
                  type of NFT they hold. This information will include details
                  on how to participate in the presale events and access
                  exclusive token offerings.
                </p>
              </span>
              <span className="flex flex-col my-5">
                <p className="text-3xl font-bold text-black">
                  Can you explain the rarity and types of NFTs that can
                  participate in presale token offerings?
                </p>
                <p className="text-white font-bold my-3">
                  Mowgli Sanctuary NFTs come in various rarities, including
                  rare, legendary, special, and early minting NFTs. The rarity
                  of your NFT will determine your eligibility to participate in
                  presale token offerings. Holders of rarer NFTs will have a
                  higher chance of accessing exclusive token offerings.
                </p>
              </span>
            </div>
          </div>
          <Footer />
        </>
      )}
    </>
  );
}
