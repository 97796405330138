import React from "react";
import CreateButoonon from "../../Alldilog/ConnectButton";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../Images/mowgli-sanctuary.png";
import stak from "../../Images/stake.png";
import stak1 from "../../Images/instagram-stories.png";
import stak2 from "../../Images/compass.png";
import stak3 from "../../Images/jigsaw.png";
import stak4 from "../../Images/dashboard.png";
import MenuIcon from "@mui/icons-material/Menu";
import PhoneMenu from "../PhoneMenu";

export default function Menu() {
  const navigate = useNavigate();
  return (
    <>
      <div className=" lg:w-[15%] relativ justify-between w-full flex bg-gradient-to-t from-[#7b50d6] from-8% via-[#8263c5] via-20% to-[#7b50d6] to-60% flex-col  py-5 text-white rounded-xl lg:my-5 lg:h-[90vh] px-2 ">
        <div className=" lg:block hidden ">
          <div className="flex items-center justify-center border-b border-gray-500 py-2 ">
            <img
              onClick={() => navigate("/")}
              src={logo}
              alt=""
              className=" h-20 cursor-pointer lg:block hidden"
            />
          </div>
          <div className="flex justify-between lg:flex-col lg:my-0 my-5  ">
            <Link
              to="/dashboard"
              className="px-5 gap-5 flex cursor-pointer  lg:my-4"
            >
              <img src={stak4} className="h-7 w-7" alt="stak" />
              <p className="font-bold  ">Dashbord</p>
            </Link>
            <Link
              to="/staking"
              className="px-5 gap-5 flex cursor-pointer  lg:my-4"
            >
              <img src={stak} className="h-7 w-7" alt="stak" />
              <p className="font-bold ">Staking</p>
            </Link>
          </div>
          <div className="flex justify-between lg:flex-col lg:my-0 my-5 ">
            <Link
              to="/storay"
              className="px-5 gap-5 flex cursor-pointer  lg:my-4"
            >
              <img src={stak1} className="h-7 w-7" alt="stak" />
              <p className="font-bold ">Story</p>
            </Link>
            <Link
              onClick={() => ""}
              className="px-5 gap-5 flex cursor-pointer  lg:my-4"
            >
              <img src={stak2} className="h-7 w-7" alt="stak" />
              <p className="font-bold  ">Event's</p>
            </Link>
          </div>
          <div className="flex justify-between lg:flex-col lg:my-0 my-5">
            <Link
              onClick={() => ""}
              className="px-5 gap-5 flex cursor-pointer  lg:my-4"
            >
              <img src={stak3} className="h-7 w-7" alt="stak" />
              <p className="font-bold  ">Explore</p>
            </Link>
            <span
              // onClick={() => setPage("/dashboard")}
              className="px-5 gap-5 flex cursor-pointer lg:hidden border-[#078043] py-1 rounded-md  lg:my-4"
            >
              <CreateButoonon />
              <p className="font-bold lg:hidden ">Go Home</p>
            </span>
          </div>
        </div>
        <PhoneMenu />

        <div
          onClick={() => navigate("/")}
          className="px-5  lg:block hidden lg:bg-opacity-20 justify-between lg:bg-black  hover:text-black cursor-pointer hover:border-b py-2 lg:border lg:border-[#078043] rounded-xl lg:hover:bg-blue-200 "
        >
          <CreateButoonon />
        </div>
      </div>
    </>
  );
}
