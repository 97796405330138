import React from "react";
import img1 from "../../Images/mowgli-NFT-bg.jpg";
import img2 from "../../Images/presale-closed.webp";
import img3 from "../../Images/winged.webp";
import axios from "axios";

export default function Map() {
  const token = process.env.REACT_APP_TOKEN;
  // console.log(token, "token");
  const CheckApi = () => {
    axios
      .post(
        "http://192.168.29.86:6001/company/v5/get-Verify-Signature",
        {
          status: true,
          amount: 500,
          addressTo: 0x5b38da6a701c568545dcfcb03fcb875f56beddc4,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log(response.data, "response");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div>
      <img
        src={img1}
        alt=""
        // data-aos="zoom-out-down"
        // data-aos-delay="50"
        // data-aos-duration="1000"
        // data-aos-easing="ease-in-out-cubic"
        className="w-full"
      />
      <section className="skewed relative z-[1] flex min-h-[300px] w-full items-center justify-center overflow-hidden px-5 sm:px-12 md:px-20">
        <div className="layer bottom bg-strawberry"></div>
        <div className="layer top "></div>
        <section className="z-[3] flex flex-col items-center justify-center py-20">
          <div className="grid w-full max-w-desktop grid-cols-1 items-center justify-center gap-12 lg:min-[1099px]:grid-cols-2">
            <div
              data-aos="fade-right"
              data-aos-delay="50"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out-cubic"
              className="flex flex-col text-white"
            >
              <h4 className="mb-2 font-bold uppercase">
                BE A PART OF OUR GROWTH…
              </h4>
              <h1 className="mb-6 font-arco text-5xl leading-10 md:text-[68px] md:leading-[68px] min-[1099px]:max-w-2xl 2xl:text-[100px] 2xl:leading-[100px] ">
                MOWGLI TOKEN!
              </h1>
              <div className="w-fit">
                <button className="button inline-flex items-center justify-center rounded-[25px] uppercase text-lg relative gap-2.5 font-gotham font-bold sky-button text-white px-9 py-[18px] h-[50px] z-10 shadow-darkShadow xl:w-full">
                  Get Access Now!
                </button>
              </div>
            </div>
            <div className="flex flex-col">
              {/* <div style={{ transform: "scale(1.24) translateZ(0px)" }}>
                <img
                  src={img2}
                  alt=""
                  className="mt-12 h-auto min-[1099px]:max-w-[600px]"
                />
              </div> */}
              <p className="lg:text-8xl text-2xl text-green-500">
                COMING SOON...
              </p>
            </div>
          </div>
        </section>
      </section>
      <div className="flex px-10  justify-between">
        <div className="flex text-white lg:text-black py-5 flex-col">
          <p>
            {" "}
            <b>IMPORTANT</b>: Mowgli Sanctuary does not provide investment
            advice or make any investment recommendations.
          </p>
          <p>
            Investing in any financial product or venture carries inherent
            risks. You should only invest funds that you are prepared to lose
            entirely. The investment opportunities presented by Mowgli Sanctuary
            are high-risk, and there are no guarantees of profitability or
            protection against losses. Before making any investment decisions,
            it is crucial to thoroughly understand the associated risks and
            carefully review Mowgli Base's Terms & Conditions.
          </p>
          <p>
            View our <b>Terms & Conditions</b>
          </p>
        </div>

        {/* <img src={img3} alt="" className="w-40" /> */}
      </div>

      {/* <p onClick={CheckApi}>login</p> */}
    </div>
  );
}
