import React, { useEffect, useState } from "react";
import a1 from "../../Images/Bagheera.jpg";
import a5 from "../../Images/Baloo.jpg";
import a4 from "../../Images/Kaa.jpg";
import a3 from "../../Images/King.jpg";
import a2 from "../../Images/Mowgli.jpg";
import a6 from "../../Images/Sherkhan.jpg";
import b2 from "../../Images/source-hat.webp";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { Loder } from "../../Alldilog/Loder";

export default function Monglicoinclaim() {
  const [isLoding, setIsLoding] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoding(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);
  return (
    <>
      {isLoding ? (
        <div className="flex flex-col justify-center items-center lg:py-56 py-20 bg-gradient-to-t from-[#599f00] to-[#a9dc01] h-screen ">
          <p className="lg:text-6xl font-bold text-xl text-pink-500">
            <Loder />
          </p>
        </div>
      ) : (
        <>
          {" "}
          <Header />{" "}
          <div className="flex flex-col bg-gradient-to-b from-[#6ab616] to-[#215606] lg:px-20 px-5">
            <div className="flex flex-col mt-20 py-10">
              <div className="flex flex-col my-10 gap-3">
                <p className="text-white font-bold lg:text-3xl text-xl font-arco">
                  GET MOWGLI SANCTUARY (PRE-SALE)
                </p>
              </div>
              <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-5 w-full">
                <img
                  src={a2}
                  alt=""
                  className="object-contain lg:w-[500px] rounded-3xl"
                />{" "}
                <img
                  src={a6}
                  alt=""
                  className=" object-contain lg:w-[500px] rounded-3xl"
                />{" "}
                <img
                  src={a5}
                  alt=""
                  className=" object-contain lg:w-[500px] rounded-3xl"
                />
                <img
                  src={a1}
                  alt=""
                  className=" object-contain lg:w-[500px] rounded-3xl"
                />
                <img
                  src={a4}
                  alt=""
                  className=" object-contain lg:w-[500px] rounded-3xl"
                />
                <img
                  src={a3}
                  alt=""
                  className=" object-contain lg:w-[500px] rounded-3xl"
                />
              </div>
              {/* <div className="grid lg:grid-cols-3 grid-cols-1 w-full justify-center  gap-14 ">
                <div className="flex w-full  sm:w-auto">
                  <div className="relative h-[679px] flex w-full overflow-hidden rounded-default bg-gradient-to-b from-[#E6E6E6] to-white text-blackGrape shadow-dropdown sm:w-[480px]">
                    <img
                      src={a2}
                      alt=""
                      className="absolute  h-[700px] w-auto max-w-none select-none"
                    />
                  </div>
                </div>
                <div className="flex w-full flex-col sm:w-auto">
                  <div className="relative h-[679px] w-full overflow-hidden rounded-default bg-gradient-to-b from-[#E6E6E6] to-white text-blackGrape shadow-dropdown sm:w-[480px]">
                    <img
                      src={a6}
                      alt=""
                      className="absolute  h-[700px] w-auto max-w-none select-none"
                    />
                  </div>
                </div>
                <div className="flex w-full flex-col sm:w-auto">
                  <div className="relative h-[679px] w-full overflow-hidden rounded-default bg-gradient-to-b from-[#E6E6E6] to-white text-blackGrape shadow-dropdown sm:w-[480px]">
                    <img
                      src={a5}
                      alt=""
                      className="absolute  h-[700px] w-auto max-w-none select-none"
                    />
                  </div>
                </div>
                <div className="flex w-full flex-col sm:w-auto">
                  <div className="relative h-[679px] w-full overflow-hidden rounded-default bg-gradient-to-b from-[#E6E6E6] to-white text-blackGrape shadow-dropdown sm:w-[480px]">
                    <img
                      src={a1}
                      alt=""
                      className="absolute  h-[700px] w-auto max-w-none select-none"
                    />
                  </div>
                </div>{" "}
                <div className="flex w-full flex-col sm:w-auto">
                  <div className="relative h-[679px] w-full overflow-hidden rounded-default bg-gradient-to-b from-[#E6E6E6] to-white text-blackGrape shadow-dropdown sm:w-[480px]">
                    <img
                      src={a4}
                      alt=""
                      className="absolute  h-[700px] w-auto max-w-none select-none"
                    />
                  </div>
                </div>{" "}
                <div className="flex w-full flex-col sm:w-auto">
                  <div className="relative h-[679px] w-full overflow-hidden rounded-default bg-gradient-to-b from-[#E6E6E6] to-white text-blackGrape shadow-dropdown sm:w-[480px]">
                    <img
                      src={a3}
                      alt=""
                      className="absolute  h-[700px] w-auto max-w-none select-none"
                    />
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          <Footer />
        </>
      )}
    </>
  );
}
