import React from "react";
import nft from "../../Images/nft1.png";
import nft1 from "../../Images/nft2.png";
import nft2 from "../../Images/nft3.png";
import { Link, useNavigate } from "react-router-dom";

export default function Cart() {
  const navigate = useNavigate();
  return (
    <div className="flex w-full flex-col items-center bg-blackGrape bg-gradient-to-b from-[#D7550D] to-[#D7550D] px-5 sm:px-12 md:px-20">
      <div className="flex w-full max-w-desktop justify-center">
        <div className="flex w-full flex-col gap-12 py-28 text-white 2xl:grid 2xl:grid-cols-3">
          <div
            // data-aos="fade-right"
            // data-aos-delay="50"
            // data-aos-duration="1000"
            // data-aos-easing="ease-in-out-cubic"
            className="relative  flex-1 rounded-default bg-gradient-to-b from-[#E6E6E6] to-[#FFFFFF] px-10 py-8"
          >
            <p className="select-none relative flex z-20 items-center justify-center text-center font-arco drop-shadow-textCta text-shadow-textCta h-auto text-green-500 max-w-[500px] text-xl md:text-3xl leading-[30px]">
              GET SOME AWESOME
            </p>
            <p className="select-none relative flex z-20 items-center justify-center text-center font-arco drop-shadow-textCta text-shadow-textCta h-auto text-green-500 max-w-[500px] text-3xl md:text-6xl leading-[50px] whitespace-nowrap">
              MOWGLI NFTS
            </p>
            <img
              src={nft}
              alt=""
              className="absolute -left-[70px] top-[40px] z-10 h-auto select-none"
            />
            <div className="mt-16 flex justify-end">
              <Link
                to="https://opensea.io/collection/the-mowgli-sanctuary/overview"
                className="button inline-flex items-center justify-center rounded-[25px] uppercase text-lg relative gap-2.5 font-gotham font-bold green-button text-white px-9 py-[18px] h-[50px] z-30 shadow-darkShadow"
              >
                know more
              </Link>
            </div>
          </div>
          <div
            // data-aos="zoom-in"
            // data-aos-delay="50"
            // data-aos-duration="1000"
            // data-aos-easing="ease-in-out-cubic"
            className="relative  flex-1 rounded-default bg-gradient-to-b from-[#E6E6E6] to-[#FFFFFF] px-10 py-8"
          >
            <p className="select-none relative flex z-20 items-center text-green-500 justify-center text-center font-arco drop-shadow-textCta text-shadow-textCta h-auto max-w-[500px] text-xl md:text-3xl leading-[30px]">
              GET SOME AWESOME
            </p>
            <p className="select-none relative flex z-20 items-center text-green-500 justify-center text-center font-arco drop-shadow-textCta text-shadow-textCta h-auto max-w-[500px] text-3xl md:text-6xl leading-[50px] whitespace-nowrap">
              MOWGLI Token
            </p>
            <img
              src={nft1}
              alt=""
              className="absolute -left-[70px] top-[40px] z-10 h-auto select-none"
            />
            <div className="mt-16 flex justify-end">
              <button
                onClick={() => navigate("/monglicoin-claim")}
                className="button inline-flex items-center justify-center rounded-[25px] uppercase text-lg relative gap-2.5 font-gotham font-bold green-button text-white px-9 py-[18px] h-[50px] z-30 shadow-darkShadow"
              >
                know more
              </button>
            </div>
          </div>
          <div
            // data-aos="fade-left"
            // data-aos-delay="50"
            // data-aos-duration="1000"
            // data-aos-easing="ease-in-out-cubic"
            className="relative  flex-1 rounded-default bg-gradient-to-b from-[#E6E6E6] to-[#FFFFFF] px-10 py-8"
          >
            <p className="select-none relative flex z-20 items-center text-green-500 justify-center text-center font-arco drop-shadow-textCta text-shadow-textCta h-auto max-w-[500px] text-xl md:text-3xl leading-[30px]">
              GET SOME AWESOME
            </p>
            <p className="select-none relative flex z-20 items-center text-green-500 justify-center text-center font-arco drop-shadow-textCta text-shadow-textCta h-auto max-w-[500px] text-3xl md:text-6xl leading-[50px] whitespace-nowrap">
              SANCTUARY
            </p>
            <img
              src={nft2}
              alt=""
              className="absolute -left-[70px] top-[40px] z-10 h-auto select-none"
            />
            <div className="mt-16 flex justify-end">
              <button
                onClick={() => navigate("/mowgli-gallery")}
                className="button inline-flex items-center justify-center rounded-[25px] uppercase text-lg relative gap-2.5 font-gotham font-bold green-button text-white px-9 py-[18px] h-[50px] z-30 shadow-darkShadow"
              >
                know more
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
