import React from "react";
import Lottie from "react-lottie";
import animationData from "../../Images/loder.json";

export default function ButtonLoder() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Lottie
      className="h-10 w-10"
      options={defaultOptions}
      isClickToPauseDisabled={true}
    />
  );
}
