import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Page/Home";
import Grapesgallery from "./Page/Grapesgallery";
import Monglicoin from "./Page/Monglicoin";
import Monglicoinclaim from "./Page/Monglicoinclaim";
import Grapecoinbridge from "./Page/Grapecoinbridge";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "@rainbow-me/rainbowkit/styles.css";
import { configureChains, mainnet, useAccount, useDisconnect } from "wagmi";
import {
  arbitrum,
  base,
  optimism,
  polygon,
  zora,
  zkSyncSepoliaTestnet,
  bscTestnet,
  sepolia,
} from "viem/chains";
import { alchemyProvider } from "wagmi/providers/alchemy";
import { publicProvider } from "wagmi/providers/public";
import { RainbowKitProvider } from "@rainbow-me/rainbowkit";
import Stakings from "../src/Dashboard/Staking";
import Storay from "../src/Dashboard/Storay";
import Exclusive from "../src/Dashboard/Exclusive";
import Sanctuary from "../src/Dashboard/Sanctuary";
import { Staking } from "./Page/Context/StakingApp";
import Harvesting from "./Page/Harvesting";
import Dashboard from "./Dashboard";
import "aos/dist/aos.css";
import Aos from "aos";

import { TransitionProvider } from "./Page/Home/TransitionContext";
import TransitionComponent from "./Components/Transition";
import Faqs from "./Page/Faqs";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Joyride1 from "./Alldilog/Joyride";
import Page404 from "./Alldilog/Page404";

function App() {
  Aos.init();
  const { chains, publicClient } = configureChains(
    [
      mainnet,
      polygon,
      optimism,
      arbitrum,
      base,
      zora,
      zkSyncSepoliaTestnet,
      bscTestnet,
      sepolia,
    ],
    [publicProvider()]
  );

  return (
    <div className="bg-[#041125] lg:h-screen">
      <RainbowKitProvider chains={chains}>
        <BrowserRouter>
          <Staking>
            <TransitionProvider>
              <Routes>
                <Route
                  path="/"
                  element={
                    <TransitionComponent>
                      <Home />
                    </TransitionComponent>
                  }
                />

                <Route
                  path="/mowgli-gallery"
                  element={
                    <TransitionComponent>
                      <Grapesgallery />
                    </TransitionComponent>
                  }
                />
                <Route
                  path="/monglicoin"
                  element={
                    <TransitionComponent>
                      <Monglicoin />
                    </TransitionComponent>
                  }
                />
                <Route
                  path="/monglicoin-claim"
                  element={
                    <TransitionComponent>
                      <Monglicoinclaim />
                    </TransitionComponent>
                  }
                />
                <Route
                  path="/mowglicoin-bridge"
                  element={
                    <TransitionComponent>
                      <Grapecoinbridge />
                    </TransitionComponent>
                  }
                />
                <Route
                  path="/harvesting"
                  element={
                    <TransitionComponent>
                      <Harvesting />
                    </TransitionComponent>
                  }
                />
                <Route
                  path="/dashboard"
                  element={
                    <TransitionComponent>
                      <Dashboard />
                    </TransitionComponent>
                  }
                />
                <Route
                  path="/staking"
                  element={
                    <TransitionComponent>
                      <Stakings />
                    </TransitionComponent>
                  }
                />
                <Route
                  path="/Storay"
                  element={
                    <TransitionComponent>
                      <Storay />
                    </TransitionComponent>
                  }
                />
                <Route
                  path="/staking/exclusive"
                  element={
                    <TransitionComponent>
                      <Exclusive />
                    </TransitionComponent>
                  }
                />
                <Route
                  path="/staking/sanctuary"
                  element={
                    <TransitionComponent>
                      <Sanctuary />
                    </TransitionComponent>
                  }
                />

                <Route
                  path="/faqs"
                  element={
                    <TransitionComponent>
                      <Faqs />
                    </TransitionComponent>
                  }
                />
                <Route path="*" element={<Page404 />} />
              </Routes>
            </TransitionProvider>
          </Staking>
        </BrowserRouter>
        <ToastContainer />
      </RainbowKitProvider>
    </div>
  );
}

export default App;
