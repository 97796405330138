import React from "react";
import a1 from "../../../Images/twitter-header-icon.png";
import a2 from "../../../Images/facebook.png";
import a3 from "../../../Images/m.png";
import a4 from "../../../Images/telegram-512.png";
import b1 from "../../../Images/xtwtter.avif";
import b2 from "../../../Images/facebook.avif";
import b3 from "../../../Images/mid3.avif";
import b4 from "../../../Images/teli.avif";

import { Box, Menu } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
export default function Community() {
  const navgate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <p className="font-bold" onClick={handleClick}>
          Community
        </p>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        // PaperProps={{
        //   elevation: 0,
        //   sx: {
        //     overflow: "visible",
        //     filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
        //     mt: 1.5,
        //     "& .MuiAvatar-root": {
        //       width: 32,
        //       height: 32,
        //       ml: -0.5,
        //       mr: 1,
        //     },
        //     "&::before": {
        //       content: '""',
        //       display: "block",
        //       position: "absolute",
        //       top: 0,
        //       right: 50,
        //       width: 10,
        //       height: 10,
        //       bgcolor: "background.paper",
        //       transform: "translateY(-50%) rotate(45deg)",
        //       zIndex: 0,
        //     },
        //   },
        // }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <div className="flex justify-center items-center">
          <div className=" flex flex-col px-10 py-10 gap-20">
            <div className="grid lg:grid-cols-3 grid-cols-1 gap-5">
              <div
                className=" flex flex-col justify-center items-center border p-3 bg-gray-500 rounded-2xl  bg-opacity-5 "
                style={{
                  backgroundImage: `url(${b1})`,
                  backgroundSize: "cover",
                }}
              >
                <img
                  src={a1}
                  alt=""
                  className="absolute  top-[1rem] h-20 z-10 rounded-full shadow-socialIcons"
                />
                <span className="flex flex-col items-center justify-center mt-10">
                  <p className="font-bold text-white font-arco text-xl my-1">
                    XTWITTER
                  </p>
                  <p className="text-white font-bold">
                    Stay up to date with everything Mowgli related
                  </p>
                  <Link
                    to="https://twitter.com/MowgliSanctuary"
                    className="text-white text-xl underline my-1 hover:text-black"
                  >
                    FOLLOW US
                  </Link>
                </span>
              </div>
              <div
                className=" flex flex-col justify-center items-center border p-3 bg-gray-500 rounded-2xl  bg-opacity-5 "
                style={{
                  backgroundImage: `url(${b2})`,
                  backgroundSize: "cover",
                }}
              >
                <img
                  src={a2}
                  alt=""
                  className="absolute  top-[1rem] h-20 z-10 rounded-full shadow-socialIcons"
                />
                <span className="flex flex-col items-center justify-center mt-10">
                  <p className="font-bold text-white font-arco text-xl my-1">
                    FACEBOOK
                  </p>
                  <p className="text-white font-bold">
                    Stay up to date with everything Mowgli related
                  </p>
                  <Link
                    to="https://www.facebook.com/people/Mowgli-Sanctuary/100090329212988/"
                    className="text-white text-xl underline my-1 hover:text-black"
                  >
                    FOLLOW US
                  </Link>
                </span>
              </div>
              <div
                className=" flex flex-col justify-center items-center border p-3 bg-gray-500 rounded-2xl  bg-opacity-5 "
                style={{
                  backgroundImage: `url(${b3})`,
                  backgroundSize: "cover",
                }}
              >
                <img
                  src={a3}
                  alt=""
                  className="absolute  top-[1rem] h-20 z-10 rounded-full shadow-socialIcons"
                />
                <span className="flex flex-col items-center justify-center mt-10">
                  <p className="font-bold text-white font-arco text-xl my-1">
                    MEDIUM
                  </p>
                  <p className="text-white font-bold">
                    Stay up to date with everything Mowgli related
                  </p>
                  <Link
                    to="https://medium.com/@mowglisanctuary"
                    className="text-white text-xl underline my-1 hover:text-black"
                  >
                    FOLLOW US
                  </Link>
                </span>
              </div>
            </div>

            <div className="grid grid-cols-1 gap-20">
              <div
                className=" flex flex-col justify-center items-center border p-3 bg-gray-500 rounded-2xl  bg-opacity-5 "
                style={{
                  backgroundImage: `url(${b4})`,
                  backgroundSize: "cover",
                }}
              >
                <img
                  src={a4}
                  alt=""
                  className="absolute  top-[14rem] h-20 z-10 rounded-full shadow-socialIcons"
                />
                <span className="flex flex-col items-center justify-center mt-10">
                  <p className="font-bold text-white font-arco text-xl my-1">
                    TELEGRAM
                  </p>
                  <p className="text-white font-bold">
                    Stay up to date with everything Mowgli related
                  </p>
                  <Link
                    to="https://t.me/Mowglisanctuaryofficial"
                    className="text-white text-xl underline my-1 hover:text-black"
                  >
                    FOLLOW US
                  </Link>
                </span>
              </div>
            </div>
          </div>
        </div>
      </Menu>
    </div>
  );
}
