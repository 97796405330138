import React from "react";
import a1 from "../../Images/slide1.png";
import a2 from "../../Images/slide2.png";
import { Carousel } from "react-responsive-carousel";

export default function Slaider() {
  return (
    <div>
      <Carousel
        autoPlay
        showStatus={false}
        showThumbs={false}
        showArrows={false}
      >
        <div className="lg:flex p-10 lg:h-[800px] items-center lg:w-full lg:mt-0 pt-24  bg-gradient-to-t from-[#599f00] to-[#a9dc01]">
          <div className="flex flex-col">
            <p className="text-white font-bold lg:text-2xl text-xl w-full my-3">
              Show Your Support...
            </p>
            <p className="text-white font-bold lg:text-6xl w-full text-2xl my-3 font-arco">
              Mowgli merch!
            </p>
            <p className="text-white font-bold my-5 lg:text-2xl">
              Take your love for Mowgli beyond the screen with our forthcoming
              range of limited edition collectibles, products and merchandise.
            </p>
            <p className="lg:block hidden text-white font-bold my-5 lg:text-2xl">
              Don't just be part of the crew; be a captain of style with Mowgli
              merchandise. Dive into our digital emporium and deck yourself out
              in Mowgli-chic fashion. Your journey to Mowgli excellence begins
              now!
            </p>
          </div>
          <img
            src={a2}
            // data-aos="fade-left"
            // data-aos-delay="20"
            // data-aos-duration="2000"
            // data-aos-easing="ease-in-out-cubic"
            alt=""
            className=" lg:h-[100%] h-[20%] object-contain "
          />
        </div>
        <div className="lg:flex p-10 lg:h-[800px] items-center lg:w-full lg:mt-0 pt-24 bg-gradient-to-t from-[#1dcfff] to-[#a5eafc] ">
          <div className="flex flex-col">
            <p className="text-white font-bold lg:text-2xl text-xl w-full my-3">
              Show Your Support...
            </p>
            <p className="text-white font-bold lg:text-6xl w-full text-2xl my-3 font-arco">
              Mowgli merch!
            </p>
            <p className="text-white font-bold my-5 lg:text-2xl">
              Take your love for Mowgli beyond the screen with our forthcoming
              range of limited edition collectibles, products and merchandise.
            </p>
            <p className="lg:block hidden text-white font-bold my-5 lg:text-2xl">
              Don't just be part of the crew; be a captain of style with Mowgli
              merchandise. Dive into our digital emporium and deck yourself out
              in Mowgli-chic fashion. Your journey to Mowgli excellence begins
              now!
            </p>
          </div>
          <img
            src={a1}
            // data-aos="fade-left"
            // data-aos-delay="20"
            // data-aos-duration="2000"
            // data-aos-easing="ease-in-out-cubic"
            alt=""
            className=" lg:h-[100%] h-[20%] object-contain "
          />
        </div>
        {/* <div className="lg:flex lg:h-[800px] h-[500px] bg-gradient-to-t from-[#1dcfff] to-[#a5eafc] gap-10 lg:py-0 py-24  lg:px-10 px-5">
          <div
            data-aos="flip-up"
            data-aos-delay="50"
            data-aos-duration="2000"
            data-aos-easing="ease-in-out-cubic"
            className=" flex flex-col items-start justify-center lg:w-[35%] text-white"
          >
            <p className="mb-2 font-bold uppercase text-xl lg:my-5 my-2 lg:block hidden">
              show your support...
            </p>
            <p className="mb-2 max-w-2xl font-arco lg:text-[68px] text-[30px] leading-[68px] lg:my-3">
              Mowgli merch!
            </p>
            <div className="flex flex-col text-start gap-6 lg:text-xl font-bold lg:my-5 my-2 ">
              <p>
                Take your love for Mongli beyond the screen with our forthcoming
                range of limited edition collectibles, products and merchandise.
              </p>
              <p className="lg:block hidden">
                Don't just be part of the crew; be a captain of style with
                Mowgli merchandise. Dive into our digital emporium, deck
                yourself out in Mowgli-chic fashion. Your journey to Mowgli
                excellence begins now!
              </p>
            </div>
          </div>
          <div
            className="lg:w-[55%] relative overflow-hidden lg:flex flex-col items-center  bg-opacity-5 "
            // style={{ backgroundImage: `url(${a1})`, backgroundSize: "cover" }}
          >
            <img
              src={a1}
              alt=""
              className="lg:bottom-0 lg:right-0 lg:absolute lg:h-[100%] "
              data-aos="fade-left"
              data-aos-delay="20"
              data-aos-duration="2000"
              data-aos-easing="ease-in-out-cubic"
            />
          </div>
        </div>
        <div className="lg:flex lg:h-[800px] h-[615px] bg-gradient-to-t from-[#599f00] to-[#a9dc01] lg:gap-20 lg:py-0 py-24 lg:px-10 px-5">
          <div
            data-aos="flip-left"
            data-aos-delay="50"
            data-aos-duration="2000"
            data-aos-easing="ease-in-out-cubic"
            className=" flex flex-col items-start justify-center lg:w-[40%] text-white"
          >
            <p className="mb-2 font-bold uppercase text-xl lg:my-5 my-2 lg:block hidden">
              show your support...
            </p>
            <p className="mb-2 max-w-2xl font-arco lg:text-[68px] text-[30px] leading-[68px] lg:my-3">
              Mowgli merch!
            </p>
            <div className="flex flex-col text-start lg:gap-6 lg:text-xl font-bold lg:my-5 my-2 ">
              <p>
                Take your love for Mongli beyond the screen with our forthcoming
                range of limited edition collectibles, products and merchandise.
              </p>
              <p className="lg:block hidden">
                Don't just be part of the crew; be a captain of style with
                Mowgli merchandise. Dive into our digital emporium, deck
                yourself out in Mowgli-chic fashion. Your journey to Mowgli
                excellence begins now!
              </p>
            </div>
          </div>
          <div className="lg:w-[60%] relative overflow-hidden lg:flex flex-col items-center  bg-opacity-5 ">
            <img
              src={a2}
              data-aos="fade-left"
              data-aos-delay="20"
              data-aos-duration="2000"
              data-aos-easing="ease-in-out-cubic"
              alt=""
              className="lg:bottom-0 lg:right-0 lg:absolute lg:h-[100%] h-[20%] object-contain "
            />
          </div>
        </div> */}
      </Carousel>
    </div>
  );
}
