import * as React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import nft from "../../Images/nft1.png";
import nft1 from "../../Images/nft2.png";
import nft2 from "../../Images/nft3.png";
import MenuIcon from "@mui/icons-material/Menu";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { Link, useNavigate } from "react-router-dom";
import CollectionsIcon from "@mui/icons-material/Collections";
import OpenWithIcon from "@mui/icons-material/OpenWith";
import FilterTiltShiftIcon from "@mui/icons-material/FilterTiltShift";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import TelegramIcon from "@mui/icons-material/Telegram";
import BrightnessMediumIcon from "@mui/icons-material/BrightnessMedium";
import FacebookIcon from "@mui/icons-material/Facebook";
import XIcon from "@mui/icons-material/X";
import Sign from "../Sign";
import { Drawer } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

export default function MobileMenu() {
  const [menu, setMenu] = React.useState("");
  const navigate = useNavigate();
  const [open1, setOpen1] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen1(true);
  };

  const handleDrawerClose = () => {
    setOpen1(false);
  };
  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <MenuIcon
          onClick={handleDrawerOpen}
          className="!text-4xl text-white "
        />
      </Box>
      <Drawer
        PaperProps={{
          className: "!w-[100%] py-5 px-5 !bg-purple-100 ",
        }}
        anchor="left"
        open={open1}
        onClose={handleDrawerClose}
      >
        <div className="flex flex-col bg-[#041125] h-full text-white rounded-2xl  py-3  px-5">
          <div className="flex flex-col ">
            <div className="flex items-end justify-end">
              <span className="border py-1 rounded-lg px-5">
                <KeyboardBackspaceIcon
                  onClick={handleDrawerClose}
                  className=" !text-3xl font-bold cursor-pointer"
                />
              </span>
            </div>

            <p
              onClick={() => {
                navigate("/");
                handleDrawerClose();
              }}
              className="text-xl font-bold my-2 underline cursor-pointer"
            >
              Home
            </p>
          </div>
          <div className="flex flex-col ">
            <p
              onClick={() => setMenu("Ecosystem")}
              className="text-xl font-bold my-2 underline cursor-pointer"
            >
              Ecosystem
            </p>
            {menu === "Ecosystem" && (
              <span>
                <p
                  onClick={() => {
                    navigate("/mowgli-gallery");
                    handleDrawerClose();
                  }}
                  className="font-bold text-purple-500 hover:underline my-1 cursor-pointer hover:text-green-500 ml-1"
                >
                  <CollectionsIcon className="!text-purple-500 !text-xl" />{" "}
                  MOWGLI GALLERY
                </p>
                <Link
                  to="https://opensea.io/collection/the-mowgli-sanctuary/overview"
                  className="font-bold text-purple-500 hover:underline my-1 cursor-pointer hover:text-green-500 ml-1"
                >
                  <OpenWithIcon className="!text-purple-500 !text-xl" />
                  OPENSEA
                </Link>
                <p
                  onClick={() => {
                    handleDrawerClose();
                  }}
                  className="font-bold text-purple-500 hover:underline my-1 cursor-pointer hover:text-green-500 ml-1"
                >
                  <FilterTiltShiftIcon className="!text-purple-500 !text-xl" />
                  BLUR (COMING SOON...)
                </p>

                <p
                  onClick={() => {
                    navigate("/monglicoin");
                    handleDrawerClose();
                  }}
                  className="font-bold text-purple-500 hover:underline my-1 cursor-pointer hover:text-green-500 ml-1"
                >
                  <OpenWithIcon className="!text-purple-500 !text-xl" />
                  MOWGLI SANCTUARY (PRE-SALE)
                </p>
                <p
                  onClick={() => {
                    navigate("/monglicoin-claim");
                    handleDrawerClose();
                  }}
                  className="font-bold text-purple-500 hover:underline my-1 cursor-pointer hover:text-green-500 ml-1"
                >
                  <AddCircleOutlineIcon className="!text-purple-500 !text-xl" />
                  CLAIM MOWGLI SANCTUARY TOKENS
                </p>
              </span>
            )}
          </div>

          <div className="flex flex-col ">
            <p
              onClick={() => setMenu("Community")}
              className="text-xl font-bold my-2 underline cursor-pointer"
            >
              Community
            </p>
            {menu === "Community" && (
              <span className="flex flex-col">
                <Link
                  to="https://twitter.com/MowgliSanctuary"
                  className="font-bold text-purple-500 hover:underline my-1 cursor-pointer hover:text-green-500"
                >
                  <XIcon className="!text-purple-500 !text-xl" />
                  XTWITTER
                </Link>
                <Link
                  to="https://www.facebook.com/people/Mowgli-Sanctuary/100090329212988/"
                  className="font-bold text-purple-500 hover:underline my-1 cursor-pointer hover:text-green-500"
                >
                  <FacebookIcon className="!text-purple-500 !text-xl" />
                  FACEBOOK
                </Link>
                <Link
                  to="https://medium.com/@mowglisanctuary"
                  className="font-bold text-purple-500 hover:underline my-1 cursor-pointer hover:text-green-500"
                >
                  <BrightnessMediumIcon className="!text-purple-500 !text-xl" />
                  MEDIUM
                </Link>
                <Link
                  to="https://t.me/Mowglisanctuaryofficial"
                  className="font-bold text-purple-500 hover:underline my-1 cursor-pointer hover:text-green-500"
                >
                  <TelegramIcon className="!text-purple-500 !text-xl" />
                  TELEGRAM
                </Link>
              </span>
            )}
          </div>
          <div className="flex flex-col ">
            <p
              onClick={() => setMenu("Info")}
              className="text-xl font-bold my-2 underline cursor-pointer"
            >
              Info
            </p>
            {menu === "Info" && (
              <span>
                <p className="font-bold text-purple-500 hover:underline my-1 cursor-pointer hover:text-green-500">
                  FAQS
                </p>
                <p className="font-bold text-purple-500 hover:underline my-1 cursor-pointer hover:text-green-500">
                  PRIVACY POLICY
                </p>
                <p className="font-bold text-purple-500 hover:underline my-1 cursor-pointer hover:text-green-500">
                  TERMS & CONDITIONS
                </p>
              </span>
            )}
          </div>

          <Sign />
          <div className="border-b-2 my-3"></div>
          <div className="flex flex-col gap-5 ">
            <Link
              to="https://opensea.io/collection/the-mowgli-sanctuary/overview"
              className="flex h-14  items-center  bg-pink-500 rounded-xl"
            >
              <img src={nft} alt="" className="h-16 lg:w-20 " />
              <h3 className="font-arco text-[15px]  lg:text-[18px] text-white cursor-pointer">
                Get mowgli nfts
              </h3>
            </Link>
            <div
              onClick={() => navigate("/monglicoin-claim")}
              className="flex h-14 items-center  bg-pink-500 rounded-xl "
            >
              <img src={nft1} alt="" className="h-16 w-20   " />
              <h3 className="font-arco text-[15px]  lg:text-[18px] text-white cursor-pointer">
                Get mowgli coin
              </h3>
            </div>
            <div
              onClick={() => navigate("/mowgli-gallery")}
              className="flex h-14 items-center bg-pink-500 rounded-xl "
            >
              <img src={nft2} alt="" className="h-16 w-20   " />
              <h3 className="font-arco text-[15px]  lg:text-[18px] text-white cursor-pointer">
                Mowgli Sanctuary
              </h3>
            </div>
          </div>
        </div>
      </Drawer>
      {/* <Menu
        anchorEl={anchorEl1}
        id="account-menu"
        open={open1}
        onClose={handleClose1}
       
        className="!mt-10"
       
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      >
        <div className="flex flex-col  lg:min-w-[380px] px-5">
          <div className="flex flex-col ">
            <p
              onClick={() => setMenu("")}
              className="text-xl font-bold my-2 underline cursor-pointer"
            >
              Home
            </p>
          </div>
          <div className="flex flex-col ">
            <p
              onClick={() => setMenu("Ecosystem")}
              className="text-xl font-bold my-2 underline cursor-pointer"
            >
              Ecosystem
            </p>
            {menu === "Ecosystem" && (
              <span>
                <p
                  onClick={() => {
                    navigate("/mowgli-gallery");
                    handleClose1();
                  }}
                  className="font-bold text-purple-500 hover:underline my-1 cursor-pointer hover:text-green-500 ml-1"
                >
                  <CollectionsIcon className="!text-purple-500 !text-xl" />{" "}
                  MOWGLI GALLERY
                </p>
                <Link
                  to="https://opensea.io/collection/the-mowgli-sanctuary/overview"
                  className="font-bold text-purple-500 hover:underline my-1 cursor-pointer hover:text-green-500 ml-1"
                >
                  <OpenWithIcon className="!text-purple-500 !text-xl" />
                  OPENSEA
                </Link>
                <p
                  onClick={() => {
                    handleClose1();
                  }}
                  className="font-bold text-purple-500 hover:underline my-1 cursor-pointer hover:text-green-500 ml-1"
                >
                  <FilterTiltShiftIcon className="!text-purple-500 !text-xl" />
                  BLUR (COMING SOON...)
                </p>
                
                <p
                  onClick={() => {
                    navigate("/monglicoin");
                    handleClose1();
                  }}
                  className="font-bold text-purple-500 hover:underline my-1 cursor-pointer hover:text-green-500 ml-1"
                >
                  <OpenWithIcon className="!text-purple-500 !text-xl" />
                  MOWGLI SANCTUARY (PRE-SALE)
                </p>
                <p
                  onClick={() => {
                    navigate("/monglicoin-claim");
                    handleClose1();
                  }}
                  className="font-bold text-purple-500 hover:underline my-1 cursor-pointer hover:text-green-500 ml-1"
                >
                  <AddCircleOutlineIcon className="!text-purple-500 !text-xl" />
                  CLAIM MOWGLI SANCTUARY TOKENS
                </p>
              </span>
            )}
          </div>

          <div className="flex flex-col ">
            <p
              onClick={() => setMenu("Community")}
              className="text-xl font-bold my-2 underline cursor-pointer"
            >
              Community
            </p>
            {menu === "Community" && (
              <span className="flex flex-col">
                <Link
                  to="https://twitter.com/MowgliSanctuary"
                  className="font-bold text-purple-500 hover:underline my-1 cursor-pointer hover:text-green-500"
                >
                  <XIcon className="!text-purple-500 !text-xl" />
                  XTWITTER
                </Link>
                <Link
                  to="https://www.facebook.com/people/Mowgli-Sanctuary/100090329212988/"
                  className="font-bold text-purple-500 hover:underline my-1 cursor-pointer hover:text-green-500"
                >
                  <FacebookIcon className="!text-purple-500 !text-xl" />
                  FACEBOOK
                </Link>
                <Link
                  to="https://medium.com/@mowglisanctuary"
                  className="font-bold text-purple-500 hover:underline my-1 cursor-pointer hover:text-green-500"
                >
                  <BrightnessMediumIcon className="!text-purple-500 !text-xl" />
                  MEDIUM
                </Link>
                <Link
                  to="https://t.me/Mowglisanctuaryofficial"
                  className="font-bold text-purple-500 hover:underline my-1 cursor-pointer hover:text-green-500"
                >
                  <TelegramIcon className="!text-purple-500 !text-xl" />
                  TELEGRAM
                </Link>
              </span>
            )}
          </div>
          <div className="flex flex-col ">
            <p
              onClick={() => setMenu("Info")}
              className="text-xl font-bold my-2 underline cursor-pointer"
            >
              Info
            </p>
            {menu === "Info" && (
              <span>
                <p className="font-bold text-purple-500 hover:underline my-1 cursor-pointer hover:text-green-500">
                  FAQS
                </p>
                <p className="font-bold text-purple-500 hover:underline my-1 cursor-pointer hover:text-green-500">
                  PRIVACY POLICY
                </p>
                <p className="font-bold text-purple-500 hover:underline my-1 cursor-pointer hover:text-green-500">
                  TERMS & CONDITIONS
                </p>
              </span>
            )}
          </div>
         
          <Sign />
          <div className="border-b-2 my-3"></div>
          <div className="flex flex-col gap-5 ">
            <Link
              to="https://opensea.io/collection/the-mowgli-sanctuary/overview"
              className="flex h-14  items-center  bg-pink-500 rounded-xl"
            >
              <img src={nft} alt="" className="h-16 lg:w-20 " />
              <h3 className="font-arco text-[15px]  lg:text-[18px] text-white cursor-pointer">
                Get mowgli nfts
              </h3>
            </Link>
            <div
              onClick={() => navigate("/monglicoin-claim")}
              className="flex h-14 items-center  bg-pink-500 rounded-xl "
            >
              <img src={nft1} alt="" className="h-16 w-20   " />
              <h3 className="font-arco text-[15px]  lg:text-[18px] text-white cursor-pointer">
                Get mowgli coin
              </h3>
            </div>
            <div
              onClick={() => navigate("/mowgli-gallery")}
              className="flex h-14 items-center bg-pink-500 rounded-xl "
            >
              <img src={nft2} alt="" className="h-16 w-20   " />
              <h3 className="font-arco text-[15px]  lg:text-[18px] text-white cursor-pointer">
                Mowgli Sanctuary
              </h3>
            </div>
          </div>
        </div>
      </Menu> */}
    </React.Fragment>
  );
}
