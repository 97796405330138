import React, { useEffect } from "react";
import "./point.css";

export default function Point(id) {
  return (
    <div id={id} class="stepper-wrapper mt-20">
      <div class="stepper-item items-center completed text-white">
        <div class="step-counter">0</div>
        <div class="step-name">Days</div>
        <div class="step-name">Journey start</div>
      </div>
      <div class="stepper-item items-center completed  text-white">
        <div class="step-counter">30</div>
        <div class="step-name">Days</div>
        <div class="step-name">900 MS / +5% of Total MS (one-time)</div>
      </div>
      <div class="stepper-item completed  text-white">
        <div class="step-counter">60</div>
        <div class="step-name">Days</div>
        <div class="step-name">1050 MS / +7% of Total MS (one-time)</div>
      </div>
      <div class="stepper-item completed text-white">
        <div class="step-counter">120</div>
        <div class="step-name">Days</div>
        <div class="step-name">1200 MS / +10% of Total MS (one-time)</div>
      </div>
      <div class="stepper-item completed text-white">
        <div class="step-counter">160</div>
        <div class="step-name">Days</div>
        <div class="step-name">1500 MS / +12% of Total MS (one-time)</div>
      </div>
      <div class="stepper-item completed text-white">
        <div class="step-counter">+180</div>
        <div class="step-name">Days</div>
        <div class="step-name">
          Pioneer 2000 MS /+12% of Total MS(200 MS/day after 180 days)
        </div>
      </div>
    </div>
  );
}
