import React, { useEffect, useState } from "react";
import Games from "../Page/Games";
import Ecosystem from "../Page/Ecosystem";
import Community from "../Page/Community";
import Info from "../Page/Info";
import logo from "../../Images/mowgli-sanctuary.png";
import { useNavigate } from "react-router-dom";
import Login from "../../Alldilog/Login";
import CreateButoon from "../../Alldilog/ConnectButton";
import MobileMenu from "../../Alldilog/MobileMenu";
import axios from "axios";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import { useAccount } from "wagmi";
import Sign from "../../Alldilog/Sign";

export default function Header() {
  const [page, setPage] = useState("");
  const { address } = useAccount();

  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  return (
    <>
      <Login setOpen={setOpen} open={open} />
      <div className="flex  bg-black bg-opacity-15 fixed z-50 w-full h-24 items-center">
        <div className="flex w-full lg:gap-20">
          <div className=" flex lg:hidden items-center justify-center lg:pl-10">
            <MobileMenu />
          </div>
          <div className="lg:w-[50%]  lg:pl-20 pl-5">
            <img
              onClick={() => navigate("/")}
              src={logo}
              alt=""
              className="lg:h-20 h-16 lg:w-[52] cursor-pointer"
            />
          </div>
          {/* {address && (
            <div className="flex text-center justify-between w-full border p-3 rounded-md">
              <div>
                {" "}
                <h5 className="mb-0 text-white font-bold">Today's Reward</h5>
                <h4 className="font-bold text-xl">
                  <EmojiEventsIcon /> {activity?.count * 100} PT
                </h4>
              </div>
              <div>
                {" "}
                <h5 className="mb-0 text-white font-bold">
                  Next Claim Will be started in
                </h5>
                <h5>{activity?.data[0]?.time}</h5>
              </div>
            </div>
          )} */}

          <div className="flex items-center justify-center px-3  gap-20">
            <p
              onClick={() => navigate("/")}
              className="text-2x font-bold text-white cursor-pointer lg:block hidden"
            >
              Home
            </p>

            <p className="text-2x font-bold text-white cursor-pointer lg:block hidden">
              <Ecosystem />
            </p>
            <p className="text-2x font-bold text-white cursor-pointer lg:block hidden">
              <Community />
            </p>

            <p className="text-2x font-bold text-white cursor-pointer lg:block hidden">
              <Info />
            </p>
            <p className="text-2x font-bold text-white cursor-pointer lg:block hidden">
              <Sign />
            </p>
          </div>
        </div>
        <div className="flex bg-black bg-opacity-20 h-full w-[18%] items-center justify-center gap-5">
          <CreateButoon />
        </div>
      </div>
    </>
  );
}
