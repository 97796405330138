import React from "react";
import a1 from "../../../Images/cartim.webp";
import a2 from "../../../Images/apple-store-badge.webp";
import g from "../../../Images/google-store-badge.webp";
import GridViewIcon from "@mui/icons-material/GridView";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import BlurCircularIcon from "@mui/icons-material/BlurCircular";
import HiveIcon from "@mui/icons-material/Hive";
import JoinInnerIcon from "@mui/icons-material/JoinInner";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import nft from "../../../Images/nft1.png";
import nft1 from "../../../Images/nft2.png";
import nft2 from "../../../Images/nft3.png";
import { Box, Menu } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
export default function Info() {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <p className="font-bold" onClick={handleClick}>
          Info
        </p>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        // PaperProps={{
        //   elevation: 0,
        //   sx: {
        //     overflow: "visible",
        //     filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
        //     mt: 1.5,
        //     "& .MuiAvatar-root": {
        //       width: 32,
        //       height: 32,
        //       ml: -0.5,
        //       mr: 1,
        //     },
        //     "&::before": {
        //       content: '""',
        //       display: "block",
        //       position: "absolute",
        //       top: 0,
        //       right: 14,
        //       width: 10,
        //       height: 10,
        //       bgcolor: "background.paper",
        //       transform: "translateY(-50%) rotate(45deg)",
        //       zIndex: 0,
        //     },
        //   },
        // }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <>
          <div>
            <div className="pl-5 text-blackGrape xl:px-[40px] xl:pb-[69px] xl:pt-[52px]">
              <div className="flex flex-col gap-8 xl:grid xl:min-w-[1024px] xl:grid-cols-3 xl:gap-16">
                <div className="flex max-w-[354px] flex-col">
                  <h2 className="hidden font-arco text-lg font-bold xl:inline-block">
                    What's going on?
                  </h2>
                  <span className="hidden text-sm xl:inline-block">
                    Check it out
                  </span>
                  <div className="flex flex-col xl:mt-3">
                    <div className="flex flex-col gap-3">
                      <span className="flex gap-3">
                        <HiveIcon className="text-[#FF4EA1]" />
                        <Link
                          to="/faqs"
                          className="flex items-center gap-1.5 text-lg font-bold text-[#260934] duration-200 hover:text-[#FF4EA1] xl:uppercase cursor-pointer "
                        >
                          FAQS
                        </Link>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex max-w-[354px] flex-col">
                  <h2 className="hidden font-arco text-lg font-bold xl:inline-block">
                    LEGAL JAZZ
                  </h2>
                  <span className="hidden text-sm xl:inline-block">
                    Yep, that’s right, here is the legal stuff
                  </span>
                  <div className="flex flex-col xl:mt-3">
                    <div className="flex flex-col gap-3">
                      <span className="flex gap-3">
                        <AddOutlinedIcon className="text-[#FF4EA1]" />
                        <a className="flex items-center gap-1.5 text-lg font-bold text-[#260934] duration-200 hover:text-[#FF4EA1] xl:uppercase cursor-pointer ">
                          PRIVACY POLICY
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex max-w-[354px] flex-col">
                  <h2 className="hidden font-arco text-lg font-bold xl:inline-block">
                    CONDITIONS
                  </h2>
                  <span className="hidden text-sm xl:inline-block">
                    Dive in and get your own Mowgli!
                  </span>
                  <div className="flex flex-col xl:mt-3">
                    <div className="flex flex-col gap-3">
                      <span className="flex gap-3">
                        <GridViewIcon className="text-[#FF4EA1]" />
                        <a className="flex items-center gap-1.5 text-lg font-bold text-[#260934] duration-200 hover:text-[#FF4EA1] xl:uppercase cursor-pointer ">
                          TERMS & CONDITIONS
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="hidden xl:block">
                <div className="my-[50px] h-px border-0 bg-blackGrape/25"></div>
                <div className="flex flex-col justify-between gap-5 xl:flex-row xl:gap-24">
                  <Link to="https://opensea.io/collection/the-mowgli-sanctuary/overview">
                    <button className="relative flex w-full max-w-[357px] gap-3 rounded-default p-3 shadow-game xl:gap-[20px] xl:p-7 bg-pink-500 text-white">
                      <img
                        src={nft}
                        className="absolute -left-[30px] -top-[25px] h-[120px] w-[120px] xl:-left-14 xl:-top-8 xl:h-[200px] xl:w-[200px]"
                        alt=""
                      />
                      <div className="w-[60px] xl:w-[80px]"></div>
                      <div className="flex flex-col gap-[5px]">
                        <h3 className="font-arco text-[18px]">
                          Get mowgli nfts
                        </h3>
                        <p className="hidden text-sm xl:block">
                          Join the community now
                        </p>
                      </div>
                    </button>
                  </Link>
                  <a onClick={() => navigate("/monglicoin-claim")}>
                    <button className="relative flex w-full max-w-[357px] gap-3 rounded-default p-3 shadow-game xl:gap-[20px] xl:p-7 bg-yellow-500 text-white">
                      <img
                        src={nft1}
                        className="absolute -left-[30px] -top-[25px] h-[120px] w-[120px] xl:-left-14 xl:-top-8 xl:h-[200px] xl:w-[200px]"
                        alt=""
                      />
                      <div className="w-[60px] xl:w-[80px]"></div>
                      <div className="flex flex-col gap-[5px]">
                        <h3 className="font-arco text-[18px]">
                          Get mowgli token
                        </h3>
                        <p className="hidden text-sm xl:block">
                          Find out more here
                        </p>
                      </div>
                    </button>
                  </a>
                  <a onClick={() => navigate("/mowgli-gallery")}>
                    <button className="relative flex w-full max-w-[357px] gap-3 rounded-default p-3 shadow-game xl:gap-[20px] xl:p-7 bg-orange-500 text-white">
                      <img
                        src={nft2}
                        className="absolute -left-[30px] -top-[25px] h-[120px] w-[120px] xl:-left-14 xl:-top-8 xl:h-[200px] xl:w-[200px]"
                        alt=""
                      />
                      <div className="w-[60px] xl:w-[80px]"></div>
                      <div className="flex flex-col gap-[5px]">
                        <h3 className="font-arco text-[15px]">
                          MOWGLI SANCTUARY
                        </h3>
                        <p className="hidden text-sm xl:block">
                          Get mowgli sanctuary here
                        </p>
                      </div>
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </>
      </Menu>
    </div>
  );
}
