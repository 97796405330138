import React, { useEffect, useState } from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { Loder } from "../../Alldilog/Loder";

export default function Harvesting() {
  const [isLoding, setIsLoding] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoding(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);
  return (
    <>
      <>
        {isLoding ? (
          <div className="flex flex-col justify-center items-center lg:py-56 py-20 bg-gradient-to-t from-[#599f00] to-[#a9dc01] h-screen ">
            <p className="lg:text-6xl font-bold text-xl text-pink-500">
              <Loder />
            </p>
          </div>
        ) : (
          <>
            {" "}
            <Header />{" "}
            <div className="flex flex-col bg-[#53A8F5] px-20">
              <div className="flex flex-col mt-24">
                <div className="flex justify-center items-center  h-screen">
                  <p className="text-8xl font-bold text-white text-center">
                    SEASON 1 COMING SOON...!
                  </p>
                </div>
              </div>
            </div>
            <Footer />
          </>
        )}
      </>
    </>
  );
}
