import React from "react";
import a1 from "../../Images/watermark.webp";
import a2 from "../../Images/source-hat.webp";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";

export default function Grapecoinbridge() {
  return (
    <>
      <Header />
      <div className="flex flex-col bg-gradient-to-b from-[#6ab616] to-[#215606] px-20">
        <div className="flex flex-col mt-20 py-10">
          <div className="flex flex-col my-10 gap-3">
            <p className="text-white font-bold text-3xl font-arco">
              BRIDGE $MOWGLI
            </p>
            <p className="text-2xl font-bold text-white underline">
              ADD MOWGLI TOKEN TO METAMASK
            </p>
          </div>
          <div className="flex w-full flex-wrap gap-12 ">
            <div className="flex w-full flex-col sm:w-auto">
              <div className="relative h-[679px] w-full overflow-hidden rounded-default bg-gradient-to-b from-[#E6E6E6] to-white text-blackGrape shadow-dropdown sm:w-[480px]">
                <img
                  src={a1}
                  alt=""
                  className="absolute -right-[50%] -top-[60px] h-[700px] w-auto max-w-none select-none"
                />
                <div className="flex h-full w-full flex-col">
                  <div className="flex h-full items-center justify-center">
                    <button className="button inline-flex items-center justify-center rounded-[25px] uppercase text-lg relative gap-2.5 font-gotham font-bold sky-button text-white px-9 py-[18px] h-[50px]">
                      Connect
                    </button>
                  </div>
                </div>
              </div>
              <div className="mt-3 flex items-center gap-2 px-[20px] text-sm text-white">
                <span className="mt-1">AUDITED BY</span>
                <img src={a2} className="h-5 " alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
