import React, { useContext, useEffect, useState } from "react";
import { StakingApp } from "../../Page/Context/StakingApp";
import axiosInstance1 from "../../Config1/axios";
import { API_URLS1 } from "../../Config1/apiUrls";
import { Navigate, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Point from "../../Alldilog/Point";
import { Loder } from "../../Alldilog/Loder";
import a1 from "../../Images/a1.png";
import a2 from "../../Images/a2.png";
import a3 from "../../Images/a3.png";
import a4 from "../../Images/a4.png";
import a5 from "../../Images/a5.png";
import a6 from "../../Images/a6.png";

import aaro from "../../Images/arrow.png";
import AOS from "aos";
import "aos/dist/aos.css";
import Menu from "../../Components/Menu/Menu";
import Notification from "../../Components/Notification/Notification";

export default function Staking() {
  const { setPage, setStakId, setname } = useContext(StakingApp);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  // const [page1, setPage1] = useState(false);

  const back = () => {
    toast.warn("Your session has expired");
    localStorage.clear();
    navigate("/");
  };

  const fetchData = async () => {
    try {
      const res = await axiosInstance1.post(API_URLS1.StakeMasterList);
      setData(res.data.data);
      setLoading(false);
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status === 401) {
        back();
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleJoin = (index) => {
    setStakId(data[index]?.id);
    setname(data[index]?.type);
    if (index === 0) {
      navigate("/staking/exclusive");
    } else {
      navigate("/staking/sanctuary");
    }
  };

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      {loading ? (
        <Loder />
      ) : (
        <>
          <div className="lg:flex lg:px-10 gap-10 justify-center items-center ">
            <Menu />

            <div className="lg:w-[80%] lg:h-[90vh] flex flex-col  py-3 !overflow-y-auto example">
              <Notification />
              <div className="lg:flex flex-col w-full  ">
                {/* {data.map((item, index) => ( */}
                <div className="lg:flex w-full  bg-gradient-to-b  from-[#1a2031] from-20% via-[#1a2a58] via-30% to-[#070b27] to-50%  justify-between  my-5 p-5 shadow-transparent border-opacity-10 rounded-md border border-[#078043] lg:hover:border-blue-600">
                  <img
                    src={data?.[0]?.image}
                    alt=""
                    className="rounded-2xl   lg:w-[50%] w-full lg:hidden"
                    // style={{ filter: "blur(1px)" }}
                  />
                  <div className="flex flex-col justify-center items-center ">
                    <span className="lg:flex gap-2 text-center text-xl">
                      <p className="text-white font-bold my-5">
                        {data?.[0]?.type}:
                      </p>
                      <p className="text-white font-bold my-5">
                        Staking for Mowgli Exclusive
                      </p>
                    </span>
                    <p className="text-gray-400 text-center px-10">
                      Stake your Mowgli Exclusive NFTs and unlock exclusive
                      benefits and rewards! Earn points based on the rarity and
                      number of NFTs staked. Join the adventure today!
                    </p>

                    <button
                      onClick={() => handleJoin(0)}
                      className="px-8 py-1 rounded-3xl bg-green-500 my-5 cursor-pointer hover:bg-green-300 hover:text-black font-bold text-white"
                    >
                      Join Now
                    </button>
                  </div>

                  <img
                    src={data?.[0]?.image}
                    alt=""
                    className="rounded-2xl  h-96 lg:w-[50%] lg:block hidden"
                    // style={{ filter: "blur(1px)" }}
                  />
                </div>
                <div
                  key={1}
                  className="lg:flex w-full  bg-gradient-to-b  from-[#1a2031] from-20% via-[#1a2a58] via-30% to-[#070b27] to-50%  shadow-transparent border-opacity-10   border-[#078043] border  justify-between  my-5 p-5 rounded-md  lg:hover:border-blue-600"
                >
                  <img
                    src={data?.[1]?.image}
                    alt=""
                    className="rounded-2xl  h-96 lg:w-[50%]"
                    // style={{ filter: "blur(1px)" }}
                  />
                  <div className="flex flex-col justify-center items-center ">
                    <span className="lg:flex text-center gap-2 text-xl">
                      <p className="text-white font-bold my-5">
                        {data?.[1]?.type}:
                      </p>
                      <p className="text-white font-bold my-5">
                        Staking for Mowgli Sanctuary
                      </p>
                    </span>
                    <p className="text-gray-400 text-center px-10">
                      Stake your Mowgli Sanctuary NFTs and become a guardian of
                      the jungle! Earn exclusive rewards based on the rarity and
                      quantity of NFTs staked. Immerse yourself in the world of
                      Mowgli Sanctuary!
                    </p>

                    <button
                      onClick={() => handleJoin(1)}
                      className="px-8 py-1 rounded-3xl bg-green-500 my-5 cursor-pointer hover:bg-green-300 hover:text-black font-bold text-white"
                    >
                      Join Now
                    </button>
                  </div>
                </div>
                {/* ))} */}
              </div>

              <a
                className="relative flex items-end justify-end mr-12  "
                href="#future"
              >
                <img
                  src={aaro}
                  alt=""
                  className="absolute animate-bounce !bottom-8 rounded-3xl h-20 w-20 lg:block hidden "
                />
              </a>
              <div className="flex flex-col gap-5">
                <div
                  id="future"
                  // data-aos="zoom-in-up"
                  className="w-full lg:my-0 bg-gradient-to-l  from-[#041125] from-20% via-[#041125] via-40 % to-[#05152c] to-50%   items-center mb-3  p-5 "
                >
                  <p className="text-white text-4xl text-center">
                    Exclusive{" "}
                    <b className="text-purple-500 italic">Rewards System</b>
                  </p>
                  <div className="lg:py-72 py-20 ">
                    <Point />
                  </div>
                </div>
                <div
                  id="future"
                  className="w-full lg:my-0 mt-5 items-center justify-center p-5 py-10 "
                >
                  <p className="text-center text-white text-4xl">
                    Ranking{" "}
                    <b className="!text-5xl text-pink-500 italic">Structure</b>{" "}
                  </p>
                  <div className="flex lg:px-52  ">
                    <div className="  w-full">
                      <div className="flex justify-between w-full my-10 text-white border-b py-2">
                        <div className="flex items-center gap-5">
                          <img src={a1} alt="" className="h-10 w-10" />
                          <p>Explorer</p>
                        </div>
                        <p>01 - 30 Days staked </p>
                      </div>
                      <div className="flex justify-between w-full my-10 text-white border-b py-2">
                        <div className="flex items-center gap-5">
                          <img src={a3} alt="" className="h-10 w-10" />
                          <p>Adventurer</p>
                        </div>
                        <p>31 - 90 Days staked </p>
                      </div>
                      <div className="flex justify-between w-full my-10 text-white border-b py-2">
                        <div className="flex items-center gap-5">
                          <img src={a2} alt="" className="h-10 w-10" />
                          <p>Trailblazer</p>
                        </div>
                        <p>91 - 120 Days staked </p>
                      </div>
                      <div className="flex justify-between w-full my-10 text-white border-b py-2">
                        <div className="flex items-center gap-5">
                          <img src={a4} alt="" className="h-10 w-10" />
                          <p>Pathfinder</p>
                        </div>
                        <p>121 - 150 Days staked </p>
                      </div>
                      <div className="flex justify-between w-full my-10 text-white border-b py-2">
                        <div className="flex items-center gap-5">
                          <img src={a5} alt="" className="h-10 w-10" />
                          <p>Discoverer</p>
                        </div>
                        <p>151 - 180 Days staked </p>
                      </div>
                      <div className="flex justify-between w-full my-10 text-white border-b py-2">
                        <div className="flex items-center gap-5">
                          <img src={a6} alt="" className="h-10 w-10" />
                          <p>Pioneer</p>
                        </div>
                        <p> {">"}181 Days staked </p>
                      </div>
                    </div>
                  </div>
                  <p className="text-white text-3xl text-center my-5">
                    With a greater rank, you get more exclusive rewards.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
