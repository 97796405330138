import React, { useContext, useEffect, useState } from "react";
import axiosInstance from "../../Config/axios";
import { StakingApp } from "../../Page/Context/StakingApp";
import axiosInstance1 from "../../Config1/axios";
import { API_URLS1 } from "../../Config1/apiUrls";
import { toast } from "react-toastify";
import { useAccount, useContractWrite } from "wagmi";
import logo from "../../Images/loaderimg.png";
import CryptoJS from "crypto-js";
import {
  SanctuaryAddress,
  StakingAbi,
  stakingAddress,
} from "../../Page/Context/constant";
import {
  Card,
  Chip,
  Dialog,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { Loder } from "../../Alldilog/Loder";
import Menu from "../../Components/Menu/Menu";
import Notification from "../../Components/Notification/Notification";

export default function Sanctuary() {
  // const nav = useNavigate
  const { stakId, aprove, name, ByAprove } = useContext(StakingApp);
  const { address } = useAccount();
  const navigate = useNavigate();
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [listData, setListData] = useState();
  const [listData2, setListData2] = useState();
  const [isLoding, setIsLoding] = useState(true);
  const [isLoding1, setIsLoding1] = useState();
  const [nftaccount, setNftaccount] = useState(null);
  const [stakedList, setStakedList] = useState();
  const [staked, setStaked] = useState();
  const [isChecked, setisChecked] = useState([]);
  const [isChecked1, setisChecked1] = useState([]);
  const [decrptedData, setDecrptedData] = useState("");
  const [stakData, setStakdata] = useState(false);
  const [sin, setSin] = useState();
  const [sin1, setSin1] = useState();
  const [data, setdata] = useState();
  const [timess, SetTimes] = useState();
  const [decrptedData1, setDecrptedData1] = useState("");
  const [text, setText] = useState("");
  const [data1, setdata1] = useState();
  const [timess1, SetTimes1] = useState();
  const secretPass = process.env.REACT_APP_Child_Key;
  const [checked, setChecked] = React.useState(false);
  const date = moment().format();

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleClose1 = () => {
    setOpen1(false);
  };
  const handelopen1 = () => {
    setOpen1(true);
  };

  const stak = () => {
    handelopen1();
    setListData(isChecked);
  };
  const handleClose2 = () => {
    setOpen2(false);
  };
  const handelopen2 = () => {
    setOpen2(true);
  };

  const unstak = () => {
    handelopen2();
    setListData2(isChecked1);
  };

  console.log(listData2, "listData2");

  const back = () => {
    toast.error("Your Session have expired");
    localStorage.clear();
    navigate("/");
  };
  const getwalletnft = () => {
    axiosInstance1
      .post(API_URLS1.getwalletnft, {
        nftType: "Sanctuary",
      })
      .then((res) => {
        if (res.data.nftdata !== nftaccount) {
          setIsLoding(true);
        }
        setNftaccount(() => {
          if (res.data.nftdata !== nftaccount) {
            return res.data.nftdata;
          }
        });
        setIsLoding(false);
      })
      .catch((err) => {
        console.log(err);
        err?.response?.data?.Response_code === 401 && back();
        setIsLoding(false);
      });
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      getwalletnft();
    }, 5000);

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, []);

  const getStakedlist = () => {
    axiosInstance1
      .post(API_URLS1.getStakedlist, {
        nft_category: 2,
      })
      .then((res) => {
        setStakedList(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        err?.response?.data?.Response_code === 401 && back();
      });
  };

  async function habndle() {
    getwalletnft();
    getStakedlist();
  }
  const Stakedlist = () => {
    axiosInstance1
      .post(API_URLS1.Stakedlist, {
        nft_category: 2,
        stake_status: "success",
      })
      .then((res) => {
        setStaked(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        err?.response?.data?.Response_code === 401 && back();
      });
  };
  useEffect(() => {
    Stakedlist();
  }, []);
  useEffect(() => {
    habndle();
  }, [stakData]);
  ///staking

  //sing     ...------------
  const CheckApi = () => {
    const timestamps = Date.now() / 1000;
    const postData = {
      timestamp: parseInt(timestamps),
      addressTo: address,
      status: true,
    };
    SetTimes(postData.timestamp);

    const formData = new URLSearchParams();
    for (const [key, value] of Object.entries(postData)) {
      formData.append(key, value);
    }
    axiosInstance1
      .post(API_URLS1.VerifySignvalue, postData)
      .then((res) => {
        setdata(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        err?.response?.data?.Response_code === 401 && back();
      });
  };

  useEffect(() => {
    data && check();
  }, [data]);

  const check = () => {
    const jsonData = data;
    const one = jsonData?.addr_1?.slice(2);
    const two = jsonData?.addr_2?.slice(2);
    const three = jsonData?.addr_3?.slice(2);
    const four = jsonData?.addr_4?.slice(2);
    const five = jsonData?.addr_5?.slice(2);

    const signature = `${one}${two}${three}${four}${five}`;

    const bytes = CryptoJS.AES.decrypt(signature, secretPass);
    const data1 = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    // const origanlsingnuture = decryptData(signature);
    setDecrptedData(data1);

    // console.log(origanlsingnuture, "origanlsingnuture");
  };

  //sign    .....------

  const postData = () => {
    axiosInstance1
      .post(API_URLS1.StbyU, { data: isChecked })
      .then((res) => {
        // toast.success(res.data.msg);
        setSin(encryptData(res.data.order_id));
        setIsLoding1(true);
        getStakedlist();
        handleClose1();
      })
      .catch((err) => {
        console.log(err);
        err?.response?.data?.Response_code === 401 && back();
      });
  };

  const encryptData = (text) => {
    const data = CryptoJS.AES.encrypt(
      JSON.stringify(text),
      secretPass
    ).toString();
    return data;
  };
  const identifiers = isChecked?.map((item) => parseInt(item?.identifier));
  useEffect(() => {
    decrptedData?.length > 0 && stake();
  }, [decrptedData?.length > 0 ? true : false]);

  const {
    write: stake,
    isLoading: val2,
    error,
  } = useContractWrite({
    address: stakingAddress,
    abi: StakingAbi,
    functionName: "stake",
    args: [
      SanctuaryAddress,
      identifiers,
      true,
      decrptedData.toString(),
      timess,
    ],
    onError(data) {
      console.log(data, "hello");
      setIsLoding1(false);
    },
    onSuccess(data) {
      StakedSuccess(data.hash.toString());
      setIsLoding1(false);
      habndle();
      getwalletnft();
    },
  });

  useEffect(() => {
    error && StakdFailed();
  }, [error]);

  const StakedSuccess = (e) => {
    axiosInstance1
      .post(API_URLS1.StakedSuccess, { conn: sin, taxId: e })
      .then((res) => {
        toast.success("Staking Successful");
        getwalletnft();
        getStakedlist();
        setisChecked([]);
        setStakdata(true);
        Stakedlist();
        handleClose2();
      })
      .catch((err) => {
        console.log(err);
        err?.response?.data?.Response_code === 401 && back();
      });
  };
  const StakdFailed = (e) => {
    axiosInstance1
      .post(API_URLS1.StakdFailed, { conn: sin, taxId: e })
      .then((res) => {
        toast.error("Staking Failed");
        getwalletnft();
        getStakedlist();
        setisChecked([]);
        handleClose2();
      })
      .catch((err) => {
        console.log(err);
        err?.response?.data?.Response_code === 401 && back();
      });
  };

  useEffect(() => {
    sin && CheckApi();
  }, [sin]);
  /////staking End
  const UnstakeNft = () => {
    axiosInstance1
      .post(API_URLS1.UnstakeNft, { data: isChecked1 })
      .then((res) => {
        // toast.success(res.data.msg);
        setSin1(encryptData1(res.data.order_id));
        setIsLoding1(true);
        getwalletnft();
      })
      .catch((err) => {
        console.log(err);
        err?.response?.data?.Response_code === 401 && back();
      });
  };

  const identifiers1 = isChecked1?.map((item) => parseInt(item?.identity_id));

  const encryptData1 = (text) => {
    const data = CryptoJS.AES.encrypt(
      JSON.stringify(text),
      secretPass
    ).toString();
    return data;
  };

  console.log(identifiers1, "identifiers1");

  const CheckApi1 = () => {
    const timestamps = Date.now() / 1000;
    const postData = {
      timestamp: parseInt(timestamps),
      addressTo: address,
      status: true,
    };
    SetTimes1(postData.timestamp);

    const formData = new URLSearchParams();
    for (const [key, value] of Object.entries(postData)) {
      formData.append(key, value);
    }
    axiosInstance1
      .post(API_URLS1.VerifySignvalue, postData)
      .then((res) => {
        setdata1(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        err?.response?.data?.Response_code === 401 && back();
      });
  };

  useEffect(() => {
    data1 && check1();
  }, [data1]);

  const check1 = () => {
    const jsonData = data1;
    const one = jsonData?.addr_1?.slice(2);
    const two = jsonData?.addr_2?.slice(2);
    const three = jsonData?.addr_3?.slice(2);
    const four = jsonData?.addr_4?.slice(2);
    const five = jsonData?.addr_5?.slice(2);

    const signature = `${one}${two}${three}${four}${five}`;

    const bytes = CryptoJS.AES.decrypt(signature, secretPass);
    const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    // const origanlsingnuture = decryptData(signature);
    setDecrptedData1(data);

    // console.log(origanlsingnuture, "origanlsingnuture");
  };

  const {
    write: stake1,
    isLoading: val1,
    error1,
  } = useContractWrite({
    address: stakingAddress,
    abi: StakingAbi,
    functionName: "unstake",
    args: [
      SanctuaryAddress,
      identifiers1,
      true,
      decrptedData1.toString(),
      timess1,
    ],
    onError(data) {
      console.log(data, "hello");
      setIsLoding1(false);
      handleClose2();
    },
    onSuccess(data) {
      UnstakeSuccess(data.hash.toString());
      setIsLoding1(false);
      handleClose2();
      habndle();
    },
  });

  useEffect(() => {
    decrptedData1?.length > 0 && stake1();
  }, [decrptedData1?.length > 0 ? true : false]);

  useEffect(() => {
    error1 && UnstakeFailed();
    error1 && handleClose2();
  }, [error1]);

  const UnstakeSuccess = (e) => {
    axiosInstance1
      .post(API_URLS1.updateUnstakeSuccess, {
        conn: sin1,
        taxId: e,
      })
      .then((res) => {
        // toast.success(res.data.msg);
        toast.success("Unstaking Successful");
        getwalletnft();
        getStakedlist();
        setisChecked1([]);
        setStakdata(true);
        Stakedlist();
      })
      .catch((err) => {
        console.log(err);
        err?.response?.data?.Response_code === 401 && back();
      });
  };
  const UnstakeFailed = (e) => {
    axiosInstance1
      .post(API_URLS1.updateUnstakeFailed, { data: isChecked1 })
      .then((res) => {
        // toast.success(res.data.msg);
        toast.success("Unstaking Failed");
        getwalletnft();
        getStakedlist();
        setisChecked1([]);
      })
      .catch((err) => {
        console.log(err);
        err?.response?.data?.Response_code === 401 && back();
      });
  };

  useEffect(() => {
    sin1 && CheckApi1();
  }, [sin1]);

  const handlecheckbox = (e) => {
    const { value, checked } = e.target;
    const [identifier, image_url, contract, name] = value.split(",");
    const name1 = name.split("#");

    if (checked) {
      setisChecked([
        ...isChecked,
        {
          identity_id: identifier,
          image: image_url,
          nft_type: "rare",
          staketype: 2,
          token: contract,
          title: name1?.[0],
          rarity: "null",
          identifier,
        },
      ]);
    } else {
      setisChecked(isChecked.filter((item) => item.identifier !== identifier));
    }
  };
  useEffect(() => {
    sin && CheckApi();
  }, [sin]);

  const handlecheckbox1 = (e) => {
    const { value, checked } = e.target;
    console.log(value, "value");
    const [id, identity_id, image] = value.split(",");
    if (checked) {
      setisChecked1([
        ...isChecked1,
        {
          id,
          unstakein: id,
          identity_id,
          taxId: "user Reg",
          image,
        },
      ]);
    } else {
      setisChecked1(isChecked1.filter((item) => item.id !== id));
    }
  };

  return (
    <>
      {isLoding1 && <Loder />}
      {isLoding ? (
        <Loder />
      ) : (
        <div className="lg:flex lg:px-10 gap-10 justify-center items-center ">
          <Menu />

          <div className="lg:w-[80%] lg:h-[90vh] flex flex-col  py-3 !overflow-y-auto example">
            <Notification />
            <div className="flex-col flex lg:py-5 lg:px-10 px-5">
              <p className="text-white font-bold my-5 lg:text-2xl text-xl">
                Mowgli
                <b className="text-orange-500 italic text-3xl">
                  {" "}
                  Sanctuary Staking
                </b>
              </p>
              {nftaccount?.length === 0 ? (
                <div className="w-full flex h-[100%] justify-center items-center  ">
                  <div>
                    <img src={logo} alt="" className="h-52 w-52" />
                    <a
                      href="https://opensea.io/collection/the-mowgli-sanctuary/overview"
                      className="flex py-2 px-5 bg-blue-400 w-52 cursor-pointer my-10 text-xl items-center justify-center"
                      target="_blank"
                    >
                      Open Sea
                    </a>
                  </div>
                </div>
              ) : (
                <>
                  <div className="lg:flex text-white gap-5">
                    <div className="flex lg:w-[50%]">
                      <div className="w-full">
                        <div className="flex justify-between">
                          <p
                            onClick={CheckApi}
                            className="text-white font-bold text-xl underline"
                          >
                            Available for staking
                          </p>

                          {isChecked.length >= 1 && (
                            <>
                              {aprove ? (
                                <button
                                  className="border px-10 py-1 text-white rounded-md  bg-green-500  border-gray-500"
                                  onClick={stak}
                                >
                                  Stake
                                </button>
                              ) : (
                                <button
                                  className="border px-10 py-1 text-white rounded-md  bg-green-500  border-gray-500"
                                  onClick={() => ByAprove()}
                                >
                                  Aprove
                                </button>
                              )}
                            </>
                          )}
                        </div>
                        {nftaccount ? (
                          <div className="flex overflow-auto scbox text-white gap-5 px-5 my-5">
                            {nftaccount?.map((item) => {
                              return (
                                <Card
                                  sx={{
                                    minWidth: 180,
                                    backgroundColor: "red",
                                    borderRadius: 2,
                                  }}
                                  key={item.identifier}
                                >
                                  <div className=" relative flex flex-col">
                                    <input
                                      type="checkbox"
                                      value={`${item.identifier},${item.image_url},${item.contract},${item.name}`}
                                      checked={item.isChecked}
                                      onChange={(e) => handlecheckbox(e)}
                                      className="flex absolute right-2 top-2 h-5 w-5 bg-green-500"
                                    />

                                    <img
                                      src={item.image_url}
                                      className="rounded-md  h-48 "
                                      alt={item.image_url}
                                    />
                                    <p className="text-white text-center">
                                      {item.name}
                                    </p>
                                  </div>
                                </Card>
                              );
                            })}
                          </div>
                        ) : (
                          <div className="flex flex-col justify-center items-center h-96">
                            <p className="text-2xl font-bold text-blue-500">
                              You have no NFT's
                            </p>
                            <Link
                              to="https://opensea.io/collection/the-mowgli-sanctuary/overview"
                              className="  px-8 py-1 rounded-3xl bg-green-500 my-5 cursor-pointer hover:bg-green-300 hover:text-black hover:font-bold"
                            >
                              Purchase
                            </Link>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="flex lg:w-[50%]">
                      <div className="w-full">
                        <div className="flex justify-between">
                          <p className="text-white font-bold text-xl underline">
                            Staked NFT's
                          </p>

                          {isChecked1.length >= 1 && (
                            <button
                              className="border px-10 py-1 text-white rounded-md  bg-red-500  border-gray-500"
                              onClick={unstak}
                            >
                              Unstake
                            </button>
                          )}
                        </div>

                        <div className="flex overflow-auto scbox text-white gap-5 px-5 my-5">
                          {stakedList?.map((item) => {
                            return (
                              <Card
                                sx={{
                                  minWidth: 200,
                                  backgroundColor: "red",
                                  borderRadius: 2,
                                }}
                              >
                                {" "}
                                <div className=" relative flex flex-col">
                                  {item.isStory === 0 ? (
                                    <input
                                      type="checkbox"
                                      value={`${item.id},${item.identity_id},${item.image}`}
                                      checked={item.isChecked}
                                      onChange={(e) => handlecheckbox1(e)}
                                      className="flex absolute right-2 top-2 h-5 w-5 bg-green-500"
                                    />
                                  ) : (
                                    <Chip
                                      label={`allot to ${item.storyTitle}`}
                                      className="flex absolute right-0 !rounded-none bottom-8 !w-full !font-bold !text-white !bg-[#0547CB]"
                                    />
                                  )}

                                  <img
                                    src={item?.image}
                                    alt={item?.image}
                                    className="rounded-md h-48 "
                                  />
                                  <div className="flex flex-col justify-center items-center text-white text-sm my-1">
                                    <p>{moment(item.stake_at).format("ll")}</p>
                                  </div>
                                </div>
                              </Card>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                  {staked && (
                    <div className="flex justify-center items-center shadow-md shadow-slate-100 rounded-2xl my-5 p-5">
                      <TableContainer className="!border example !text-white h-[40vh] ">
                        <Table stickyHeader>
                          <TableHead>
                            <TableRow className="">
                              <TableCell className="!text-black !font-bold">
                                Image
                              </TableCell>
                              <TableCell className="!text-black !font-bold">
                                Name
                              </TableCell>
                              <TableCell className="!text-black !font-bold">
                                Stake at
                              </TableCell>
                              <TableCell className="!text-black !font-bold">
                                Unstake at
                              </TableCell>
                              <TableCell className="!text-black !font-bold">
                                Point
                              </TableCell>
                              <TableCell className="!text-black !font-bold !text-center">
                                Stake type
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {staked?.map((row) => (
                              <TableRow
                                key={row.name}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  <img
                                    src={row.image}
                                    alt=""
                                    className="h-14 "
                                  />
                                </TableCell>
                                <TableCell className="!text-white">
                                  #{row.identity_id}
                                </TableCell>
                                <TableCell className="!text-white">
                                  {row.stake_at === null
                                    ? ""
                                    : moment(row.stake_at).format("L")}
                                </TableCell>
                                <TableCell className="!text-white">
                                  {row.unstake_at === null
                                    ? ""
                                    : moment(row.unstake_at).format("L")}
                                </TableCell>

                                <TableCell className="!text-white">
                                  30
                                </TableCell>
                                <TableCell className="text-white">
                                  <p
                                    className={
                                      row.stake_type === "stake"
                                        ? " text-green-500 text-center py-1"
                                        : " text-red-500 text-center py-1"
                                    }
                                  >
                                    {row.stake_type}
                                  </p>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  )}
                </>
              )}
              {/* <div className="grid grid-cols-3 gap-5 my-10">
            {staked.map((i) => {
              return (
                <div className="flex  w-full  p-5 gap-5 my-3 rounded-lg border bg-opacity-20 bg-[#078043]">
                  <img
                    src={i.image}
                    alt=""
                    className="rounded-lg"
                    style={{ width: "150px" }}
                  />

                  <div className="flex w-full flex-col justify-center items-center  ">
                    <span className="flex gap-3">
                      <p className=" text-center  text-white">
                        # {i.identity_id}
                      </p>
                    </span>

                    <span className="flex gap-3">
                      <p className=" text-center  text-white">
                        Staking point :
                      </p>
                      <p className=" text-center  text-white">
                        {i.identity_id}
                      </p>
                    </span>
                    <span className="flex gap-3">
                      <p className=" text-center  text-white">
                        {" "}
                        Staking on duty for :
                      </p>
                      <p className=" text-center  text-white">
                        {i.unstake_at === null
                          ? moment(i.stake_at, "YYYYMMDD")
                              .fromNow()
                              .slice(" ")?.[0] - 1
                          : moment(i.stake_at, "YYYYMMDD")
                              .fromNow()
                              .slice(" ")?.[0] -
                            moment(i.unstake_at, "YYYYMMDD")
                              .fromNow()
                              .slice(" ")?.[0]}
                      </p>
                    </span>
                    <span className="flex gap-3">
                      <p className=" text-center  text-white">
                        {" "}
                        Staking status :
                      </p>
                      <p className=" text-center  text-white">{i.stake_type}</p>
                    </span>
                  </div>
                </div>
              );
            })}
          </div> */}
              {/* <div className="lg:flex my-10 ">
        <div className="w-full py-5 ">
          <div className="flex justify-between">
            <p
              onClick={CheckApi}
              className="text-white  text-xl underline"
            >
              Available NFT's in your wallet
            </p>

            {isChecked.length >= 2 && (
              <>
                {aprove ? (
                  <button
                    className="border px-10 py-1 text-white rounded-md  bg-green-500  border-gray-500"
                   
                    onClick={stak}
                  >
                    Stake
                  </button>
                ) : (
                  <button
                    className="border px-10 py-1 text-white rounded-md  bg-green-500  border-gray-500"
                    onClick={() => ByAprove()}
                  >
                    Aprove
                  </button>
                )}
              </>
            )}
          </div>
          <div className="grid lg:grid-cols-4 grid-cols-2 gap-2 my-5 ">
            {isLoding
              ? [1, 2, 3, 4, 5, 6, 7, 8].map((index) => {
                  return (
                    <div className="  ">
                      <Box key={index}>
                        <Skeleton
                          sx={{ bgcolor: "grey.500" }}
                          variant="rectangular"
                          height={180}
                          className="!rounded-md"
                        />
                      </Box>

                      <Box sx={{ pt: 0.5 }}>
                        <Skeleton sx={{ bgcolor: "grey.500" }} height={50} />
                      </Box>
                    </div>
                  );
                })
              : nftaccount?.map((item) => {
                  return (
                    <div className=" relative flex flex-col">
                      <input
                        type="checkbox"
                       
                        value={`${
                          item.identifier
                        },${`https://bafybeib2ocbry6wrodf6t6ubz2gm2s7eeeag5n6epdi3rpjqafjzh5o724.ipfs.nftstorage.link/${item.identifier}.png`},${
                          item.contract
                        },${item.name}`}
                        checked={item.isChecked}
                        onChange={(e) => handlecheckbox(e)}
                        className="flex absolute right-2 top-2 h-5 w-5 bg-green-500"
                      />
                      <img
                        src={`https://bafybeib2ocbry6wrodf6t6ubz2gm2s7eeeag5n6epdi3rpjqafjzh5o724.ipfs.nftstorage.link/${item.identifier}.png`}
                        alt=""
                        className="rounded-md h-48 "
                      />
                      <p className="text-white text-center">{item.name}</p>

                      
                    </div>
                  );
                })}
          </div>{" "}
        </div>
        <div className="w-1 bg-gray-500 max-h-[1000px] mx-2"></div>

        <div className="w-full  py-5 ">
          <div className="flex justify-between">
            <p className="text-white font-bold text-xl underline">
              Available staked NFT's you have
            </p>
            {isChecked1.length >= 2 && (
              <button
                className="border px-10 py-1 text-white rounded-md  bg-red-500  border-gray-500"
                
                onClick={unstak}
              >
                Unstake
              </button>
            )}
           
          </div>
          <div className="grid lg:grid-cols-4 grid-cols-2 gap-2 my-5 ">
            {stakedList?.map((item) => {
              return (
                <div className=" relative flex flex-col">
                  {item.isStory === 0 ? (
                    <input
                      type="checkbox"
                      
                      value={`${item.id},${item.identity_id},${item.image}`}
                      checked={item.isChecked}
                      onChange={(e) => handlecheckbox1(e)}
                      className="flex absolute right-2 top-2 h-5 w-5 bg-green-500"
                    />
                  ) : (
                    <Chip
                      label="Filled"
                      className="flex absolute right-0 !rounded-none bottom-14 !w-full !font-bold !text-white !bg-[#0547CB]"
                    />
                  )}

                  <img src={item?.image} alt="" className="rounded-md h-48 " />
                  <div className="flex flex-col justify-center items-center text-white text-sm my-2">
                    
                    <p>{moment(item.stake_at).format("ll")}</p>
                  </div>
                 
                </div>
              );
            })}
          </div>
        </div>
      </div> */}
              <Dialog
                open={open1}
                onClose={handleClose1}
                PaperProps={{
                  className:
                    "!lg:w-[40%] !lg:min:h-[50%] lg:p-1 p-1 flex gap-3 flex-col !rounded-2xl !text-shadow-none !bg-[#041125] !p-5  border border-[#078043] ",
                }}
              >
                <div className="flex flex-col justify-center items-center">
                  <div className="grid grid-cols-3 gap-3">
                    {listData?.map((i) => {
                      return <img src={i.image} alt="" />;
                    })}
                  </div>
                  <div className="flex justify-center gap-5 items-center">
                    <button
                      onClick={postData}
                      className="px-8 py-1 rounded-3xl bg-green-500 my-5 cursor-pointer hover:bg-green-300 hover:text-black font-bold  text-white"
                    >
                      Confirm
                    </button>
                    <button
                      onClick={handleClose1}
                      className="px-8 py-1 rounded-3xl bg-red-500 my-5 cursor-pointer hover:bg-red-300 hover:text-black font-bold  text-white"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </Dialog>
              <Dialog
                open={open2}
                onClose={handleClose2}
                PaperProps={{
                  className:
                    "!lg:w-[40%] !lg:min:h-[50%] lg:p-1 p-1 flex gap-3 flex-col !rounded-2xl !text-shadow-none !bg-[#041125] !p-5  border border-[#078043] ",
                }}
              >
                <div className="flex flex-col ">
                  <p className="text-white font-bold text-xl my-2">
                    UnStake Your Nft's
                  </p>
                  <div className="grid lg:grid-cols-3 grid-cols-2 gap-3">
                    {listData2?.map((i) => {
                      return (
                        <img src={i.image} alt="" className="rounded-lg" />
                      );
                    })}
                  </div>
                  <span className="flex  gap-3 my-2">
                    <input
                      type="checkbox"
                      checked={checked}
                      onChange={handleChange}
                      className="flex h-5 w-5 mt-2 bg-green-500"
                    />

                    <p className="text-white my-2 text-sm ">
                      Are you sure you want to unstake your NFT after 30 days of
                      staking? If you unstake before 30 days, you will receive
                      no rewards and also you come at start point.
                    </p>
                  </span>
                  {!checked && (
                    <p className="text-sm text-red-500 italic">{text}</p>
                  )}

                  <div className="flex justify-center gap-5 items-center">
                    {checked ? (
                      <button
                        onClick={UnstakeNft}
                        className="px-8 py-1 rounded-3xl bg-green-500 my-5 cursor-pointer hover:bg-green-300 hover:text-black font-bold  text-white"
                      >
                        Confirm
                      </button>
                    ) : (
                      <button
                        onClick={() => setText("Please check confirmation box")}
                        className="px-8 py-1 rounded-3xl bg-green-500 my-5 cursor-pointer hover:bg-green-300 hover:text-black font-bold  text-white"
                      >
                        Confirm
                      </button>
                    )}

                    <button
                      onClick={handleClose2}
                      className="px-8 py-1 rounded-3xl bg-red-500 my-5 cursor-pointer hover:bg-red-300 hover:text-black font-bold  text-white"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </Dialog>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
