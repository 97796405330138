import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import a1 from "../../Images/nft1.png";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { Loder } from "../../Alldilog/Loder";

export default function Grapesgallery() {
  const [data, setData] = useState(false);
  const [isLoding, setIsLoding] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoding(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);
  return (
    <>
      {isLoding ? (
        <div className="flex flex-col justify-center items-center lg:py-56 py-20 bg-gradient-to-t from-[#599f00] to-[#a9dc01] h-screen ">
          <p className="lg:text-6xl font-bold text-xl text-pink-500">
            <Loder />
          </p>
        </div>
      ) : (
        <>
          <Header />
          <div className="flex flex-col bg-gradient-to-b from-[#6ab616] to-[#215606] lg:px-20 px-5">
            {data ? (
              <div className="flex flex-col mt-24">
                <div className="lg:flex justify-between mt-5">
                  <p className="lg:text-8xl text-4xl font-arco text-white">
                    Mowgli Gallery
                  </p>
                  <span className="flex flex-col ite justify-center">
                    <p className="lg:text-3xl text-xl font-bold text-white font-arco">
                      4446 Mowgli
                    </p>
                    <p className="text-white text-xl font-bold my-3">
                      ID High to Low
                    </p>
                  </span>
                </div>

                <div className="lg:flex my-20 gap-10">
                  <div className="flex flex-col lg:w-[30%]">
                    <div className="flex flex-col">
                      <div className="flex justify-between">
                        <p className="text-white lg:text-2xl text-xl  font-arco">
                          Filters
                        </p>
                        <p className="text-white lg:text-xl text-sm font-bold ">
                          CLEAR
                        </p>
                      </div>
                      <div className="my-5 w-full">
                        <input
                          type="number "
                          placeholder="Search by token ID..."
                          className="outline-none border border-gray-500 bg-transparent px-5 py-2 w-full rounded-md font-bold text-xl"
                        />
                      </div>
                    </div>
                    <div className="flex flex-col gap-5  p-3 ">
                      <Accordion className="!bg-transparent !shadow-none border-none ">
                        <AccordionSummary
                          expandIcon={<ArrowDownwardIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          <p className="text-white text-2xl font-bold">
                            Filters
                          </p>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="flex flex-col h-[300px]"></div>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion className="!bg-transparent !shadow-none border-none ">
                        <AccordionSummary
                          expandIcon={<ArrowDownwardIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          <p className="text-white text-2xl font-bold">
                            Filters
                          </p>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="flex flex-col h-[300px]"></div>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion className="!bg-transparent !shadow-none border-none ">
                        <AccordionSummary
                          expandIcon={<ArrowDownwardIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          <p className="text-white text-2xl font-bold">
                            Filters
                          </p>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="flex flex-col h-[300px]"></div>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion className="!bg-transparent !shadow-none border-none ">
                        <AccordionSummary
                          expandIcon={<ArrowDownwardIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          <p className="text-white text-2xl font-bold">
                            Filters
                          </p>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="flex flex-col h-[300px]"></div>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion className="!bg-transparent !shadow-none border-none">
                        <AccordionSummary
                          expandIcon={<ArrowDownwardIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          <p className="text-white text-2xl font-bold">
                            Filters
                          </p>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="flex flex-col h-[300px]"></div>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion className="!bg-transparent !shadow-none border-none ">
                        <AccordionSummary
                          expandIcon={<ArrowDownwardIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          <p className="text-white text-2xl font-bold">
                            Filters
                          </p>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="flex flex-col h-[300px]"></div>
                        </AccordionDetails>
                      </Accordion>
                      <div className="flex gap-10 items-center px-5 ">
                        <Checkbox
                          defaultChecked
                          size="large"
                          className="!text-white"
                        />
                        <p className="text-white !text-3xl font-arco">
                          SHOW CRATES
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="grid lg:grid-cols-4 grid-cols-2 lg:w-[70%]">
                    {[
                      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 13, 14, 15, 16, 17,
                    ].map(() => {
                      return (
                        <div className="flex flex-col items-center justify-center">
                          <img
                            src={a1}
                            alt=""
                            className="aspect-square select-none rounded-default shadow-darkShadow h-60 w-60"
                          />
                          <p className="text-white my-5 font-bold text-xl">
                            #1
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex flex-col justify-center items-center lg:py-56 py-20">
                <p className="lg:text-6xl font-bold text-xl text-pink-500">
                  COMING SOON...
                </p>
              </div>
            )}
          </div>
          <Footer />
        </>
      )}
    </>
  );
}
