import React, { useEffect, useState } from "react";
import Slaider from "../../Components/Slaidar";
import Cart from "../Cart";
import Map from "../Map";

import { useAccount, useDisconnect } from "wagmi";
import { useNavigate } from "react-router-dom";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";

import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import axiosInstance1 from "../../Config1/axios";
import { API_URLS1 } from "../../Config1/apiUrls";
import Trophy from "../../Alldilog/Trophy";

export default function Home() {
  const add = localStorage.getItem("address");
  const [isLoding, setIsLoding] = useState(true);
  const navigate = useNavigate();
  const { address } = useAccount();

  const { disconnect } = useDisconnect();
  const handleClose = () => {
    disconnect();
    localStorage.clear();
    navigate("/");
  };
  const Activ = () => {
    axiosInstance1
      .post(API_URLS1.loginactivity, {})
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    Activ();
  }, []);

  console.log(add, "add");

  useEffect(() => {
    if (add) {
      add !== address && handleClose();
    }
  }, [add, address]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoding(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <div className="flex flex-col">
        <Header />
        <Slaider />
        <Trophy />
        <div className="relative flex items-center justify-center mr-12">
          <a href="#future" className="lg:hidden">
            <KeyboardDoubleArrowDownIcon className="absolute animate-bounce !bottom-8  !text-5xl  text-red-500" />
          </a>
        </div>
        <div id="future">
          <Cart />
        </div>

        <Map />
        {/* <Sign /> */}
        <Footer />
      </div>
    </>
  );
}
